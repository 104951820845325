import { FC, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { ValidateFieldsError } from 'async-validator';

import { Typography, Checkbox } from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';

import {
  PaddedPaper,
  ValidatedTextField,
  FormButtons,
  BlockFormControlLabel,
  DefaultButton,
} from '../../../components';
import { SupplierDetail, SupplierSettings } from '../../../types';
import * as SupplierApi from '../../../api/supplier';
import { extractErrorMessage } from '../../../api/endpoints';
import { intl } from '../../../Internationalization';
import { supplierSettingsValidator, validate } from '../../../validation';

interface SupplierSettingsFormProps {
  supplierDetail: SupplierDetail;
  onSupplierUpdated: (supplierDetail: SupplierDetail) => void;
}

const SupplierSettingsForm: FC<SupplierSettingsFormProps> = ({
  supplierDetail,
  onSupplierUpdated,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const supplierSettings = SupplierApi.extractSupplierSettings(supplierDetail);

  const [name, setName] = useState<string>(supplierSettings.name);
  const [active, setActive] = useState<boolean>(supplierSettings.active);
  const [processing, setProcessing] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>({});

  const validator = supplierSettingsValidator(() => supplierDetail.name);

  const updateSupplier = async (updatedSuppler: SupplierSettings) => {
    try {
      const response = await SupplierApi.updateSupplier(supplierDetail.key, updatedSuppler);
      onSupplierUpdated(response.data);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'supplier.settings.saveSuccess',
          defaultMessage: 'Supplier updated',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'supplier.settings.saveError',
            defaultMessage: 'Failed to update supplier',
          })
        ),
        { variant: 'error' }
      );
    }
  };

  const validateAndUpdateSupplier = async () => {
    setProcessing(true);
    try {
      setFieldErrors({});
      await updateSupplier(await validate(validator, { name, active }));
    } catch (errors: any) {
      setFieldErrors(errors);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <PaddedPaper>
      <Typography variant="h5">
        <FormattedMessage id="supplier.settings.title" defaultMessage="Supplier Settings" />
      </Typography>
      <ValidatedTextField
        disabled={true}
        name="key"
        label={intl.formatMessage({
          id: 'supplier.settings.key.label',
          defaultMessage: 'Key',
        })}
        value={supplierDetail.key}
        margin="normal"
        variant="outlined"
      />
      <ValidatedTextField
        fieldErrors={fieldErrors}
        name="name"
        label={intl.formatMessage({
          id: 'supplier.settings.name.label',
          defaultMessage: 'Name',
        })}
        value={name}
        onChange={(e) => setName(e.target.value)}
        disabled={processing}
        margin="normal"
        variant="outlined"
      />
      <BlockFormControlLabel
        control={
          <Checkbox
            color="primary"
            name="active"
            checked={active}
            onChange={() => setActive((isActive) => !isActive)}
            disabled={processing}
          />
        }
        label={intl.formatMessage({
          id: 'supplier.settings.active.label',
          defaultMessage: 'Active?',
        })}
      />
      <FormButtons>
        <DefaultButton
          name="updateSupplierDetails"
          onClick={validateAndUpdateSupplier}
          disabled={processing}
          startIcon={<SaveIcon />}
        >
          <FormattedMessage id="supplier.settings.saveButton" defaultMessage="Save" />
        </DefaultButton>
      </FormButtons>
    </PaddedPaper>
  );
};

export default SupplierSettingsForm;

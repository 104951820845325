import Schema, { InternalRuleItem, Values } from 'async-validator';

import * as SavedMappingLookupApi from '../api/savedMappingLookup';
import { intl } from '../Internationalization';
import { submissionExistsByReference } from '../api/submission';
import { AssignmentDetail, MAX_MAPPINGS, MappingRatios } from '../types';

import { NOT_BLANK_REGEX, duplicateValidator } from './shared';

export const SUBMISSION_REFERENCE_VALIDATOR = new Schema({
  reference: [
    {
      type: 'string',
      required: true,
      len: 36,
      message: intl.formatMessage({
        id: 'submission.validator.reference.minLength',
        defaultMessage: 'Submission reference must be 36 characters',
      }),
    },
    {
      validator: (
        rule: InternalRuleItem,
        value: any,
        callback: (error?: string) => void,
        source: Values
      ) => {
        const { reference } = source;
        submissionExistsByReference(reference)
          .then(() => callback())
          .catch(() =>
            callback(
              intl.formatMessage({
                id: 'submission.validator.reference.exists',
                defaultMessage: 'No submission found with the reference provided',
              })
            )
          );
      },
    },
  ],
});

export const savedMappingValidator = (assignment: AssignmentDetail, mappingRatios: MappingRatios) =>
  new Schema({
    name: [
      {
        required: true,
        message: intl.formatMessage({
          id: 'openSubmission.schemaMapping.manageSchemaMapping.validator.name.required',
          defaultMessage: 'Please provide a mapping name',
        }),
      },
      duplicateValidator({
        regex: NOT_BLANK_REGEX,
        checkUnique: (name: string) =>
          SavedMappingLookupApi.savedMappingByName(assignment.key, name),
        alreadyExistsMessage: intl.formatMessage({
          id: 'openSubmission.schemaMapping.manageSchemaMapping.validator.name.unique',
          defaultMessage: 'A saved mapping with this name already exists',
        }),
        errorMessage: intl.formatMessage({
          id: 'openSubmission.schemaMapping.manageSchemaMapping.validator.name.checkUniqueError',
          defaultMessage: 'There was a problem verifying the saved mapping name',
        }),
      }),
      {
        validator: (
          rule: InternalRuleItem,
          value: any,
          callback: (error?: string) => void,
          source: Values
        ) => {
          if (Object.keys(mappingRatios.saved).length >= MAX_MAPPINGS) {
            callback(
              intl.formatMessage(
                {
                  id: 'openSubmission.schemaMapping.manageSchemaMapping.validator.maximumMappings',
                  defaultMessage: 'You may save a maximum of {maxMappings} mappings',
                },
                { maxMappings: MAX_MAPPINGS }
              )
            );
          }
          callback();
        },
      },
    ],
  });

import { AxiosPromise } from 'axios';
import { ScheduleDetail, UpdateScheduleRequest } from '../types';
import { AXIOS, RequestConfig } from './endpoints';
import { ScheduleEventsRequest, ScheduleEventsResponse } from '../types/scheduleEvent';

export function getSchedule(
  scheduleKey: string,
  config?: RequestConfig
): AxiosPromise<ScheduleDetail> {
  return AXIOS.get(`/schedules/${scheduleKey}`, config);
}

export function getScheduleEvents(
  scheduleKey: string,
  request: ScheduleEventsRequest,
  config?: RequestConfig
): AxiosPromise<ScheduleEventsResponse> {
  return AXIOS.get(`/schedules/${scheduleKey}/events`, { ...config, params: request });
}

export function updateSchedule(
  scheduleKey: string,
  request: UpdateScheduleRequest
): AxiosPromise<ScheduleDetail> {
  return AXIOS.post(`/schedules/${scheduleKey}`, request);
}

export function deleteSchedule(scheduleKey: string): AxiosPromise<void> {
  return AXIOS.delete(`/schedules/${scheduleKey}`);
}

export const extractScheduleSettings = ({
  startDateTime,
  endDateTime,
  active,
  cronExpression,
  note,
  endCriteria,
  numberOfOccurrences,
}: ScheduleDetail): UpdateScheduleRequest => ({
  startDateTime,
  endDateTime,
  active,
  cronExpression,
  note,
  endCriteria,
  numberOfOccurrences,
});

import { FC, useState } from 'react';
import { EditorState } from 'draft-js';

import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { DefaultButton, PaddedDialogActions, TemplatedInputEditor, ValidatedTextField } from '..';
import { editorStateFromText, textFromEditorState } from '../../util';
import { TextFieldGroup } from './TemplatedInputEditor';
import { ValidateFieldsError } from 'async-validator';

interface ExpressionTemplateDialogProps {
  expressionTemplate: string;
  textFieldGroups: TextFieldGroup[];
  onConfirm: (expressionTemplate: string) => void;
  id: string;
  label: string;
  fieldName: string;
  disabled: boolean;
  fieldErrors?: ValidateFieldsError;
}

const ExpressionTemplateDialog: FC<ExpressionTemplateDialogProps> = ({
  expressionTemplate,
  textFieldGroups,
  onConfirm,
  id,
  label,
  fieldName,
  disabled,
  fieldErrors,
}) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [expressionTemplateState, setExpressionTemplateState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  const handleDialogOpen = () => {
    setExpressionTemplateState(editorStateFromText(expressionTemplate));
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    onConfirm(textFromEditorState(expressionTemplateState));
    setDialogOpen(false);
  };

  return (
    <>
      <ValidatedTextField
        value={expressionTemplate}
        label={label}
        InputLabelProps={{ shrink: true }}
        name={fieldName}
        margin="dense"
        size="small"
        fullWidth
        disabled={disabled}
        fieldErrors={fieldErrors}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={handleDialogOpen}
              sx={{ p: 1, m: -0.5 }}
              size="large"
              disabled={disabled}
            >
              <EditIcon />
            </IconButton>
          ),
        }}
      />
      <Dialog open={dialogOpen} onClose={handleDialogClose} id={id} fullWidth maxWidth="md">
        <DialogTitle>
          <FormattedMessage
            id="components.editor.expressionTemplateDialog.edit"
            defaultMessage="Edit"
          />
          &nbsp;-&nbsp;{label}
        </DialogTitle>
        <DialogContent>
          <TemplatedInputEditor
            textFieldGroups={textFieldGroups}
            editorState={expressionTemplateState}
            onEditorStateChange={setExpressionTemplateState}
          />
        </DialogContent>
        <PaddedDialogActions>
          <DefaultButton name="cancel" onClick={handleDialogClose} color="secondary">
            <FormattedMessage
              id="components.editor.expressionTemplateDialog.cancelBtn"
              defaultMessage="Cancel"
            />
          </DefaultButton>
          <DefaultButton name="confirm" onClick={handleConfirm}>
            <FormattedMessage
              id="components.editor.expressionTemplateDialog.confirmBtn"
              defaultMessage="Confirm"
            />
          </DefaultButton>
        </PaddedDialogActions>
      </Dialog>
    </>
  );
};

export default ExpressionTemplateDialog;

import { useContext, useEffect, useState, FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Link, Typography, Box } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssessmentIcon from '@mui/icons-material/Assessment';

import { extractErrorMessage } from '../../../../../api/endpoints';
import * as SubmissionApi from '../../../../../api/submission';
import { AppDecorator, HeaderBreadcrumb } from '../../../../../components';
import { useErrorBlock } from '../../../../../contexts/error-block';
import { SubmissionDetail } from '../../../../../types';

import SubmissionSkeleton from '../../../../components/submission/SubmissionSkeleton';
import { MyAssignmentContext } from '../../MyAssignmentContext';
import { intl } from '../../../../../Internationalization';
import { dateTimeFormat } from '../../../../../util';

import MySubmissionHandler from './MySubmissionHandler';

interface MySubmissionProps {
  submissionReference: string;
}

const MySubmission: FC<MySubmissionProps> = ({ submissionReference }) => {
  const { navigateToMySubmissions, assignment, project, specification } =
    useContext(MyAssignmentContext);
  const [submission, setSubmission] = useState<SubmissionDetail>();
  const { raiseError } = useErrorBlock();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const initializeContext = async () => {
      try {
        const fetchedSubmission = (await SubmissionApi.getSubmission(submissionReference)).data;
        if (fetchedSubmission.assignment.key === assignment.key) {
          setSubmission(fetchedSubmission);
        } else {
          enqueueSnackbar(
            intl.formatMessage({
              id: 'myAssignment.mySubmission.invalidSubmission',
              defaultMessage: 'Invalid submission',
            }),
            { variant: 'warning' }
          );
          navigateToMySubmissions();
        }
      } catch (error: any) {
        raiseError(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'myAssignment.mySubmission.loadError',
              defaultMessage: 'Failed to fetch submission',
            })
          )
        );
      }
    };

    initializeContext();
  }, [submissionReference, assignment, enqueueSnackbar, navigateToMySubmissions, raiseError]);

  const renderBreadcrumb = () => (
    <HeaderBreadcrumb>
      <Link component={RouterLink} to={`/my_assignments/${assignment.key}/submissions`}>
        <AssignmentIcon
          titleAccess={intl.formatMessage({
            id: 'myAssignment.mySubmission.breadcrumb.assignment.titlAccess',
            defaultMessage: 'Assignment',
          })}
        />
        {assignment.reference}
      </Link>
      {submission && (
        <Typography>
          <AssessmentIcon
            titleAccess={intl.formatMessage({
              id: 'myAssignment.mySubmission.breadcrumb.submission.titleAccess',
              defaultMessage: 'Submission created at',
            })}
          />
          {dateTimeFormat(submission.createdAt)}
        </Typography>
      )}
    </HeaderBreadcrumb>
  );

  const renderSubmission = () => {
    if (!submission) {
      return <SubmissionSkeleton />;
    }

    return (
      <MySubmissionHandler
        submissionUpdated={setSubmission}
        submission={submission}
        assignment={assignment}
        project={project}
        specification={specification}
      />
    );
  };

  return (
    <AppDecorator renderTabs={undefined} renderBreadcrumb={renderBreadcrumb}>
      <Box id="my-assignment-my-submission">{renderSubmission()}</Box>
    </AppDecorator>
  );
};

export default MySubmission;

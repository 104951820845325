import { AxiosPromise } from 'axios';

import { AXIOS } from './endpoints';
import { LdapServerSettings } from '../types';

export function getServerSettings(): AxiosPromise<LdapServerSettings> {
  return AXIOS.get(`/ldap/server`);
}

export function updateServerSettings(
  ldapSettings: LdapServerSettings
): AxiosPromise<LdapServerSettings> {
  return AXIOS.post(`/ldap/server`, ldapSettings);
}

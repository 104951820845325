import { FC, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { ValidateFieldsError } from 'async-validator';

import {
  Typography,
  Checkbox,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { IMAGE_STYLE_METADATA, ImageStyle, ProjectSettings, imageStyles } from '../../../types';
import * as ProjectApi from '../../../api/project';
import { intl } from '../../../Internationalization';
import { extractErrorMessage } from '../../../api/endpoints';
import {
  PaddedPaper,
  ValidatedTextField,
  FormButtons,
  BlockFormControlLabel,
  DefaultButton,
  InputTooltip,
} from '../../../components';
import { projectSettingsValidator, validate } from '../../../validation';

import { ProjectContext } from './ProjectContext';

const ProjectSettingsForm: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { project, projectUpdated } = useContext(ProjectContext);
  const projectSettings = ProjectApi.extractProjectSettings(project);

  const [name, setName] = useState<string>(projectSettings.name);
  const [description, setDescription] = useState<string>(projectSettings.description);
  const [active, setActive] = useState<boolean>(projectSettings.active);
  const [imageStyle, setImageStyle] = useState<ImageStyle>(projectSettings.imageStyle);

  const [processing, setProcessing] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>({});

  const validator = projectSettingsValidator(() => project.name);

  const updateProject = async (updatedProject: ProjectSettings) => {
    try {
      const response = await ProjectApi.updateProjectSettings(project.key, {
        ...project,
        ...updatedProject,
      });
      projectUpdated(response.data);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'project.settings.saveSuccess',
          defaultMessage: 'Project updated',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'project.settings.saveError',
            defaultMessage: 'Failed to update project',
          })
        ),
        { variant: 'error' }
      );
    }
  };

  const validateAndUpdateProject = async () => {
    setProcessing(true);
    try {
      setFieldErrors({});
      await updateProject(await validate(validator, { name, description, active, imageStyle }));
    } catch (errors: any) {
      setFieldErrors(errors);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <PaddedPaper>
      <Typography variant="h5">
        <FormattedMessage id="project.settings.title" defaultMessage="Project Settings" />
      </Typography>
      <ValidatedTextField
        tooltip={intl.formatMessage({
          id: 'project.settings.key.tooltip',
          defaultMessage: 'Key is a user-friendly identifier, not editable.',
        })}
        disabled={true}
        name="key"
        label={intl.formatMessage({
          id: 'project.settings.key.label',
          defaultMessage: 'Key',
        })}
        value={project.key}
        margin="normal"
        variant="outlined"
      />
      <ValidatedTextField
        tooltip={intl.formatMessage({
          id: 'project.settings.name.tooltip',
          defaultMessage: 'A short name for the project.',
        })}
        fieldErrors={fieldErrors}
        name="name"
        label={intl.formatMessage({
          id: 'project.settings.name.label',
          defaultMessage: 'Name',
        })}
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
        disabled={processing}
        margin="normal"
        variant="outlined"
      />
      <ValidatedTextField
        tooltip={intl.formatMessage({
          id: 'project.settings.description.tooltip',
          defaultMessage: 'Detailed free text description of the project.',
        })}
        fieldErrors={fieldErrors}
        name="description"
        label={intl.formatMessage({
          id: 'project.settings.description.label',
          defaultMessage: 'Description',
        })}
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
        multiline={true}
        maxRows={10}
        disabled={processing}
        margin="normal"
        variant="outlined"
      />
      <InputTooltip
        data-tooltip-for="active"
        title={intl.formatMessage({
          id: 'project.settings.active.tooltip',
          defaultMessage: 'If selected the project will be visible.',
        })}
      >
        <BlockFormControlLabel
          control={
            <Checkbox
              color="primary"
              name="active"
              checked={active}
              onChange={() => {
                setActive((isActive) => !isActive);
              }}
              disabled={processing}
            />
          }
          label={intl.formatMessage({
            id: 'project.settings.active.label',
            defaultMessage: 'Active?',
          })}
        />
      </InputTooltip>
      <FormControl margin="normal" component="fieldset">
        <FormLabel component="legend">
          <FormattedMessage id="project.settings.imageStyle.label" defaultMessage="Image Style" />
        </FormLabel>
        <RadioGroup
          name="imageStyle"
          value={imageStyle}
          row
          onChange={(event) => {
            setImageStyle(ImageStyle[event.target.value as keyof typeof ImageStyle]);
          }}
        >
          {imageStyles.map((style) => (
            <FormControlLabel
              key={style}
              value={style}
              control={<Radio />}
              label={IMAGE_STYLE_METADATA[style].label}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <FormButtons>
        <DefaultButton
          name="updateProjectDetails"
          onClick={validateAndUpdateProject}
          disabled={processing}
          startIcon={<SaveIcon />}
        >
          <FormattedMessage id="project.settings.saveButton" defaultMessage="Save" />
        </DefaultButton>
      </FormButtons>
    </PaddedPaper>
  );
};

export default ProjectSettingsForm;

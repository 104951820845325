import Schema from 'async-validator';

import { intl } from '../Internationalization';
import { duplicateValidator, NOT_BLANK_REGEX } from './shared';

import * as ReceiverMappingLookupApi from '../api/receiverMappingLookup';

export const receiverMappingValidator = (getIdentityProviderKey: () => string): Schema => {
  return new Schema({
    group: [
      {
        required: true,
        pattern: NOT_BLANK_REGEX,
        message: intl.formatMessage({
          id: 'receiverMapping.validator.group.required',
          defaultMessage: 'Please provide a group identifier for this mapping',
        }),
      },
      duplicateValidator({
        regex: NOT_BLANK_REGEX,
        checkUnique: (group) =>
          ReceiverMappingLookupApi.receiverMappingByGroup(getIdentityProviderKey(), group),
        alreadyExistsMessage: intl.formatMessage({
          id: 'receiverMapping.validator.group.unique',
          defaultMessage: 'A mapping with this group identifier already exists',
        }),
        errorMessage: intl.formatMessage({
          id: 'receiverMapping.validator.group.checkUniqueError',
          defaultMessage: 'There was a problem verifying the receiver mapping',
        }),
      }),
    ],
  });
};

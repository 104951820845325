import { Tooltip } from '@mui/material';

import {
  SubmissionOutcome,
  SubmissionSummary,
  SUBMISSION_OUTCOME_METADATA,
  SUBMISSION_REJECTION_METADATA,
} from '../../types';

interface SubmissionOutcomeIconProps {
  submission: SubmissionSummary;
  size?: 'inherit' | 'medium' | 'small' | 'large';
}

const SubmissionOutcomeIcon = ({
  submission: { outcome, rejection },
  size = 'large',
}: SubmissionOutcomeIconProps) => {
  if (!outcome) {
    return null;
  }

  const title = SUBMISSION_OUTCOME_METADATA[outcome].description;
  const titleSuffix =
    outcome === SubmissionOutcome.REJECTED && rejection
      ? ` ${SUBMISSION_REJECTION_METADATA[rejection].qualifier}`
      : '';

  const Icon = SUBMISSION_OUTCOME_METADATA[outcome].icon;
  return (
    <Tooltip describeChild title={title + titleSuffix}>
      <Icon fontSize={size} />
    </Tooltip>
  );
};

export default SubmissionOutcomeIcon;

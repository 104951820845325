import Schema from 'async-validator';
import {
  NOT_BLANK_REGEX,
  duplicateValidator,
  nameLengthValidator,
  notTrimmableValidator,
} from './shared';
import { intl } from '../Internationalization';

import { userByEmail } from '../api/userLookup';

export function userProfileValidator(getExistingEmail?: () => string): Schema {
  return new Schema({
    email: [
      notTrimmableValidator,
      {
        required: true,
        type: 'email',
        message: intl.formatMessage({
          id: 'user.validator.email.required',
          defaultMessage: 'Please provide a valid email',
        }),
      },
      duplicateValidator({
        regex: NOT_BLANK_REGEX,
        existingValue: getExistingEmail,
        checkUnique: userByEmail,
        alreadyExistsMessage: intl.formatMessage({
          id: 'user.validator.email.unique',
          defaultMessage: 'A user with this email already exists',
        }),
        errorMessage: intl.formatMessage({
          id: 'user.validator.email.checkUniqueError',
          defaultMessage: 'There was a problem verifying the user email',
        }),
      }),
    ],
    name: [
      {
        required: true,
        message: intl.formatMessage({
          id: 'user.validator.name.required',
          defaultMessage: 'Please provide a name',
        }),
      },
      nameLengthValidator,
    ],
  });
}

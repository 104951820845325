import { AxiosPromise } from 'axios';

import { AXIOS, RequestConfig } from './endpoints';
import { IdentityProvidersRequest, IdentityProviderResponse } from '../types';

export function getIdentityProviders(
  request: IdentityProvidersRequest,
  config?: RequestConfig
): AxiosPromise<IdentityProviderResponse> {
  return AXIOS.get('/identity_providers', {
    ...config,
    params: request,
  });
}

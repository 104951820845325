import { FC, useContext, useEffect, useState } from 'react';

import * as UserApi from '../../../api/user';
import { extractErrorMessage } from '../../../api/endpoints';
import { ErrorBlockContext } from '../../../contexts/error-block';
import { UserDetail } from '../../../types';
import { intl } from '../../../Internationalization';
import { useRequiredParams } from '../../../hooks';

import UserSkeleton from './UserSkeleton';
import { UserContext } from './UserContext';
import UserRouting from './UserRouting';

const User: FC = () => {
  const { userKey } = useRequiredParams<{ userKey: string }>();

  const { raiseError } = useContext(ErrorBlockContext);
  const [user, setUser] = useState<UserDetail>();

  useEffect(() => {
    const loadUser = async () => {
      try {
        const response = await UserApi.getUserDetails(userKey);
        setUser(response.data);
      } catch (error: any) {
        raiseError(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'user.loadError',
              defaultMessage: 'Failed to read user',
            })
          )
        );
      }
    };

    loadUser();
  }, [raiseError, userKey]);

  return user ? (
    <UserContext.Provider
      value={{
        userUpdated: setUser,
        userKey,
        user: user,
      }}
    >
      <UserRouting />
    </UserContext.Provider>
  ) : (
    <UserSkeleton />
  );
};

export default User;

import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

import { Grid, Container, TableRow, TableCell } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';

import { useErrorBlock } from '../../../../../../contexts/error-block';
import { AppBarStatsContext } from '../../../../../../contexts/app-bar-stats';
import { ApplicationContext } from '../../../../../../contexts/application';
import { NavigationPromptContext } from '../../../../../../contexts/navigation-prompt';
import {
  MessageBox,
  FullWidthButton,
  Loading,
  BrowseTable,
  browseTableBody,
  MoreButton,
  StyledTableHead,
} from '../../../../../../components';
import {
  SubmissionState,
  virusScanStatePassed,
  SubmissionInputDetail,
  InputsDescription,
} from '../../../../../../types';
import { extractErrorMessage } from '../../../../../../api/endpoints';
import * as SubmissionApi from '../../../../../../api/submission';
import { intl } from '../../../../../../Internationalization';

import ConfigureAndContinue from '../ConfigureAndContinue';
import { OpenSubmissionContext } from '../OpenSubmissionContext';

import InputRow from './InputRow';
import VirusScanMessage from './VirusScanMessage';
import { useTitle } from '../../../../../../hooks';
const calculateColsCount = (
  mediaColumn: boolean,
  virusScannerEnabled: boolean,
  parametersRequired: boolean
) => {
  let count = 2;
  if (mediaColumn) {
    count += virusScannerEnabled ? 3 : 2;
  }
  if (parametersRequired) {
    count += 1;
  }

  return count;
};

const displayMediaColumn = (inputs: InputsDescription) => {
  return !!Object.values(inputs).find(
    (currentInput) => currentInput.uploadSupported || currentInput.orphaned
  );
};

const validateInputUploads = (inputs: InputsDescription) =>
  !Object.values(inputs).find(
    (currentInput) => currentInput.orphaned || (currentInput.uploadRequired && !currentInput.file)
  );

const validateVirusScanPassed = (inputs: InputsDescription) =>
  !Object.values(inputs).find(
    (currentInput) => currentInput.file && !virusScanStatePassed(currentInput.file?.virusScanState)
  );

const areParametersRequired = (inputs: Record<string, SubmissionInputDetail>) =>
  !!Object.values(inputs).find(({ linkedDataStores }) => {
    return Object.values(linkedDataStores).find(({ parameters }) => parameters.length);
  });

const sortedInputs = (inputs: Record<string, SubmissionInputDetail>) =>
  Object.values(inputs).sort((a, b) => {
    return a.sortKey - b.sortKey || a.inputName.localeCompare(b.inputName);
  });

const InputUploadTableBody = browseTableBody<SubmissionInputDetail>();

const InputUpload: FC = () => {
  useTitle(
    intl.formatMessage({
      id: 'title.submissionsUpload',
      defaultMessage: 'Submission Upload',
    })
  );
  const { enqueueSnackbar } = useSnackbar();
  const { submission, submissionUpdated, sessionSchema } = useContext(OpenSubmissionContext);
  const appBarStatsContext = useContext(AppBarStatsContext);
  const {
    applicationDetails: { virusScannerEnabled },
  } = useContext(ApplicationContext);

  const { raiseNavigationBlock, clearNavigationBlock } = useContext(NavigationPromptContext);

  const { raiseError } = useErrorBlock();

  const [inputs, setInputs] = useState<InputsDescription>({});

  const [openAutomationDialog, setOpenAutomationDialog] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);

  const [uploadingCount, setUploadingCount] = useState<number>(0);

  const updateUploadingCount = (uploading: boolean) => {
    setUploadingCount((prev) => (uploading ? prev + 1 : prev - 1));
  };

  useEffect(() => {
    if (uploadingCount === 1) {
      raiseNavigationBlock();
    }

    if (uploadingCount === 0) {
      clearNavigationBlock();
    }
  }, [clearNavigationBlock, raiseNavigationBlock, uploadingCount]);

  const fetchSchema = useCallback(async () => {
    try {
      const { data: submissionInputs } = await SubmissionApi.describeInputs(submission.reference);

      setInputs(submissionInputs);
    } catch (error: any) {
      raiseError(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'openSubmission.inputUpload.loadError',
            defaultMessage: 'Failed to load submission details',
          })
        )
      );
    } finally {
      setFetching(false);
    }
  }, [raiseError, submission.reference]);

  useEffect(() => {
    setFetching(true);
    fetchSchema();
  }, [fetchSchema]);

  useEffect(() => {
    if (
      Object.entries(submission.inputsByKey).find(([inputKey, input]) => {
        return input.file?.virusScanState !== inputs[inputKey]?.file?.virusScanState;
      })
    ) {
      fetchSchema();
    }
  }, [submission, inputs, fetchSchema]);

  const cancelCurrentSubmission = async () => {
    setProcessing(true);
    try {
      const response = await SubmissionApi.cancel(submission.reference);
      submissionUpdated(response.data);
      appBarStatsContext.refresh();
    } catch (error: any) {
      setProcessing(false);
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'openSubmission.inputUpload.cancelError',
            defaultMessage: 'Failed to cancel submission',
          })
        ),
        { variant: 'error' }
      );
    }
  };

  const startCurrentSubmission = async () => {
    setProcessing(true);
    try {
      const response = await SubmissionApi.start(submission.reference);
      submissionUpdated(response.data);
      appBarStatsContext.refresh();
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'openSubmission.inputUpload.startError',
            defaultMessage: 'Failed to start submission',
          })
        ),
        { variant: 'error' }
      );
      setProcessing(false);
    }
  };

  const onInputsUpdated = (submissionInputs: InputsDescription) => {
    submissionUpdated(
      (prevSubmission) =>
        prevSubmission && {
          ...prevSubmission,
          inputsByKey: submissionInputs,
        }
    );
    setInputs(submissionInputs);
  };

  const handleUpdateInput = (inputKey: string, input: SubmissionInputDetail) => {
    setInputs((prevInputs) => ({ ...prevInputs, [inputKey]: input }));
    submissionUpdated(
      (prevSubmission) =>
        prevSubmission && {
          ...prevSubmission,
          inputsByKey: { ...prevSubmission.inputsByKey, [inputKey]: input },
        }
    );
  };

  const onRemoveMedia = async (dataStorePath: string, inputKey: string) => {
    try {
      await SubmissionApi.removeFileForInput(dataStorePath, inputKey);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'openSubmission.inputUpload.removeMediaSuccessful',
          defaultMessage: 'Submission file removed successfully',
        }),
        { variant: 'success' }
      );
      submissionUpdated((prevSubmission) => {
        if (!prevSubmission) {
          return prevSubmission;
        }
        const updatedInputs = {
          ...prevSubmission.inputsByKey,
          [dataStorePath]: { ...prevSubmission.inputsByKey[dataStorePath], file: undefined },
        };
        return { ...prevSubmission, inputsByKey: updatedInputs };
      });
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'openSubmission.inputUpload.removeMediaError',
            defaultMessage: 'Failed to remove submission file',
          })
        ),
        { variant: 'error' }
      );
    }
  };

  const handleOpenAutomationDialog = () => {
    setOpenAutomationDialog(true);
  };

  const handleCloseAutomationDialog = () => {
    setOpenAutomationDialog(false);
  };

  const inputUploadsValid = validateInputUploads(inputs);
  const mediaColumn = displayMediaColumn(inputs);
  const parametersRequired = areParametersRequired(inputs);
  const virusScanStateValid = validateVirusScanPassed(inputs);

  const renderReadyMessage = () => {
    if (!inputUploadsValid) {
      return (
        <MessageBox
          key="uploadInput"
          level="info"
          message={intl.formatMessage({
            id: 'openSubmission.inputUpload.uploadRequirements',
            defaultMessage: 'Please upload required files.',
          })}
        />
      );
    }

    if (virusScannerEnabled && !virusScanStateValid) {
      return <VirusScanMessage inputs={submission.inputsByKey} />;
    }

    return (
      <MessageBox
        level="success"
        message={intl.formatMessage({
          id: 'openSubmission.inputUpload.ready',
          defaultMessage: 'Submission is ready to run.',
        })}
      />
    );
  };

  if (!sessionSchema) {
    return <Loading />;
  }

  return (
    <Container maxWidth="lg" id="my-assignment-current-submission-upload" disableGutters>
      {submission.state === SubmissionState.NOT_STARTED ? (
        <Grid container spacing={3} direction="row" alignItems="stretch">
          <Grid item xs={12}>
            <BrowseTable gutterBottom={false}>
              <StyledTableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage
                      id="openSubmission.inputUpload.table.dataSetColumn"
                      defaultMessage="Data Set"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="openSubmission.inputUpload.table.fileTypeColumn"
                      defaultMessage="File Type"
                    />
                  </TableCell>
                  {mediaColumn && (
                    <TableCell align="center">
                      <FormattedMessage
                        id="openSubmission.inputUpload.table.requiredColumn"
                        defaultMessage="Required"
                      />
                    </TableCell>
                  )}
                  {mediaColumn && virusScannerEnabled && (
                    <TableCell>
                      <FormattedMessage
                        id="openSubmission.inputUpload.table.virusScanColumn"
                        defaultMessage="Virus Scan"
                      />
                    </TableCell>
                  )}
                  {mediaColumn && (
                    <TableCell>
                      <FormattedMessage
                        id="openSubmission.inputUpload.table.uploadFileColumn"
                        defaultMessage="Upload File"
                      />
                    </TableCell>
                  )}
                  {parametersRequired && (
                    <TableCell align="center">
                      <FormattedMessage
                        id="openSubmission.inputUpload.table.parametersColumn"
                        defaultMessage="Parameters"
                      />
                    </TableCell>
                  )}
                </TableRow>
              </StyledTableHead>
              <InputUploadTableBody
                processing={processing || fetching}
                data={sortedInputs(inputs)}
                mapToRow={(input) => (
                  <InputRow
                    key={input.inputKey}
                    input={input}
                    virusScannerEnabled={virusScannerEnabled}
                    onInputsUpdated={onInputsUpdated}
                    onRemoveMedia={onRemoveMedia}
                    submissionReference={submission.reference}
                    sessionSchema={sessionSchema}
                    handleUploadChange={updateUploadingCount}
                    mediaColumn={mediaColumn}
                    displayParameters={parametersRequired}
                    handleUpdateInput={handleUpdateInput}
                  />
                )}
                noDataMessage={intl.formatMessage({
                  id: 'openSubmission.inputUpload.noUploadRequired',
                  defaultMessage: 'No uploads required.',
                })}
                numCols={calculateColsCount(mediaColumn, virusScannerEnabled, parametersRequired)}
              />
            </BrowseTable>
          </Grid>
          <Grid item xs={12}>
            {renderReadyMessage()}
          </Grid>
          <Grid item xs={6}>
            <FullWidthButton
              name="cancelSubmission"
              label={intl.formatMessage({
                id: 'openSubmission.inputUpload.cancelButton',
                defaultMessage: 'Cancel',
              })}
              disabled={processing || !submission.canUpdateSubmission || fetching}
              color="secondary"
              startIcon={<StopIcon />}
              onClick={cancelCurrentSubmission}
            />
          </Grid>
          <Grid item xs={6}>
            <MoreButton
              name="continueSubmission"
              moreName="automateSubmission"
              label={intl.formatMessage({
                id: 'openSubmission.inputUpload.continueButton',
                defaultMessage: 'Continue',
              })}
              disabled={
                !inputUploadsValid ||
                !virusScanStateValid ||
                uploadingCount > 0 ||
                !submission.canUpdateSubmission
              }
              processing={processing || fetching}
              color="primary"
              endIcon={<PlayArrowIcon />}
              onClick={startCurrentSubmission}
              onMore={handleOpenAutomationDialog}
            />
            <ConfigureAndContinue
              open={openAutomationDialog}
              onSubmit={startCurrentSubmission}
              onClose={handleCloseAutomationDialog}
            />
          </Grid>
        </Grid>
      ) : (
        <Loading
          loadingText={intl.formatMessage({
            id: 'openSubmission.inputUpload.uploading',
            defaultMessage: 'Uploading data…',
          })}
        />
      )}
    </Container>
  );
};

export default InputUpload;

import { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { ValidateFieldsError } from 'async-validator';
import { useSnackbar } from 'notistack';
import { add, formatISO, startOfDay } from 'date-fns';

import { Dialog, DialogTitle, DialogContent, FormHelperText, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ForwardIcon from '@mui/icons-material/Forward';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import * as ApiTokensApi from '../../../api/apiTokens';
import {
  PaddedDialogActions,
  DefaultButton,
  CopyToClipboard,
  MessageBox,
  ValidatedTextField,
} from '../../../components';
import { CreateApiTokenRequest, ApiTokenFullDetail, UserProfile } from '../../../types';
import { extractErrorMessage } from '../../../api/endpoints';
import { intl } from '../../../Internationalization';
import { newApiTokenSettingsValidator, validate } from '../../../validation';

interface NewApiTokenDialogProps {
  user: UserProfile;
  onCancel: () => void;
}

const NewApiTokenDialog: FC<NewApiTokenDialogProps> = ({ user, onCancel }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [processing, setProcessing] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [expiresAt, setExpiresAt] = useState<Date | null>(null);

  const [apiToken, setApiToken] = useState<ApiTokenFullDetail>();

  const handleClose = (_: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') {
      return;
    }
    onCancel();
  };

  const validateAndSubmit = async () => {
    setProcessing(true);
    const newApiTokenRequest = { name, description, expiresAt, userKey: user.key };
    try {
      const validatedResponse = await validate(
        newApiTokenSettingsValidator(() => user.key),
        newApiTokenRequest
      );
      createApiToken({
        ...validatedResponse,
        expiresAt: formatISO(startOfDay(validatedResponse.expiresAt!)),
      });
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const createApiToken = async (newApiTokenRequest: CreateApiTokenRequest) => {
    setFieldErrors(undefined);

    try {
      const response = await ApiTokensApi.createApiToken(newApiTokenRequest);
      setApiToken(response.data);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'apiToken.create.saveSuccess',
          defaultMessage: 'New API token created',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'apiToken.create.saveError',
            defaultMessage: 'Failed to create new API token',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessing(false);
    }
  };

  const renderNewForm = () => (
    <>
      <DialogContent dividers={true}>
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="name"
          label={intl.formatMessage({
            id: 'apiToken.create.name.label',
            defaultMessage: 'Name',
          })}
          value={name}
          onChange={(event) => setName(event.target.value)}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="description"
          label={intl.formatMessage({
            id: 'apiToken.create.description.label',
            defaultMessage: 'Description',
          })}
          value={description}
          onChange={(event) => setDescription(event.target.value)}
          margin="normal"
          variant="outlined"
        />
        <DesktopDatePicker
          label={intl.formatMessage({
            id: 'apiToken.create.expiresAt.label',
            defaultMessage: 'Expires At',
          })}
          value={expiresAt}
          onChange={(date) => setExpiresAt(date)}
          minDate={add(new Date(), { days: 1 })}
          disablePast
          disabled={processing}
          slotProps={{
            textField: {
              variant: 'outlined',
              margin: 'normal',
              name: 'expiresAt',
              error: !!fieldErrors?.expiresAt,
              fullWidth: true,
            },
          }}
        />
        {fieldErrors && fieldErrors.expiresAt && (
          <FormHelperText>{fieldErrors?.expiresAt[0]?.message}</FormHelperText>
        )}
      </DialogContent>
      <PaddedDialogActions>
        <DefaultButton
          name="cancelAddApiToken"
          color="secondary"
          onClick={onCancel}
          disabled={processing}
        >
          <FormattedMessage id="apiToken.create.cancelButton" defaultMessage="Cancel" />
        </DefaultButton>
        <DefaultButton
          id="addNewApiToken"
          onClick={validateAndSubmit}
          disabled={processing}
          startIcon={<AddIcon />}
        >
          <FormattedMessage id="apiToken.create.createButton" defaultMessage="Add API Token" />
        </DefaultButton>
      </PaddedDialogActions>
    </>
  );

  const renderApiToken = () => (
    <>
      <DialogContent dividers={true}>
        <MessageBox
          message={intl.formatMessage({
            id: 'apiToken.create.tokenInformation',
            defaultMessage:
              'Your token has been generated and is displayed below. You should copy this token now as you will not be able to view it again.',
          })}
          level="info"
        />
        <Box mt={3} mb={1}>
          <CopyToClipboard>{apiToken!.token}</CopyToClipboard>
        </Box>
      </DialogContent>
      <PaddedDialogActions>
        <DefaultButton
          name="continueApiTokenDetails"
          onClick={() => navigate(encodeURIComponent(apiToken!.name))}
          startIcon={<ForwardIcon />}
        >
          <FormattedMessage id="apiToken.create.continueButton" defaultMessage="Continue" />
        </DefaultButton>
      </PaddedDialogActions>
    </>
  );

  return (
    <Dialog
      onClose={handleClose}
      open={true}
      fullWidth
      id="new-api-token-dialog"
      aria-labelledby="new-api-token-dialog-title"
    >
      <DialogTitle id="new-api-token-dialog-title">
        <FormattedMessage id="apiToken.create.title" defaultMessage="Add API Token" />
      </DialogTitle>
      {apiToken ? renderApiToken() : renderNewForm()}
    </Dialog>
  );
};

export default NewApiTokenDialog;

import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { ValidateFieldsError } from 'async-validator';
import { FormattedMessage } from 'react-intl';
import isEqual from 'lodash/isEqual';

import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { useSnackbar } from 'notistack';

import { extractErrorMessage } from '../../../../../../api/endpoints';
import * as SpecificationApi from '../../../../../../api/specification';
import {
  DefaultButton,
  FormButtons,
  LabeledAddFab,
  MinWidthTableCell,
  TableInfoRow,
  TableLoadingRow,
} from '../../../../../../components';
import { useErrorBlock } from '../../../../../../contexts/error-block';
import { useNavigationPrompt } from '../../../../../../contexts/navigation-prompt';
import { SpecificationContext } from '../../SpecificationContext';
import {
  InputConfig,
  ReorderDirection,
  SessionSchema,
  SpecificationInputDetail,
} from '../../../../../../types';
import { usePrevious } from '../../../../../../hooks';
import { intl } from '../../../../../../Internationalization';
import { SPECIFICATION_INPUT_CONFIG_VALIDATOR, validate } from '../../../../../../validation';

import NoSessionMessage from '../NoSessionMessage';
import InputConfigTableRow from './InputConfigTableRow';
import AddInputDialog from './AddInputDialog';

const COL_SPAN = 5;

interface InputConfigEditorProps {
  sessionSchema: SessionSchema;
}

const InputConfigEditor: FC<InputConfigEditorProps> = ({ sessionSchema }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { raiseError } = useErrorBlock();
  const { raiseNavigationBlock, clearNavigationBlock } = useNavigationPrompt();
  const { specification, specificationKey, projectKey } = useContext(SpecificationContext);

  const [inputs, setInputs] = useState<SpecificationInputDetail[]>([]);
  const [expandedInput, setExpandedInput] = useState<SpecificationInputDetail>();
  const [fetching, setFetching] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [openInputDialog, setOpenInputDialog] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();
  const prevSessionSchema = usePrevious(sessionSchema);

  const validateAndSaveConfig = async (updatedInputs?: InputConfig[]) => {
    setProcessing(true);
    try {
      setFieldErrors({});
      saveConfig(
        (
          await validate(SPECIFICATION_INPUT_CONFIG_VALIDATOR, {
            inputs: updatedInputs ? updatedInputs : inputs,
          })
        ).inputs
      );
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const saveConfig = async (updatedInputs?: InputConfig[]) => {
    setProcessing(true);

    try {
      const response = await SpecificationApi.updateInputs(
        specificationKey,
        updatedInputs ? updatedInputs : inputs
      );
      setInputs(response.data);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'specification.configuration.inputConfig.saveSuccessful',
          defaultMessage: 'Inputs updated successfully',
        }),
        { variant: 'success' }
      );
      clearNavigationBlock();
    } catch (error: any) {
      raiseError(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'specification.configuration.inputConfig.saveError',
            defaultMessage: 'Failed to save inputs',
          })
        )
      );
    } finally {
      setProcessing(false);
    }
  };

  const fetchConfig = useCallback(async () => {
    try {
      setFetching(true);
      const response = await SpecificationApi.getInputs(specificationKey);
      setInputs(response.data);
      clearNavigationBlock();
    } catch (error: any) {
      raiseError(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'specification.configuration.inputConfig.loadError',
            defaultMessage: 'Failed to fetch inputs',
          })
        )
      );
    } finally {
      setFetching(false);
    }
  }, [clearNavigationBlock, raiseError, specificationKey]);

  useEffect(() => {
    if (!isEqual(prevSessionSchema, sessionSchema)) {
      fetchConfig();
    }
  }, [fetchConfig, prevSessionSchema, sessionSchema]);

  const handleAddInput = (newInput: InputConfig) => {
    const updatedInputs = [...(inputs || [])] as InputConfig[];

    updatedInputs.push(newInput);
    validateAndSaveConfig(updatedInputs);
  };

  const handleUpdateInput = (updatedInput: SpecificationInputDetail) => {
    raiseNavigationBlock();
    setInputs((prevInputs) => {
      const updatedInputs = [...(prevInputs || [])];

      const inputIndex = updatedInputs.findIndex((d) => d.key === updatedInput.key);
      updatedInputs[inputIndex] = updatedInput;

      return updatedInputs;
    });
  };

  const handleRemoveInput = (inputKey: string) => {
    if (expandedInput?.key === inputKey) {
      setExpandedInput(undefined);
    }
    setInputs((prev) => {
      const updatedInputs = [...prev];
      const index = updatedInputs.findIndex((input) => input.key === inputKey);

      updatedInputs.splice(index, 1);

      return updatedInputs;
    });
  };

  const handleReorderAction = (inputKey: string, direction: ReorderDirection) => {
    const index = inputs.findIndex((input) => input.key === inputKey);
    if (
      (index === 0 && direction === 'UP') ||
      (index === inputs.length - 1 && direction === 'DOWN')
    ) {
      return;
    }
    setInputs((prevInputs) => {
      const updatedArray = [...prevInputs];
      const to = direction === 'UP' ? index - 1 : index + 1;
      [updatedArray[index], updatedArray[to]] = [updatedArray[to], updatedArray[index]];
      return updatedArray;
    });
    raiseNavigationBlock();
  };

  const handleOpenAddInputDialog = async () => {
    setFieldErrors({});
    try {
      await validate(SPECIFICATION_INPUT_CONFIG_VALIDATOR, {
        inputs: inputs,
      });
      setOpenInputDialog(true);
    } catch (errors: any) {
      setFieldErrors(errors);
    }
  };

  const handleCloseAddInputDialog = () => {
    setOpenInputDialog(false);
  };

  const tableContent = () => {
    if (fetching) {
      return {
        className: 'InputConfigEditor-loading',
        content: (
          <TableBody>
            <TableLoadingRow colSpan={COL_SPAN} />
          </TableBody>
        ),
      };
    }

    if (!inputs?.length) {
      return {
        className: 'InputConfigEditor-noContent',
        content: (
          <TableBody>
            <TableInfoRow
              colSpan={COL_SPAN}
              size="medium"
              message={intl.formatMessage({
                id: 'specification.configuration.inputConfig',
                defaultMessage: 'No inputs for selected session',
              })}
            />
          </TableBody>
        ),
      };
    }

    if (!specification.sessionPath) {
      return {
        className: 'InputConfigEditor-noSessions',
        content: (
          <TableBody>
            <TableRow>
              <TableCell colSpan={COL_SPAN}>
                <NoSessionMessage projectKey={projectKey} specificationKey={specificationKey} />
              </TableCell>
            </TableRow>
          </TableBody>
        ),
      };
    }

    return {
      className: 'InputConfigEditor-loaded',
      content: (
        <TableBody>
          {inputs.map((input, index) => {
            const fieldError = fieldErrors && fieldErrors[`inputs.${index}.name`];
            return (
              <InputConfigTableRow
                key={input.key}
                input={input}
                disabled={processing || fetching}
                disablePrev={index === 0}
                disableNext={index === inputs.length - 1}
                expandedInput={expandedInput}
                handleRemoveInput={handleRemoveInput}
                handleExpandInput={setExpandedInput}
                handleUpdateInput={handleUpdateInput}
                handleReorderAction={handleReorderAction}
                dataStoreSchemas={sessionSchema.dataStores.filter(
                  (dataStore) =>
                    !inputs.find(({ linkedDataStores }) =>
                      linkedDataStores.includes(dataStore.path)
                    )
                )}
                fieldError={fieldError && { name: [fieldError[0]] }}
              />
            );
          })}
        </TableBody>
      ),
    };
  };

  const content = tableContent();

  return (
    <Box id="specification-input-config-editor">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage
                id="specification.configuration.inputConfig.table.nameColumn"
                defaultMessage="Name"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="specification.configuration.inputConfig.table.requiredColumn"
                defaultMessage="Required"
              />
            </TableCell>
            <MinWidthTableCell />
            <TableCell>
              <FormattedMessage
                id="specification.configuration.inputConfig.table.linkedToColumn"
                defaultMessage="Linked to"
              />
            </TableCell>
            <MinWidthTableCell />
          </TableRow>
        </TableHead>
        {content.content}
      </Table>
      <FormButtons>
        <DefaultButton
          name="saveInputConfig"
          startIcon={<SaveIcon />}
          onClick={() => validateAndSaveConfig()}
          disabled={processing || fetching}
        >
          <FormattedMessage
            id="specification.configuration.inputConfig.saveButton"
            defaultMessage="Save input config"
          />
        </DefaultButton>
      </FormButtons>
      <AddInputDialog
        dialogOpen={openInputDialog}
        onConfirm={handleAddInput}
        onCloseDialog={handleCloseAddInputDialog}
        disabled={processing || fetching}
      />
      <LabeledAddFab
        name="createInput"
        label={intl.formatMessage({
          id: 'specification.configuration.inputConfig.addInputButton',
          defaultMessage: 'Add input',
        })}
        onClick={handleOpenAddInputDialog}
        disabled={processing || fetching}
      />
    </Box>
  );
};
export default InputConfigEditor;

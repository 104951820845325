import { useSnackbar } from 'notistack';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { NamedLocationDetail } from '../../../../types';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import { intl } from '../../../../Internationalization';
import * as NamedLocationApi from '../../../../api/namedLocation';
import { extractErrorMessage } from '../../../../api/endpoints';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box/Box';
import { List, ListItemButton, ListItemText, Skeleton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface DeleteNamedLocationDialogProps {
  namedLocationKey?: string;
  setNamedLocationKey: Dispatch<SetStateAction<string | undefined>>;
  refresh: () => void;
}

const DeleteNamedLocationDialog: FC<DeleteNamedLocationDialogProps> = ({
  namedLocationKey,
  setNamedLocationKey,
  refresh,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [processing, setProcessing] = useState(false);

  const [namedLocationDetail, setNamedLocationDetail] = useState<NamedLocationDetail>();
  const [disableDelete, setDisableDelete] = useState<boolean>();

  const MAX_USAGES = 5;

  const handleRefreshNamedLocation = useCallback(async () => {
    if (!namedLocationKey) {
      return;
    }
    try {
      setProcessing(true);
      const response = await NamedLocationApi.getNamedLocation(namedLocationKey);
      setNamedLocationDetail(response.data);
      setDisableDelete(response.data && response.data.usedIn.length > 0);
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'systemActivity.namedLocation.refreshError',
            defaultMessage: 'Failed to fetch named location',
          })
        ),
        { variant: 'error' }
      );
      setDisableDelete(false);
    } finally {
      setProcessing(false);
    }
  }, [enqueueSnackbar, namedLocationKey]);

  useEffect(() => {
    if (!!namedLocationKey) {
      handleRefreshNamedLocation();
    }
  }, [namedLocationKey, handleRefreshNamedLocation]);

  const handleDeleteNamedLocation = async () => {
    if (!namedLocationKey) {
      return;
    }
    try {
      await NamedLocationApi.deleteNamedLocation(namedLocationKey);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'systemActivity.namedLocations.deleteSuccess',
          defaultMessage: 'Named location deleted',
        }),
        { variant: 'success' }
      );
      refresh();
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'systemActivity.namedLocations.deleteError',
            defaultMessage: 'Failed to delete named location',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setNamedLocationKey(undefined);
      setDisableDelete(true);
    }
  };

  const renderNamedLocationUsages = () => {
    if (processing) {
      return (
        <>
          <Box py={2}>
            <Skeleton variant="text" />
          </Box>
          <Typography>
            <Skeleton variant="text" width={170} />
          </Typography>
          <List>
            {[...Array(3)].map((_, index) => (
              <ListItemButton key={index}>
                <ListItemText primary={<Skeleton />} />
              </ListItemButton>
            ))}
          </List>
        </>
      );
    }

    if (namedLocationDetail && namedLocationDetail?.usedIn.length > 0) {
      return (
        <>
          <Box py={2}>
            <Typography>
              <FormattedMessage
                id="systemActivity.namedLocations.stillInUse"
                defaultMessage="This named location is currently in use. Once the named location has been removed from all actions it can be deleted."
              />
            </Typography>
          </Box>
          {namedLocationDetail && namedLocationDetail.usedIn && (
            <>
              <Typography>
                <FormattedMessage
                  id="systemActivity.namedLocations.usagesCount"
                  defaultMessage="Displaying {shown} of {total} usages:"
                  values={{
                    shown: Math.min(namedLocationDetail.usedIn.length, MAX_USAGES),
                    total: namedLocationDetail.usedIn.length,
                  }}
                />
              </Typography>
              <List>
                {namedLocationDetail.usedIn.slice(0, MAX_USAGES).map((usedIn) => {
                  return (
                    <ListItemButton
                      key={usedIn.specificationKey}
                      component={Link}
                      to={`/projects/${usedIn.projectKey}/specifications/${usedIn.specificationKey}/configuration`}
                    >
                      <ListItemText
                        primary={intl.formatMessage(
                          {
                            id: 'systemActivity.namedLocations.usage',
                            defaultMessage:
                              'Project: {projectName}. Specification: {specificationName}.',
                          },
                          { ...usedIn }
                        )}
                      />
                    </ListItemButton>
                  );
                })}
              </List>
            </>
          )}
        </>
      );
    }
  };

  return (
    <ConfirmDialog
      id="confirm-delete-named-location"
      isOpen={!!namedLocationKey}
      title={intl.formatMessage({
        id: 'systemActivity.namedLocations.confirmDelete.title',
        defaultMessage: 'Delete Named Location',
      })}
      text={intl.formatMessage({
        id: 'systemActivity.namedLocations.confirmDelete.text',
        defaultMessage: 'Are you sure you wish to delete this named location?',
      })}
      confirmBtnText={intl.formatMessage({
        id: 'systemActivity.namedLocations.confirmDelete.confirmButton',
        defaultMessage: 'Delete Named Location',
      })}
      confirmAction={handleDeleteNamedLocation}
      closeAction={() => setNamedLocationKey(undefined)}
      disableAction={disableDelete}
      maxWidth="sm"
      fullWidth
    >
      {renderNamedLocationUsages()}
    </ConfirmDialog>
  );
};

export default DeleteNamedLocationDialog;

import { FC, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { ValidateFieldsError } from 'async-validator';

import { Dialog, DialogTitle, DialogContent, Checkbox } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import * as ReceiverMappingsApi from '../../../api/receiverMappings';
import { AxiosApiError, extractErrorMessage } from '../../../api/endpoints';
import {
  PaddedDialogActions,
  DefaultButton,
  BlockFormControlLabel,
  ValidatedTextField,
} from '../../../components';
import { intl } from '../../../Internationalization';
import { ReceiverMappingSettings } from '../../../types';
import { receiverMappingValidator, validate } from '../../../validation';

interface NewReceiverMappingFormProps {
  identityProviderKey: string;
  onCreate: () => void;
  onClose: () => void;
}

const NewReceiverMappingForm: FC<NewReceiverMappingFormProps> = ({
  identityProviderKey,
  onCreate,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [admin, setAdmin] = useState<boolean>(false);
  const [group, setGroup] = useState<string>('');

  const [processing, setProcessing] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const VALIDATOR = receiverMappingValidator(() => identityProviderKey);

  const validateAndSubmit = async () => {
    setProcessing(true);
    try {
      createReceiverMapping(await validate(VALIDATOR, { group, identityProviderKey, admin }));
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const createReceiverMapping = (settings: ReceiverMappingSettings) => {
    ReceiverMappingsApi.createReceiverMapping(settings)
      .then(() => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'identityProvider.receiverMappings.add.saveSuccess',
            defaultMessage: 'Receiver mapping has been added',
          }),
          { variant: 'success' }
        );
        onCreate();
        onClose();
      })
      .catch((error: AxiosApiError) => {
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'identityProvider.receiverMappings.add.saveError',
              defaultMessage: 'Failed to add receiver mapping',
            })
          ),
          { variant: 'error' }
        );
        setProcessing(false);
      });
  };

  return (
    <Dialog
      id="new-receiver-mapping-dialog"
      onClose={onClose}
      aria-labelledby="new-receiver-mapping-dialog-title"
      open={true}
      fullWidth
    >
      <DialogTitle id="new-receiver-mapping-dialog-title">
        <FormattedMessage
          id="identityProvider.receiverMappings.add.title"
          defaultMessage="Add Receiver Mapping"
        />
      </DialogTitle>
      <DialogContent dividers={true}>
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="group"
          label={intl.formatMessage({
            id: 'identityProvider.receiverMappings.add.group.label',
            defaultMessage: 'Group',
          })}
          value={group}
          onChange={(event) => setGroup(event.target.value)}
          margin="normal"
          variant="outlined"
        />
        <BlockFormControlLabel
          control={
            <Checkbox
              color="primary"
              name="admin"
              checked={admin}
              onChange={(event) => setAdmin(event.target.checked)}
            />
          }
          label={intl.formatMessage({
            id: 'identityProvider.receiverMappings.add.admin.label',
            defaultMessage: 'Site Administrator?',
          })}
        />
      </DialogContent>
      <PaddedDialogActions>
        <DefaultButton
          id="cancel-add-receiver-mapping"
          color="secondary"
          onClick={onClose}
          disabled={processing}
        >
          <FormattedMessage
            id="identityProvider.receiverMappings.add.cancelButton"
            defaultMessage="Cancel"
          />
        </DefaultButton>
        <DefaultButton
          id="add-receiver-mapping"
          onClick={validateAndSubmit}
          disabled={processing}
          startIcon={<AddIcon />}
        >
          <FormattedMessage
            id="identityProvider.receiverMappings.add.addButton"
            defaultMessage="Add Receiver Mapping"
          />
        </DefaultButton>
      </PaddedDialogActions>
    </Dialog>
  );
};

export default NewReceiverMappingForm;

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { SxProps } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import * as SubmissionMediaApi from '../../api/submissionMedia';
import { SubmissionDetail } from '../../types';

import { DefaultButton } from '.';

export interface DownloadLogButtonProps {
  submission: SubmissionDetail;
  sx?: SxProps;
}

const DownloadLogButton: React.FC<DownloadLogButtonProps> = ({ sx = [], submission }) => {
  if (!submission.log) {
    return null;
  }

  return (
    <DefaultButton
      disabled={submission.mediaRemoved}
      href={SubmissionMediaApi.downloadSubmissionLogFileUri(submission?.reference, submission.log)}
      download
      color="secondary"
      startIcon={<DescriptionOutlinedIcon />}
      sx={sx}
    >
      <FormattedMessage id="components.downloadLogButton.label" defaultMessage="Download log" />
    </DefaultButton>
  );
};

export default DownloadLogButton;

import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';
import { isEqual } from 'lodash';

import { Box } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { extractErrorMessage } from '../../../../../../api/endpoints';
import * as SpecificationApi from '../../../../../../api/specification';
import {
  DefaultButton,
  FormButtons,
  MessageBox,
  CardGrid,
  Loading,
} from '../../../../../../components';
import { DataStoreSchema, DataStoreConfigDetail } from '../../../../../../types';
import { ErrorBlockContext } from '../../../../../../contexts/error-block';
import { NavigationPromptContext } from '../../../../../../contexts/navigation-prompt';
import { intl } from '../../../../../../Internationalization';
import { usePrevious } from '../../../../../../hooks';

import { SpecificationContext } from '../../SpecificationContext';
import NoSessionMessage from '../NoSessionMessage';
import DataStoreConfigCards from './DataStoreConfigCards';

export const findDataStoreSchema = (dataStorePath: string, dataStoreSchemas: DataStoreSchema[]) => {
  return dataStoreSchemas.find((d) => d.path === dataStorePath);
};

interface DataStoreConfigEditorProps {
  dataStoreSchemas: DataStoreSchema[];
}

const DataStoreConfigEditor: FC<DataStoreConfigEditorProps> = ({ dataStoreSchemas }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    projectKey,
    specificationKey,
    specification,
    specificationValidationResult,
    validateSpecification,
  } = useContext(SpecificationContext);
  const { raiseError } = useContext(ErrorBlockContext);
  const { raiseNavigationBlock, clearNavigationBlock } = useContext(NavigationPromptContext);

  const [dataStoreConfigs, setDataStoreConfigs] = useState<DataStoreConfigDetail[]>();
  const [savingConfig, setSavingConfig] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);

  const previousDataStoreSchemas = usePrevious(dataStoreSchemas);

  const fetchConfig = useCallback(async () => {
    setFetching(true);
    try {
      const response = await SpecificationApi.getDataStoreConfigs(specificationKey);
      setDataStoreConfigs(response.data);
      validateSpecification();
      setFetching(false);
    } catch (error: any) {
      raiseError(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'specification.configuration.dataStoreConfig.loadError',
            defaultMessage: 'Failed to fetch specification config',
          })
        )
      );
    }
  }, [raiseError, specificationKey, validateSpecification]);

  useEffect(() => {
    if (!isEqual(previousDataStoreSchemas, dataStoreSchemas)) {
      fetchConfig();
    }
  }, [fetchConfig, dataStoreSchemas, previousDataStoreSchemas]);

  const updateDataStoreConfig = (config: DataStoreConfigDetail) => {
    raiseNavigationBlock();

    setDataStoreConfigs((prevDataStoreConfigs) => {
      const updatedDataStoreConfigs = [...(prevDataStoreConfigs || [])];
      const currentConfigIndex = updatedDataStoreConfigs.findIndex((d) => d.path === config.path);
      updatedDataStoreConfigs[currentConfigIndex] = config;

      return updatedDataStoreConfigs;
    });
  };

  const saveDataStores = async () => {
    if (!dataStoreConfigs) {
      return;
    }

    setSavingConfig(true);
    try {
      const updatedDataStoreConfig = dataStoreConfigs.map((config) => ({
        ...config,
        overrideParameters: config.overrideParameters.map((parameter) => parameter.name),
      }));
      const response = await SpecificationApi.updateDataStoreConfigs(
        specificationKey,
        updatedDataStoreConfig
      );
      clearNavigationBlock();
      setDataStoreConfigs(response.data);
      setSavingConfig(false);
      validateSpecification();
      enqueueSnackbar(
        intl.formatMessage({
          id: 'specification.configuration.dataStoreConfig.saveSuccess',
          defaultMessage: 'Save successful',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      setSavingConfig(false);
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'specification.configuration.dataStoreConfig.saveError',
            defaultMessage: 'Failed to save datastore config',
          })
        ),
        { variant: 'error' }
      );
    }
  };

  const renderCardGridContent = () => {
    if (dataStoreConfigs) {
      return {
        className: 'DataStoreConfigEditor-loaded',
        content: (
          <DataStoreConfigCards
            dataStoreConfigs={dataStoreConfigs}
            dataStoreSchemas={dataStoreSchemas}
            savingConfig={savingConfig}
            updateDataStoreConfig={updateDataStoreConfig}
          />
        ),
      };
    }

    if (!specification.sessionPath && !fetching) {
      return {
        className: 'DataStoreConfigEditor-noContent',
        content: <NoSessionMessage projectKey={projectKey} specificationKey={specificationKey} />,
      };
    }
    return {
      className: 'DataStoreConfigEditor-loading',
      content: <Loading />,
    };
  };

  const cards = renderCardGridContent();
  return (
    <Box id="specification-datastore-editor" className={cards.className}>
      {!specificationValidationResult.dataStoresValid && (
        <MessageBox
          level="warning"
          gutterBottom
          message={intl.formatMessage({
            id: 'specification.configuration.dataStoreConfig.invalidSession',
            defaultMessage:
              'Session changes detected, review and save the data store configuration below.',
          })}
        />
      )}
      <CardGrid>{cards.content}</CardGrid>
      <FormButtons>
        <DefaultButton
          name="saveDataStoreConfig"
          disabled={savingConfig || !specification.sessionPath || fetching}
          onClick={saveDataStores}
          startIcon={<SaveIcon />}
        >
          <FormattedMessage
            id="specification.configuration.dataStoreConfig.saveButton"
            defaultMessage="Save datastore Config"
          />
        </DefaultButton>
      </FormButtons>
    </Box>
  );
};

export default DataStoreConfigEditor;

import React, { FC, CSSProperties } from 'react';
import { Typography } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';

import { AvatarPaper } from '../../components';

interface StatPaperProps {
  id?: string;
  name: string;
  stat: number;
  icon: React.ComponentType<SvgIconProps>;
  iconColor: CSSProperties['backgroundColor'];
}

const StatPaper: FC<StatPaperProps> = ({ id, name, stat, icon, iconColor }) => (
  <AvatarPaper
    id={id}
    sx={{ textAlign: 'right' }}
    icon={icon}
    iconColor={iconColor}
    className="StatPaper"
  >
    <Typography variant="h6" color="textSecondary">
      {name}
    </Typography>
    <Typography variant="h4" component="p" color="textPrimary">
      {stat}
    </Typography>
  </AvatarPaper>
);

export default StatPaper;

import { FC, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { pickBy } from 'lodash';

import {
  Box,
  DialogContent,
  MenuItem,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Select,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import * as SpecificationApi from '../../../../../api/specification';
import { extractErrorMessage } from '../../../../../api/endpoints';
import { DefaultButton, TableInfoRow, TableLoadingRow } from '../../../../../components';
import { intl } from '../../../../../Internationalization';
import { DataStoreConfigDetail, SavedMappingDetail } from '../../../../../types';
import { dataStoreNameFromPath } from '../../../../../util';

import { MyAssignmentContext } from '../../MyAssignmentContext';

import SavedMappingBreadcrumb from './SavedMappingBreadcrumb';
import CopySavedMappingActions from './CopySavedMappingActions';
import CopySavedMappingTable from './CopySavedMappingTable';

interface AssignDataStoresProps {
  sourceSavedMapping: SavedMappingDetail;
  onSelectDataStoreMappings: (dataStoreMappings: Record<string, string>) => void;

  onCancel: () => void;
  onBack: () => void;
}

const calculateDefaultMappings = (
  sourceSavedMapping: SavedMappingDetail,
  dataStoreConfigs: DataStoreConfigDetail[]
) =>
  Object.fromEntries(
    sourceSavedMapping.mappings.flatMap((sourceDataStoreMapping) => {
      const sourceDataStoreMappingName = dataStoreNameFromPath(sourceDataStoreMapping.path);
      const candidateMappings = dataStoreConfigs.flatMap((dataStoreConfig) => {
        return dataStoreNameFromPath(dataStoreConfig.path) === sourceDataStoreMappingName
          ? [dataStoreConfig]
          : [];
      });
      return candidateMappings.length === 1
        ? [[sourceDataStoreMapping.path, candidateMappings[0].path]]
        : [];
    })
  );

const AssignDataStores: FC<AssignDataStoresProps> = ({
  sourceSavedMapping,
  onSelectDataStoreMappings,
  onCancel,
  onBack,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { specification } = useContext(MyAssignmentContext);

  const [dataStoreConfigs, setDataStoreConfigs] = useState<DataStoreConfigDetail[]>();
  const [dataStoreMappings, setDataStoreMappings] = useState<Record<string, string>>({});

  useEffect(() => {
    const fetchSpecificationDataSets = async () => {
      try {
        const response = await SpecificationApi.getDataStoreConfigs(specification.key);
        setDataStoreMappings(calculateDefaultMappings(sourceSavedMapping, response.data));
        setDataStoreConfigs(response.data);
      } catch (error: any) {
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'myAssignment.savedMapping.copy.assignDataStores.loadError',
              defaultMessage: 'Failed to fetch specification data store configurations',
            })
          ),
          { variant: 'error' }
        );
      }
    };

    fetchSpecificationDataSets();
  }, [enqueueSnackbar, setDataStoreMappings, sourceSavedMapping, specification.key]);

  const handleSelectDataStoreMapping = (sourceDataStore: string, targetDataStore?: string) => {
    setDataStoreMappings((prevMappings) => {
      const newMappings = pickBy(prevMappings, (_, k) => sourceDataStore !== k);
      if (targetDataStore) {
        newMappings[sourceDataStore] = targetDataStore;
      }
      return newMappings;
    });
  };

  const handleNext = () => {
    onSelectDataStoreMappings(dataStoreMappings);
  };

  const renderTableRows = () => {
    if (!dataStoreConfigs) {
      return <TableLoadingRow height={100} colSpan={3} />;
    }

    if (!sourceSavedMapping.mappings.length) {
      return (
        <TableInfoRow
          colSpan={3}
          size="medium"
          message={intl.formatMessage({
            id: 'myAssignment.savedMapping.copy.assignDataStores.noDataStores',
            defaultMessage: 'No data sets to display',
          })}
        />
      );
    }

    return sourceSavedMapping.mappings.map(({ path: mappingDataStore }) => {
      const assignmentDataStore = dataStoreMappings[mappingDataStore];
      return (
        <TableRow key={mappingDataStore}>
          <TableCell>
            <Tooltip title={mappingDataStore} placement="right">
              <span>{dataStoreNameFromPath(mappingDataStore)}</span>
            </Tooltip>
          </TableCell>
          <TableCell>
            <Select
              displayEmpty
              name="dataStoreMapping"
              fullWidth
              value={assignmentDataStore || ''}
              variant="outlined"
              margin="dense"
              onChange={(event) =>
                handleSelectDataStoreMapping(mappingDataStore, event.target.value as string)
              }
            >
              <MenuItem value="">
                <Typography component="div">
                  <FormattedMessage
                    id="myAssignment.savedMapping.copy.assignDataStores.doNotCopy.message"
                    defaultMessage="Do not copy mappings for this data set"
                  />
                </Typography>
              </MenuItem>
              {/* Removed uploadMode filter: uploadMode !== UploadMode.NOT_SUPPORTED */}
              {dataStoreConfigs
                .filter(
                  (d) =>
                    d.path === assignmentDataStore ||
                    !Object.values(dataStoreMappings).find((value) => value === d.path)
                )
                .map(({ path }) => (
                  <MenuItem key={path} value={path}>
                    <Tooltip title={path} placement="bottom-start">
                      <Typography component="div">{dataStoreNameFromPath(path)}</Typography>
                    </Tooltip>
                  </MenuItem>
                ))}
            </Select>
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <>
      <DialogContent>
        <Box p={2}>
          <SavedMappingBreadcrumb savedMapping={sourceSavedMapping} displaySavedMapping />
        </Box>
        <CopySavedMappingTable>{renderTableRows()}</CopySavedMappingTable>
      </DialogContent>
      <CopySavedMappingActions
        onCancel={onCancel}
        onBack={onBack}
        nextButton={
          <DefaultButton
            name="next"
            onClick={handleNext}
            disabled={!!dataStoreMappings.length}
            endIcon={<NavigateNextIcon />}
          >
            <FormattedMessage
              id="myAssignment.savedMapping.copy.assignDataStores.nextButton"
              defaultMessage="Next"
            />
          </DefaultButton>
        }
      />
    </>
  );
};

export default AssignDataStores;

import Schema from 'async-validator';
import { NOT_BLANK_REGEX, duplicateValidator, notTrimmableValidator } from './shared';
import { intl } from '../Internationalization';
import { identityProviderByName } from '../api/identityProviderLookup';
import { AXIOS, entityDoesNotExist } from '../api/endpoints';

const IDENTITY_PROVIDER_ID_REGEX = /^[a-zA-Z0-9_]+$/;

export function identityProviderUniqueByKey(identityProviderKey: string): Promise<boolean> {
  return entityDoesNotExist(AXIOS.head(`/identity_providers/${identityProviderKey}`));
}

const nameValidator = (getExistingName?: () => string) => [
  {
    required: true,
    message: intl.formatMessage({
      id: 'identityProvider.validator.name.required',
      defaultMessage: 'Please provide a name',
    }),
  },
  notTrimmableValidator,
  duplicateValidator({
    regex: NOT_BLANK_REGEX,
    existingValue: getExistingName,
    checkUnique: identityProviderByName,
    alreadyExistsMessage: intl.formatMessage({
      id: 'identityProvider.validator.name.unique',
      defaultMessage: 'An identity provider with this name already exists',
    }),
    errorMessage: intl.formatMessage({
      id: 'identityProvider.validator.name.checkUniqueError',
      defaultMessage: 'There was a problem verifying the identity provider name',
    }),
  }),
];

export function identityProviderSettingsValidator(getExistingName?: () => string): Schema {
  return new Schema({
    name: nameValidator(getExistingName),
    emailAttribute: {
      required: true,
      message: intl.formatMessage({
        id: 'identityProvider.validator.emailAttribute.required',
        defaultMessage: 'Please provide an email attribute',
      }),
    },
    nameAttribute: {
      required: true,
      message: intl.formatMessage({
        id: 'identityProvider.validator.nameAttribute.required',
        defaultMessage: 'Please provide a name attribute',
      }),
    },
    groupAttribute: {
      required: true,
      message: intl.formatMessage({
        id: 'identityProvider.validator.groupAttribute.required',
        defaultMessage: 'Please provide a group attribute',
      }),
    },
  });
}

export const CREATE_IDENTITY_PROVIDER_SETTINGS_VALIDATOR = new Schema({
  name: nameValidator(),
  key: [
    {
      required: true,
      pattern: IDENTITY_PROVIDER_ID_REGEX,
      message: intl.formatMessage({
        id: 'identityProvider.validator.key.required',
        defaultMessage: 'Please provide a valid key',
      }),
    },
    duplicateValidator({
      regex: IDENTITY_PROVIDER_ID_REGEX,
      checkUnique: identityProviderUniqueByKey,
      alreadyExistsMessage: intl.formatMessage({
        id: 'identityProvider.validator.key.unique',
        defaultMessage: 'An identity provider with this key already exists',
      }),
      errorMessage: intl.formatMessage({
        id: 'identityProvider.validator.key.checkUniqueError',
        defaultMessage: 'There was a problem verifying the identity provider key',
      }),
    }),
  ],
});

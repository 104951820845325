import { FC, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { pdf } from '@react-pdf/renderer';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Checkbox,
  Menu,
  MenuItem,
  Link,
} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

import { DefaultButton, InputTooltip, PaddedDialogActions } from '../../../components';
import { extractErrorMessage } from '../../../api/endpoints';
import { intl } from '../../../Internationalization';
import * as SubmissionMediaApi from '../../../api/submissionMedia';

import ResultPdf, { TaskFilters } from './pdf/ResultPdf';
import { SubmissionContext } from './SubmissionContext';
import { ApplicationContext } from '../../../contexts/application';
import { AuthenticatedContext } from '../../../contexts/authentication';

const DEFAULT_TASK_FILTERS: TaskFilters = {
  hideNotFinished: false,
  hideZeroProcessedTasks: false,
  hideZeroProcessedRows: false,
};

const DownloadReports: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { me } = useContext(AuthenticatedContext);
  const {
    applicationDetails: {
      site: {
        resources: { logo },
      },
    },
  } = useContext(ApplicationContext);

  const { submission, project, specification, assignment } = useContext(SubmissionContext);
  const title = `${project.name} - ${specification.name}`;

  const [creatingPdf, setCreatingPdf] = useState<boolean>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [taskFilters, setTaskFilters] = useState<TaskFilters>({ ...DEFAULT_TASK_FILTERS });

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const appliedSchemaMappingUrl =
    submission.appliedSchemaMapping &&
    SubmissionMediaApi.downloadAppliedSchemaFileUri(
      submission.reference,
      submission.appliedSchemaMapping
    );

  const generatePdf = async () => {
    setCreatingPdf(true);
    try {
      const blob = await pdf(
        <ResultPdf
          title={title}
          submission={submission}
          taskFilters={taskFilters}
          logo={logo}
          isReceiver={!!me.receiverPermissions}
        />
      ).toBlob();
      saveAs(
        blob,
        intl.formatMessage(
          {
            id: 'submission.downloadReports.pdfFilename',
            defaultMessage: 'Submission Report - {assignmentReference}.pdf',
          },
          { assignmentReference: assignment.reference }
        )
      );
      setDialogOpen(false);
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'submission.downloadReports.generatePdfError',
            defaultMessage: 'Failed to generate PDF',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setCreatingPdf(false);
    }
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenDialog = () => {
    handleCloseMenu();
    setTaskFilters({ ...DEFAULT_TASK_FILTERS });
    setDialogOpen(true);
  };

  return (
    <>
      <>
        <DefaultButton
          name="downloadReports"
          color="secondary"
          download
          startIcon={<PictureAsPdfIcon />}
          endIcon={<ArrowDropDown />}
          onClick={handleOpenMenu}
        >
          <FormattedMessage
            id="submission.downloadReports.reportsButton"
            defaultMessage="Reports"
          />
        </DefaultButton>
        <Menu
          id="submission-reports"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <MenuItem
            href={appliedSchemaMappingUrl}
            component={Link}
            disabled={!appliedSchemaMappingUrl}
          >
            <FormattedMessage
              id="submission.downloadReports.schemaMappingReportButton"
              defaultMessage="Schema Mapping"
            />
          </MenuItem>
          <MenuItem onClick={handleOpenDialog}>
            <FormattedMessage
              id="submission.downloadReports.submissionReportButton"
              defaultMessage="Submission Report"
            />
          </MenuItem>
        </Menu>
      </>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        id="hide-unprocessed-tasks-dialog"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <FormattedMessage
            id="submission.downloadReports.title"
            defaultMessage="Customise Report PDF"
          />
        </DialogTitle>
        <DialogContent>
          <InputTooltip
            title={intl.formatMessage({
              id: 'submission.downloadReports.taskFilters.hideNotFinished.tooltip',
              defaultMessage: 'Tasks which have not finished will be omitted from the report.',
            })}
            data-tooltip-for="hideNotFinished"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={taskFilters.hideNotFinished}
                  onChange={(event) =>
                    setTaskFilters((prev) => ({ ...prev, hideNotFinished: event.target.checked }))
                  }
                  name="hideNotFinished"
                  color="primary"
                  disabled={creatingPdf}
                />
              }
              label={intl.formatMessage({
                id: 'submission.downloadReports.taskFilters.hideNotFinished.label',
                defaultMessage: 'Hide tasks which have not finished',
              })}
            />
          </InputTooltip>
          <InputTooltip
            data-tooltip-for="hideZeroProcessedTasks"
            title={intl.formatMessage({
              id: 'submission.downloadReports.taskFilters.hideZeroProcessedTasks.tooltip',
              defaultMessage: 'Tasks which processed no features will be omitted from the report.',
            })}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={taskFilters.hideZeroProcessedTasks}
                  onChange={(event) =>
                    setTaskFilters((prev) => ({
                      ...prev,
                      hideZeroProcessedTasks: event.target.checked,
                    }))
                  }
                  name="hideZeroProcessedTasks"
                  color="primary"
                  disabled={creatingPdf}
                />
              }
              label={intl.formatMessage({
                id: 'submission.downloadReports.taskFilters.hideZeroProcessedTasks.label',
                defaultMessage: 'Hide tasks with no processed features',
              })}
            />
          </InputTooltip>
          <InputTooltip
            data-tooltip-for="hideZeroProcessedRows"
            title={intl.formatMessage({
              id: 'submission.downloadReports.taskFilters.hideZeroProcessedRows.tooltip',
              defaultMessage:
                'Classes which processed no features will be omitted from the report.',
            })}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={taskFilters.hideZeroProcessedRows}
                  onChange={(event) =>
                    setTaskFilters((prev) => ({
                      ...prev,
                      hideZeroProcessedRows: event.target.checked,
                    }))
                  }
                  name="hideZeroProcessedRows"
                  color="primary"
                  disabled={creatingPdf}
                />
              }
              label={intl.formatMessage({
                id: 'submission.downloadReports.taskFilters.hideZeroProcessedRows.label',
                defaultMessage: 'Hide rows with no processed features',
              })}
            />
          </InputTooltip>
        </DialogContent>
        <PaddedDialogActions>
          <DefaultButton
            disabled={creatingPdf}
            onClick={handleCloseDialog}
            color="secondary"
            id="cancel-hide-unprocessed-tasks"
          >
            <FormattedMessage
              id="submission.downloadReports.cancelButton"
              defaultMessage="Cancel"
            />
          </DefaultButton>
          <DefaultButton
            disabled={creatingPdf}
            onClick={generatePdf}
            id="submit-hide-unprocessed-tasks"
          >
            <FormattedMessage
              id="submission.downloadReports.generateButton"
              defaultMessage="Generate PDF"
            />
          </DefaultButton>
        </PaddedDialogActions>
      </Dialog>
    </>
  );
};

export default DownloadReports;

import { FC, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TableCell,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import * as SubmissionApi from '../../../../../../api/submission';
import { extractErrorMessage } from '../../../../../../api/endpoints';
import {
  ButtonRow,
  ConfirmDialog,
  DefaultButton,
  PaddedDialogActions,
  ValidatedTextField,
} from '../../../../../../components';
import {
  DataStoreDetail,
  DataStoreParameterDetail,
  SubmissionDataSetInputParameters,
  SubmissionInputParameters,
} from '../../../../../../types';
import { intl } from '../../../../../../Internationalization';

import InputParameterPassword from './InputParameterPassword';

const extractInputParams = (dataStoreInputParameters: DataStoreParameterDetail[]) => {
  const inputParams: Record<string, string> = {};
  dataStoreInputParameters.forEach(({ name, value, type }) => {
    if (type !== 'PASSWORD') {
      inputParams[name] = value;
    }
  });
  return inputParams;
};

interface InputParametersTableCellProps {
  submissionReference: string;
  dataStore: DataStoreDetail;

  dataStoreInputParameters?: DataStoreParameterDetail[];
  handleOnParametersUpdated: (parameters: SubmissionInputParameters) => void;

  disabled: boolean;
}

const InputParametersTableCell: FC<InputParametersTableCellProps> = ({
  submissionReference,
  dataStore,
  dataStoreInputParameters = [],
  handleOnParametersUpdated,
  disabled,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [processing, setProcessing] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [clearParameterConfirm, setClearParameterConfirm] = useState<boolean>(false);
  const [inputParameters, setInputParameters] = useState<SubmissionDataSetInputParameters>(
    extractInputParams(dataStoreInputParameters)
  );

  const handleInputParamChange = (name: string, value: string) => {
    setInputParameters((prevParams) => ({ ...prevParams, [name]: value }));
  };

  const handleResetStoredParameters = () => {
    const nulledParameters: SubmissionDataSetInputParameters = {};
    dataStore.parameters.forEach(({ name }) => {
      nulledParameters[name] = null;
    });
    handleSaveParameters(nulledParameters);
    setClearParameterConfirm(false);
  };

  const handleSaveParameters = useCallback(
    async (inputParams: SubmissionDataSetInputParameters) => {
      setProcessing(true);
      try {
        const response = await SubmissionApi.patchInputParameters(submissionReference, {
          [dataStore.dataStorePath]: inputParams,
        });
        handleOnParametersUpdated(response.data);
        setInputParameters(extractInputParams(response.data[dataStore.dataStorePath]));
        setDialogOpen(false);
        enqueueSnackbar(
          intl.formatMessage({
            id: 'openSubmission.inputUpload.parameters.save.success',
            defaultMessage: 'Input parameters updated successfully',
          }),
          { variant: 'success' }
        );
      } catch (error: any) {
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'openSubmission.inputUpload.parameters.save.error',
              defaultMessage: 'Failed to save input parameters',
            })
          ),
          { variant: 'error' }
        );
      } finally {
        setProcessing(false);
      }
    },
    [dataStore.dataStorePath, enqueueSnackbar, handleOnParametersUpdated, submissionReference]
  );

  if (!dataStoreInputParameters.length || !dataStore.parameters) {
    return <TableCell />;
  }

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setInputParameters(extractInputParams(dataStoreInputParameters));
  };

  return (
    <>
      <TableCell align="center">
        <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
          <DialogTitle>
            <FormattedMessage
              id="openSubmission.inputUpload.parameters.dialog.title"
              defaultMessage="Edit Input Parameters"
            />
          </DialogTitle>
          <DialogContent>
            {dataStoreInputParameters.map(({ type, name, valuePresent }) => {
              if (type === 'PASSWORD') {
                return (
                  <InputParameterPassword
                    key={name}
                    name={name}
                    storedPassword={valuePresent}
                    handleParamChange={handleInputParamChange}
                  />
                );
              }
              return (
                <ValidatedTextField
                  key={name}
                  name={name}
                  label={name}
                  value={inputParameters[name] || ''}
                  onChange={(event) => handleInputParamChange(name, event.target.value)}
                  margin="normal"
                  variant="outlined"
                />
              );
            })}
          </DialogContent>
          <PaddedDialogActions>
            <DefaultButton
              color="secondary"
              onClick={handleCloseDialog}
              name="closeInputParameterDialog"
              disabled={processing}
            >
              <FormattedMessage
                id="openSubmission.inputUpload.parameters.dialog.cancelButton"
                defaultMessage="Cancel"
              />
            </DefaultButton>
            <Box display="flex" flexGrow={1} />
            <DefaultButton
              name="saveInputParameters"
              onClick={() => handleSaveParameters(inputParameters)}
              disabled={processing}
            >
              <FormattedMessage
                id="openSubmission.inputUpload.parameters.dialog.saveButton"
                defaultMessage="Save parameters"
              />
            </DefaultButton>
          </PaddedDialogActions>
        </Dialog>
        <Typography variant="body2" whiteSpace="nowrap" gutterBottom>
          <FormattedMessage
            id="openSubmission.inputUpload.parameters.providedTitle"
            defaultMessage="{count, plural, =0{No parameters}one{{count} parameter}other{{count} parameters}} provided"
            values={{
              count: dataStoreInputParameters.filter((param) => param.valuePresent).length,
            }}
          />
        </Typography>
        <ButtonRow>
          {!!dataStore?.parameters && (
            <IconButton name="editParameters" size="large" onClick={() => setDialogOpen(true)}>
              <EditIcon />
            </IconButton>
          )}
          <IconButton
            name="editParameters"
            size="large"
            onClick={() => setClearParameterConfirm(true)}
            disabled={!dataStoreInputParameters.find((param) => param.valuePresent)}
          >
            <DeleteIcon />
          </IconButton>
        </ButtonRow>
      </TableCell>
      <ConfirmDialog
        id="confirm-clear-parameters"
        isOpen={clearParameterConfirm}
        title={intl.formatMessage({
          id: 'openSubmission.inputUpload.parameters.confirmClear.title',
          defaultMessage: 'Clear stored input parameters?',
        })}
        text={intl.formatMessage({
          id: 'openSubmission.inputUpload.parameters.confirmClear.text',
          defaultMessage: 'Are you sure you wish to clear stored input parameters?',
        })}
        confirmBtnText={intl.formatMessage({
          id: 'openSubmission.inputUpload.parameters.confirmClear.confirmButton',
          defaultMessage: 'Clear parameters',
        })}
        confirmAction={handleResetStoredParameters}
        closeAction={() => setClearParameterConfirm(false)}
        disabled={disabled}
      />
    </>
  );
};

export default InputParametersTableCell;

import { FC, useCallback, useContext } from 'react';
import { Transition } from 'history';

import { useBlocker } from '../../hooks';

import { NavigationPromptContext } from './context';

interface RouterNavigationHandlerProps {
  blockNavigation: boolean;
}

const RouterNavigationHandler: FC<RouterNavigationHandlerProps> = ({ blockNavigation }) => {
  const { handleNavigationPrompt } = useContext(NavigationPromptContext);

  const blocker = useCallback(
    (transition: Transition) => handleNavigationPrompt(() => transition.retry()),
    [handleNavigationPrompt]
  );

  useBlocker(blocker, blockNavigation);

  return null;
};

export default RouterNavigationHandler;

import { FC, Fragment, useCallback, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { TableRow, TableCell } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import * as AssignmentsApi from '../../../../api/assignments';
import {
  AddFab,
  BrowseTable,
  FilterPagination,
  browseTableBody,
  FilterBar,
  DefaultButton,
  FilterToggle,
  MinWidthTableCell,
  NamedSupplierAvatar,
  FilterSearch,
  StyledTableHead,
} from '../../../../components';
import { AssignmentDetail, AssignmentMetadata } from '../../../../types';
import { updateActiveOnlyValue } from '../../../../util';
import { intl } from '../../../../Internationalization';
import { useBrowseRequest, useTitle } from '../../../../hooks';

import AddAssignmentDialog from './assignment/AddAssignmentDialog';
import { SpecificationContext } from './SpecificationContext';

export const toAssignmentUrl = (assignment: AssignmentMetadata) =>
  `/projects/${assignment.specification.project.key}/specifications/${assignment.specification.key}/assignments/${assignment.key}`;

const AssignmentsTableBody = browseTableBody<AssignmentDetail>();

const PAGE_SIZE = 10;

const Assignments: FC = () => {
  useTitle(intl.formatMessage({ id: 'title.assignments', defaultMessage: 'Assignments' }));
  const { projectKey, specificationKey } = useContext(SpecificationContext);
  const { request, response, processing, updateRequest, setPage, refresh } = useBrowseRequest({
    initialRequest: { page: 0, size: PAGE_SIZE, active: true, projectKey, specificationKey },
    onRequest: AssignmentsApi.getAssignments,
  });

  const [addAssignment, setAddAssignment] = useState<boolean>(false);

  const handleFilterUpdate = useCallback(
    (filter: string) => updateRequest({ filter }),
    [updateRequest]
  );
  const handleActiveOnlyUpdate = updateActiveOnlyValue(updateRequest);

  const assignmentRow = (assignment: AssignmentDetail) => {
    return (
      <TableRow key={assignment.key}>
        <TableCell align="left">{assignment.reference}</TableCell>
        <TableCell align="left">
          <NamedSupplierAvatar supplier={assignment.supplier} />
        </TableCell>
        <MinWidthTableCell>
          <DefaultButton
            color="grey"
            component={Link}
            to={toAssignmentUrl(assignment)}
            aria-label={intl.formatMessage({
              id: 'assignments.table.navigateToAssignment.ariaLabel',
              defaultMessage: 'Navigate to assignment',
            })}
          >
            <EditIcon />
          </DefaultButton>
        </MinWidthTableCell>
      </TableRow>
    );
  };

  return (
    <div id="project-specification-assignments">
      <FilterBar
        startInput={
          <FilterSearch
            placeholder={intl.formatMessage({
              id: 'assignments.filterSearch.placeholder',
              defaultMessage: 'Filter assignments…',
            })}
            onSearch={handleFilterUpdate}
          />
        }
        actions={
          <Fragment>
            <FilterPagination
              page={request.page}
              size={request.size}
              total={response?.total}
              disabled={processing}
              setPage={setPage}
            />
            <FilterToggle
              name="active"
              label={intl.formatMessage({
                id: 'assignments.filterActiveToggle.label',
                defaultMessage: 'Active Only',
              })}
              checked={!!request.active}
              onChange={handleActiveOnlyUpdate}
              disabled={processing}
            />
          </Fragment>
        }
      />
      <BrowseTable>
        <StyledTableHead>
          <TableRow>
            <TableCell align="left">
              <FormattedMessage id="assignments.table.referenceColumn" defaultMessage="Reference" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="assignments.table.supplierColumn" defaultMessage="Supplier" />
            </TableCell>
            <MinWidthTableCell>
              <FormattedMessage id="assignments.table.actionsColumn" defaultMessage="Actions" />
            </MinWidthTableCell>
          </TableRow>
        </StyledTableHead>
        <AssignmentsTableBody
          data={response && response.results}
          mapToRow={assignmentRow}
          noDataMessage={intl.formatMessage({
            id: 'assignments.noAssignments',
            defaultMessage: 'No matching assignments',
          })}
          numCols={4}
        />
      </BrowseTable>
      {addAssignment && (
        <AddAssignmentDialog
          onClose={() => setAddAssignment(false)}
          onRefreshAssignments={refresh}
        />
      )}
      <AddFab
        name="addAssignment"
        aria-label={intl.formatMessage({
          id: 'assignments.addFab.addAssignment.ariaLabel',
          defaultMessage: 'Add assignment',
        })}
        onClick={() => setAddAssignment(true)}
      />
    </div>
  );
};

export default Assignments;

import { useState, useEffect, FC } from 'react';

import { Link as RouterLink, useParams } from 'react-router-dom';
import { Typography, Link, Container, Box } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import StorageIcon from '@mui/icons-material/Storage';

import * as ApiTokensApi from '../../../api/apiTokens';
import { extractErrorMessage } from '../../../api/endpoints';
import { HeaderBreadcrumb, AppDecorator, PaperFormSkeleton } from '../../../components';
import { useErrorBlock } from '../../../contexts/error-block';
import { ApiTokenDetail, UserProfile } from '../../../types';
import { intl } from '../../../Internationalization';

import ApiTokenSettingsForm from './ApiTokenSettingsForm';

interface ApiTokenProps {
  user: UserProfile;
}

const ApiToken: FC<ApiTokenProps> = ({ user }) => {
  const { apiTokenName } = useParams<{ apiTokenName: string }>();
  const { raiseError } = useErrorBlock();

  const [apiToken, setApiToken] = useState<ApiTokenDetail>();

  useEffect(() => {
    const fetchApiToken = async () => {
      try {
        const response = await ApiTokensApi.getApiToken(user.key, apiTokenName!);
        setApiToken(response.data);
      } catch (error: any) {
        raiseError(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'apiTokens.apiToken.loadError',
              defaultMessage: 'Failed to update new API token',
            })
          )
        );
      }
    };

    fetchApiToken();
  }, [apiTokenName, raiseError, user.key]);

  const renderBreadcrumb = () => {
    return (
      <HeaderBreadcrumb>
        <Link component={RouterLink} to="./api_tokens">
          <Box display="flex" alignItems="center">
            <PersonIcon
              titleAccess={intl.formatMessage({
                id: 'apiTokens.routing.breadcrumb.user.titleAccess',
                defaultMessage: 'User',
              })}
            />
            {user.email}
          </Box>
        </Link>
        <Box display="flex" alignItems="center">
          <StorageIcon
            titleAccess={intl.formatMessage({
              id: 'apiTokens.routing.breadcrumb.apiToken.titleAccess',
              defaultMessage: 'API token',
            })}
          />
          <Typography color="inherit">{apiTokenName}</Typography>
        </Box>
      </HeaderBreadcrumb>
    );
  };

  return (
    <AppDecorator renderTabs={undefined} renderBreadcrumb={renderBreadcrumb}>
      <Container maxWidth="md" disableGutters>
        {apiToken ? (
          <ApiTokenSettingsForm user={user} apiToken={apiToken} />
        ) : (
          <PaperFormSkeleton contentRows={3} />
        )}
      </Container>
    </AppDecorator>
  );
};

export default ApiToken;

import { FC, useCallback, useContext } from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import HelpIcon from '@mui/icons-material/Help';

import { intl } from '../../../Internationalization';

import { ApplicationContext } from '../../../contexts/application';
import { createDocumentationUrl } from '../../../screens/system/help/Help';

import { DRAWER_WIDTH } from '../Layout';
import { AppDecorationContext } from '..';
import { OpenSubmissions, Notifications, AuthMenu } from './';

interface LayoutAppBarProps {
  shiftAppBar: boolean;
  handleDrawerToggle: () => void;
}

const LayoutAppBar: FC<LayoutAppBarProps> = ({ shiftAppBar, handleDrawerToggle }) => {
  const { applicationDetails } = useContext(ApplicationContext);
  const appDecorationContext = useContext(AppDecorationContext);

  const getHelpUrl = useCallback(() => {
    if (applicationDetails.site.helpUrl) {
      return applicationDetails.site.helpUrl;
    }
    return createDocumentationUrl(applicationDetails.version);
  }, [applicationDetails]);

  const helpButtonUrl = getHelpUrl();

  return (
    <>
      <AppBar
        id="app-menu"
        sx={[
          {
            boxShadow: 'none',
            transition: (theme) =>
              theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
          },
          shiftAppBar
            ? {
                marginLeft: DRAWER_WIDTH,
                width: `calc(100% - ${DRAWER_WIDTH}px)`,
                transition: (theme) =>
                  theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                  }),
              }
            : {},
        ]}
        position="fixed"
      >
        <Toolbar>
          <IconButton
            id="open-drawer"
            color="inherit"
            edge="start"
            aria-label={intl.formatMessage({
              id: 'components.appBar.layoutAppBar.openSidebar.ariaLabel',
              defaultMessage: 'Open sidebar',
            })}
            onClick={handleDrawerToggle}
            sx={{
              display: shiftAppBar ? 'none' : 'inline-flex',
              mr: 1,
            }}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            {appDecorationContext.title}
          </Typography>
          <Notifications />
          <OpenSubmissions />
          <IconButton
            id="open-help"
            component="a"
            href={helpButtonUrl}
            target="_blank"
            color="inherit"
            size="large"
            disabled={!helpButtonUrl}
            aria-label={intl.formatMessage({
              id: 'components.layoutAppBar.navigateToHelp.ariaLabel',
              defaultMessage: 'Navigate to help page',
            })}
          >
            <HelpIcon />
          </IconButton>
          <AuthMenu />
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};

export default LayoutAppBar;

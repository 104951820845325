import { FC, useState } from 'react';
import { ValidateFieldsError } from 'async-validator';
import { useSnackbar } from 'notistack';
import { add, formatISO, startOfDay } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import { FormHelperText, Typography, Container } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import SaveIcon from '@mui/icons-material/Save';

import * as ApiTokensApi from '../../../api/apiTokens';
import { extractErrorMessage } from '../../../api/endpoints';
import { DefaultButton, ValidatedTextField, FormButtons, PaddedPaper } from '../../../components';
import { ApiTokenDetail, ApiTokenSettings, UserProfile } from '../../../types';
import { intl } from '../../../Internationalization';
import { API_TOKEN_SETTINGS_VALIDATOR, validate } from '../../../validation';

interface ApiTokenSettingsFormProps {
  user: UserProfile;
  apiToken: ApiTokenDetail;
}

const ApiTokenSettingsForm: FC<ApiTokenSettingsFormProps> = ({ user, apiToken }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [processing, setProcessing] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();
  const [description, setDescription] = useState<string>(apiToken.description);
  const [expiresAt, setExpiresAt] = useState<Date | null>(new Date(apiToken.expiresAt));

  const validateAndSubmit = async () => {
    setProcessing(true);
    const apiTokenSettings = { description, expiresAt };
    try {
      const validatedResponse = await validate(API_TOKEN_SETTINGS_VALIDATOR, apiTokenSettings);
      saveApiToken({
        ...validatedResponse,
        expiresAt: formatISO(startOfDay(validatedResponse.expiresAt!)),
      });
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const saveApiToken = async (apiTokenSettings: ApiTokenSettings) => {
    setFieldErrors(undefined);

    try {
      await ApiTokensApi.updateApiToken(apiTokenSettings, user.key, apiToken!.name);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'apiToken.settings.saveSuccess',
          defaultMessage: 'API token updated',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'apiToken.settings.saveError',
            defaultMessage: 'Failed to update new API token',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Container maxWidth="md" disableGutters id="api-token-settings">
      <PaddedPaper>
        <Typography variant="h5">
          <FormattedMessage id="apiToken.settings.title" defaultMessage="API Token Settings" />
        </Typography>
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled
          name="name"
          label={intl.formatMessage({
            id: 'apiToken.settings.name.label',
            defaultMessage: 'Name',
          })}
          value={apiToken.name}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="description"
          label={intl.formatMessage({
            id: 'apiToken.settings.description.label',
            defaultMessage: 'Description',
          })}
          value={description}
          onChange={(event) => setDescription(event.target.value)}
          margin="normal"
          variant="outlined"
        />
        <DesktopDatePicker
          label={intl.formatMessage({
            id: 'apiToken.settings.expires.label',
            defaultMessage: 'Expires',
          })}
          value={expiresAt || null}
          onChange={setExpiresAt}
          minDate={add(new Date(), { days: 1 })}
          disablePast
          disabled={processing}
          slotProps={{
            textField: {
              variant: 'outlined',
              margin: 'normal',
              name: 'expiresAt',
              error: !!fieldErrors?.expiresAt,
              fullWidth: true,
            },
          }}
        />
        {fieldErrors && fieldErrors.expiresAt && (
          <FormHelperText>{fieldErrors?.expiresAt[0]?.message}</FormHelperText>
        )}
        <FormButtons>
          <DefaultButton
            name="saveApiTokenSettings"
            onClick={validateAndSubmit}
            disabled={processing}
            startIcon={<SaveIcon />}
          >
            <FormattedMessage id="apiToken.settings.saveButton" defaultMessage="Save settings" />
          </DefaultButton>
        </FormButtons>
      </PaddedPaper>
    </Container>
  );
};

export default ApiTokenSettingsForm;

import { FC, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';

import { Box, Container, Grid, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import * as AssignmentSavedMappingApi from '../../../../../api/assignmentSavedMapping';
import { extractErrorMessage } from '../../../../../api/endpoints';
import * as SpecificationApi from '../../../../../api/specification';
import { FullWidthButton, MessageBox, PaddedPaper } from '../../../../../components';
import { useErrorBlock } from '../../../../../contexts/error-block';
import {
  DataStoreClassMapping,
  DataStoreConfigDetail,
  DataStoreMapping,
  SubmissionSchema,
} from '../../../../../types';
import { intl } from '../../../../../Internationalization';

import {
  SchemaMapper,
  SchemaMappingContext,
  SchemaMappingValidationResults,
  validateSchemaMappings,
  SchemaMappingDirection,
  SchemaMapperSkeleton,
  mapDataStoreConfigs,
} from '../../../../components/schema-mapper';

import { SavedMappingContext } from './SavedMappingContext';
import { useNavigationPrompt } from '../../../../../contexts/navigation-prompt';

const SchemaMapping: FC = () => {
  const { assignment, savedMapping, updateSavedMapping, specification } =
    useContext(SavedMappingContext);
  const { enqueueSnackbar } = useSnackbar();
  const { raiseError } = useErrorBlock();
  const { raiseNavigationBlock, clearNavigationBlock } = useNavigationPrompt();

  const [saving, setSaving] = useState<boolean>(false);

  const [schema, setSchema] = useState<SubmissionSchema>();
  const [dataStoreConfigs, setDataStoreConfigs] = useState<Record<string, DataStoreConfigDetail>>();
  const [selectedDataStoreName, setSelectedDataStoreName] = useState<string>();
  const [direction, setDirection] = useState<SchemaMappingDirection>(
    SchemaMappingDirection.TARGET_TO_SOURCE
  );
  const [validationResults, setValidationResults] = useState<SchemaMappingValidationResults>();

  useEffect(() => {
    const loadSchema = async () => {
      try {
        const { data: savedMappingSchema } = await AssignmentSavedMappingApi.getSchema(
          assignment.key,
          savedMapping.key
        );
        const dataStoreConfigsData = (await SpecificationApi.getDataStoreConfigs(specification.key))
          .data;
        setSchema(savedMappingSchema);
        setDataStoreConfigs(mapDataStoreConfigs(dataStoreConfigsData));
        setSelectedDataStoreName(savedMappingSchema.source[0] && savedMappingSchema.source[0].path);
      } catch (error: any) {
        raiseError(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'myAssignment.savedMapping.schemaMapping.loadError',
              defaultMessage: 'Failed to load saved mapping schema',
            })
          )
        );
      }
    };
    loadSchema();
  }, [raiseError, assignment, savedMapping.key, specification.key]);

  useEffect(() => {
    if (dataStoreConfigs && schema) {
      setValidationResults(
        validateSchemaMappings(
          dataStoreConfigs,
          schema.source,
          schema.target,
          schema.mappings,
          specification
        )
      );
    }
  }, [dataStoreConfigs, schema, specification]);

  if (!schema || !dataStoreConfigs) {
    return <SchemaMapperSkeleton />;
  }

  const updateClassMappings = (dataStoreClasses: DataStoreClassMapping[]) => {
    const mappings = schema ? [...schema.mappings] : [];
    const index = mappings.findIndex(({ path }) => path === selectedDataStoreName);
    mappings[index].importMappings = dataStoreClasses;
    setSchema({ ...schema, mappings });
    raiseNavigationBlock();
  };

  const updateSchemaMappings = (mappings: DataStoreMapping[]) => {
    setSchema({ ...schema, mappings });
    raiseNavigationBlock();
  };

  const handleUpdateSavedMapping = async () => {
    setSaving(true);
    try {
      const response = await AssignmentSavedMappingApi.updateSavedMapping(
        assignment.key,
        savedMapping.key,
        {
          name: savedMapping.name,
          mappings: schema.mappings,
        }
      );
      updateSavedMapping(response.data);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'myAssignment.savedMapping.schemaMapping.saveSuccess',
          defaultMessage: 'Schema mapping updated',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'myAssignment.savedMapping.schemaMapping.saveError',
            defaultMessage: 'Failed to update schema mapping',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setSaving(false);
      clearNavigationBlock();
    }
  };

  const renderSchemaMapper = () => {
    if (!selectedDataStoreName) {
      return (
        <Box py={2}>
          <MessageBox
            message={intl.formatMessage({
              id: 'myAssignment.savedMapping.schemaMapping.noDataToMap',
              defaultMessage:
                'There is no sample data, upload sample data to enable the schema editor.',
            })}
            level="info"
          />
        </Box>
      );
    }
    if (selectedDataStoreName && validationResults) {
      return (
        <SchemaMappingContext.Provider
          value={{
            specification,
            dataStoreConfigs,
            selectedDataStoreName,
            handleSelectDataStoreName: setSelectedDataStoreName,
            direction,
            setDirection,

            sourceDataStores: schema.source,
            targetDataStores: schema.target,
            schemaMappings: schema.mappings,

            updateSchemaMappings,
            updateClassMappings,
            validationResults,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SchemaMapper />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <FullWidthButton
                name="save"
                label={intl.formatMessage({
                  id: 'myAssignment.savedMapping.schemaMapping.saveButton',
                  defaultMessage: 'Save',
                })}
                disabled={saving}
                processing={saving}
                color="primary"
                startIcon={<SaveIcon />}
                onClick={handleUpdateSavedMapping}
              />
            </Grid>
          </Grid>
        </SchemaMappingContext.Provider>
      );
    }
  };

  return (
    <Container id="saved-mapping-schema-mapping" maxWidth="xl" disableGutters>
      <PaddedPaper>
        <Typography variant="h5" gutterBottom>
          <FormattedMessage
            id="myAssignment.savedMapping.schemaMapping.title"
            defaultMessage="Schema Mapping"
          />
        </Typography>
        {renderSchemaMapper()}
      </PaddedPaper>
    </Container>
  );
};

export default SchemaMapping;

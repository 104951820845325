import { AxiosPromise } from 'axios';

import { LdapServerSettings } from '../types';
import { AXIOS } from './endpoints';

export function testServerConnection(
  ldapSettings: LdapServerSettings
): AxiosPromise<LdapServerSettings> {
  return AXIOS.post(`/ldap/server/test`, ldapSettings);
}

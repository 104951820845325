import { FC } from 'react';

import { ImageStyle, ProjectMetadata } from '../../types';
import * as ProjectMediaApi from '../../api/projectMedia';

import ImageAvatar, { ImageAvatarProps } from './ImageAvatar';

interface ProjectAvatarProps extends Omit<ImageAvatarProps, 'label' | 'color'> {
  project: ProjectMetadata;
}

const ProjectAvatar: FC<ProjectAvatarProps> = ({ sx = [], project, ...rest }) => (
  <ImageAvatar
    sx={sx}
    alt={project.name}
    src={ProjectMediaApi.projectImageOrDefault(project)}
    logo={project.imageStyle === ImageStyle.FIT}
    label={project.name}
    variant="rounded"
    {...rest}
  />
);

export default ProjectAvatar;

import { FC, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { ValidateFieldsError } from 'async-validator';

import SaveIcon from '@mui/icons-material/Save';
import { Typography, Container, Grid } from '@mui/material';

import * as SiteSettingsApi from '../../../api/siteSettings';
import { extractErrorMessage } from '../../../api/endpoints';
import {
  PaddedPaper,
  FormButtons,
  DefaultButton,
  MessageBox,
  ValidatedNumericField,
  ValidatedTextField,
} from '../../../components';
import { intl } from '../../../Internationalization';
import { editablePeriodToIso, isoPeriodToEditable } from '../../../util';
import { PasswordRequirements } from '../../../types';

import { SiteContext } from './SiteContext';
import {
  PASSWORD_REQUIREMENT_VALIDATOR,
  validate,
  PASSWORD_REQUIREMENTS_METADATA,
} from '../../../validation';

const extractPasswordReqSettings = (reqSettings: PasswordRequirements): PasswordRequirements => ({
  ...reqSettings,
  changeInterval: reqSettings.changeInterval && isoPeriodToEditable(reqSettings.changeInterval),
});

const Password: FC = () => {
  const { siteSettings, refreshApplicationContext, siteSettingsUpdated } = useContext(SiteContext);
  const { enqueueSnackbar } = useSnackbar();

  const [passwordRequirements, setPasswordRequirements] = useState<PasswordRequirements>(
    extractPasswordReqSettings(siteSettings.passwordRequirements)
  );

  const [processing, setProcessing] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const validateAndSave = async () => {
    setProcessing(true);
    setFieldErrors(undefined);
    const requirementsToValidate = { ...passwordRequirements };
    if (requirementsToValidate.changeInterval) {
      requirementsToValidate.changeInterval = editablePeriodToIso(
        requirementsToValidate.changeInterval
      );
    }
    try {
      updatePasswordRequirements(
        await validate(PASSWORD_REQUIREMENT_VALIDATOR, requirementsToValidate)
      );
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const updatePasswordRequirements = async (validatedRequirements: PasswordRequirements) => {
    try {
      const response = await SiteSettingsApi.updatePasswordRequirements(validatedRequirements);
      siteSettingsUpdated(response.data);
      setPasswordRequirements(extractPasswordReqSettings(response.data.passwordRequirements));
      refreshApplicationContext();
      enqueueSnackbar(
        intl.formatMessage({
          id: 'site.passwordRequirements.saveSuccess',
          defaultMessage: 'Password requirements have successfully been updated',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'site.passwordRequirements.saveError',
            defaultMessage: 'Failed to update password requirements',
          })
        ),
        { variant: 'error' }
      );
    }
    setProcessing(false);
  };

  const updatePasswordRequirement = (
    field: keyof PasswordRequirements,
    value?: number | string
  ) => {
    setPasswordRequirements((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  return (
    <Container maxWidth="md" id="system-password-requirements" disableGutters>
      <PaddedPaper>
        <Typography variant="h5" gutterBottom>
          <FormattedMessage
            id="site.passwordRequirements.title"
            defaultMessage="Password Requirements"
          />
        </Typography>
        <MessageBox
          level="info"
          gutterBottom
          message={intl.formatMessage({
            id: 'site.passwordRequirements.info',
            defaultMessage: 'Password requirements will be enforced for user account passwords.',
          })}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ValidatedNumericField
              name="minLength"
              required
              inputProps={{
                min: 0,
              }}
              label={PASSWORD_REQUIREMENTS_METADATA['minLength'].label}
              value={passwordRequirements.minLength}
              onChange={(fieldName, value) =>
                updatePasswordRequirement(fieldName as keyof PasswordRequirements, value)
              }
              fieldErrors={fieldErrors}
              disabled={processing}
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <ValidatedNumericField
              required
              name="uppercase"
              inputProps={{
                min: 0,
              }}
              label={PASSWORD_REQUIREMENTS_METADATA['uppercase'].label}
              value={passwordRequirements.uppercase}
              onChange={(fieldName, value) =>
                updatePasswordRequirement(fieldName as keyof PasswordRequirements, value)
              }
              fieldErrors={fieldErrors}
              disabled={processing}
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <ValidatedNumericField
              name="lowercase"
              required
              inputProps={{
                min: 0,
              }}
              label={PASSWORD_REQUIREMENTS_METADATA['lowercase'].label}
              value={passwordRequirements.lowercase}
              onChange={(fieldName, value) =>
                updatePasswordRequirement(fieldName as keyof PasswordRequirements, value)
              }
              fieldErrors={fieldErrors}
              disabled={processing}
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <ValidatedNumericField
              name="numbers"
              inputProps={{
                min: 0,
              }}
              required
              label={PASSWORD_REQUIREMENTS_METADATA['numbers'].label}
              value={passwordRequirements.numbers}
              onChange={(fieldName, value) =>
                updatePasswordRequirement(fieldName as keyof PasswordRequirements, value)
              }
              fieldErrors={fieldErrors}
              disabled={processing}
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <ValidatedNumericField
              name="specialCharacters"
              required
              inputProps={{
                min: 0,
              }}
              label={PASSWORD_REQUIREMENTS_METADATA['specialCharacters'].label}
              value={passwordRequirements.specialCharacters}
              onChange={(fieldName, value) =>
                updatePasswordRequirement(fieldName as keyof PasswordRequirements, value)
              }
              fieldErrors={fieldErrors}
              disabled={processing}
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ValidatedNumericField
              name="reuseGenerations"
              inputProps={{
                min: 0,
              }}
              label={PASSWORD_REQUIREMENTS_METADATA['reuseGenerations'].label}
              value={passwordRequirements.reuseGenerations || ''}
              onChange={(fieldName, value) =>
                updatePasswordRequirement(fieldName as keyof PasswordRequirements, value)
              }
              fieldErrors={fieldErrors}
              disabled={processing}
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ValidatedTextField
              name="changeInterval"
              label={
                PASSWORD_REQUIREMENTS_METADATA['changeInterval'].label +
                ' ' +
                intl.formatMessage({
                  id: 'site.passwordRequirements.changeInterval.example',
                  defaultMessage: '(e.g. 1y 2m 10d)',
                })
              }
              value={passwordRequirements.changeInterval || ''}
              onChange={(event) =>
                updatePasswordRequirement(
                  event.target.name as keyof PasswordRequirements,
                  event.target.value
                )
              }
              fieldErrors={fieldErrors}
              disabled={processing}
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <ValidatedNumericField
              name="authFailureLockoutThreshold"
              inputProps={{
                min: 0,
              }}
              label={PASSWORD_REQUIREMENTS_METADATA['authFailureLockoutThreshold'].label}
              value={passwordRequirements.authFailureLockoutThreshold || ''}
              onChange={(fieldName, value) =>
                updatePasswordRequirement(fieldName as keyof PasswordRequirements, value)
              }
              fieldErrors={fieldErrors}
              disabled={processing}
              variant="outlined"
              margin="dense"
            />
          </Grid>
        </Grid>
        <FormButtons>
          <DefaultButton
            name="updatePasswordRequirements"
            onClick={validateAndSave}
            disabled={processing}
            startIcon={<SaveIcon />}
          >
            <FormattedMessage
              id="site.passwordRequirements.updateButton"
              defaultMessage="Update Password Requirements"
            />
          </DefaultButton>
        </FormButtons>
      </PaddedPaper>
    </Container>
  );
};

export default Password;

import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';
import { ValidateFieldsError } from 'async-validator';

import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import * as NamedLocationsApi from '../../../../api/namedLocations';
import * as NamedLocationApi from '../../../../api/namedLocation';
import { extractErrorMessage } from '../../../../api/endpoints';

import { ValidatedTextField, PaddedDialogActions, DefaultButton } from '../../../../components';

import { updateValue } from '../../../../util';
import { intl } from '../../../../Internationalization';
import { NamedLocationRequest, NamedLocationDetail } from '../../../../types';
import { namedLocationValidator, validate } from '../../../../validation';

const INITIAL_NAMED_LOCATION = {
  name: '',
  rootDirectory: '',
};

interface NamedLocationDialogProps {
  open: boolean;
  location?: NamedLocationDetail;
  onClose: () => void;
  onUpdated: (namedLocation: NamedLocationDetail) => void;
}

const NamedLocationDialog: FC<NamedLocationDialogProps> = ({
  open,
  location,
  onUpdated,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [processing, setProcessing] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const [namedLocation, setNamedLocation] = useState<NamedLocationRequest>(
    location ? location : INITIAL_NAMED_LOCATION
  );

  useEffect(() => {
    setNamedLocation({ ...(location ? location : INITIAL_NAMED_LOCATION) });
    setFieldErrors(undefined);
  }, [open, location]);

  const validateAndSubmit = async () => {
    setProcessing(true);
    try {
      const validatedNamedLocation = await validate(
        namedLocationValidator(location ? () => location.name : undefined),
        namedLocation
      );
      location
        ? updateNamedLocation(validatedNamedLocation)
        : createNamedLocation(validatedNamedLocation);
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const createNamedLocation = async (repo: NamedLocationRequest) => {
    try {
      const response = await NamedLocationsApi.createNamedLocation(repo);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'systemActivity.namedLocations.dialog.createSuccess',
          defaultMessage: 'Named location created',
        }),
        { variant: 'success' }
      );
      onUpdated(response.data);
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'systemActivity.namedLocations.dialog.createError',
            defaultMessage: 'Failed to create named location',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessing(false);
    }
  };

  const updateNamedLocation = async (repo: NamedLocationRequest) => {
    if (!location) {
      return;
    }
    try {
      const response = await NamedLocationApi.updateNamedLocation(repo, location.key);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'systemActivity.namedLocations.dialog.updateSuccess',
          defaultMessage: 'Named location updated',
        }),
        { variant: 'success' }
      );
      onUpdated(response.data);
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'systemActivity.namedLocations.dialog.updateError',
            defaultMessage: 'Failed to update named location',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessing(false);
    }
  };

  const handleUpdate = updateValue(setNamedLocation);

  return (
    <Dialog
      id="named-location-dialog"
      onClose={onClose}
      aria-labelledby="named-location-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle id="named-location-dialog-title">
        <FormattedMessage
          id="systemActivity.namedLocations.dialog.title"
          defaultMessage="Named Locations"
        />
      </DialogTitle>
      <DialogContent dividers={true}>
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="name"
          label={intl.formatMessage({
            id: 'systemActivity.namedLocations.dialog.name.label',
            defaultMessage: 'Name',
          })}
          value={namedLocation.name}
          onChange={handleUpdate}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="rootDirectory"
          label={intl.formatMessage({
            id: 'systemActivity.namedLocations.dialog.rootDirectory.label',
            defaultMessage: 'Root Directory',
          })}
          value={namedLocation.rootDirectory}
          onChange={handleUpdate}
          margin="normal"
          variant="outlined"
        />
      </DialogContent>
      <PaddedDialogActions>
        <DefaultButton
          name="closeNamedLocationDialog"
          color="secondary"
          onClick={onClose}
          disabled={processing}
        >
          <FormattedMessage
            id="systemActivity.namedLocations.dialog.cancelButton"
            defaultMessage="Cancel"
          />
        </DefaultButton>
        <DefaultButton
          name="saveNamedLocation"
          onClick={validateAndSubmit}
          disabled={processing}
          startIcon={<AddIcon />}
        >
          <FormattedMessage
            id="systemActivity.namedLocations.dialog.addButton"
            defaultMessage="Save Named Location"
          />
        </DefaultButton>
      </PaddedDialogActions>
    </Dialog>
  );
};

export default NamedLocationDialog;

import { FC, useContext } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import { Box } from '@mui/material';

import { AppDecorator, AppTabs, RouterTab, RouterTabs } from '../../../components';
import { intl } from '../../../Internationalization';
import { useRouterTab, useTitle } from '../../../hooks';

import SupplierHeader from './SupplierHeader';
import Members from './Members';
import SupplierAvatarSelect from './SupplierAvatarSelect';
import SupplierSettings from './SupplierSettings';
import SupplierAssignments from './SupplierAssignments';
import { SupplierContext } from './SupplierContext';

const SupplierRouting: FC = () => {
  const {
    supplier: { name },
  } = useContext(SupplierContext);
  useTitle(
    intl.formatMessage(
      { id: 'title.supplier', defaultMessage: 'Supplier ({supplierName})' },
      { supplierName: name }
    )
  );
  const { routerTab, resolveTabRoute } = useRouterTab();

  const renderTabs = () => (
    <RouterTabs id="supplier-tabs" value={routerTab}>
      <RouterTab
        id="members-tab"
        value="members"
        label={intl.formatMessage({
          id: 'supplier.routing.tabs.members',
          defaultMessage: 'Members',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="assignments-tab"
        value="assignments"
        label={intl.formatMessage({
          id: 'supplier.routing.tabs.assignments',
          defaultMessage: 'Assignments',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="settings-tab"
        value="settings"
        label={intl.formatMessage({
          id: 'supplier.routing.tabs.settings',
          defaultMessage: 'Settings',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="avatar-tab"
        value="avatar"
        label={intl.formatMessage({
          id: 'supplier.routing.tabs.avatar',
          defaultMessage: 'Avatar',
        })}
        resolveTabRoute={resolveTabRoute}
      />
    </RouterTabs>
  );

  return (
    <AppDecorator renderTabs={renderTabs}>
      <SupplierHeader />
      <AppTabs />
      <Box p={3}>
        <Routes>
          <Route path="members" element={<Members />} />
          <Route path="assignments" element={<SupplierAssignments />} />
          <Route path="settings" element={<SupplierSettings />} />
          <Route path="avatar" element={<SupplierAvatarSelect />} />
          <Route path="*" element={<Navigate to="members" replace />} />
        </Routes>
      </Box>
    </AppDecorator>
  );
};

export default SupplierRouting;

import Schema, { Values } from 'async-validator';
import { intl } from '../Internationalization';
import {
  identityProviderLinkByRemoteId,
  identityProviderLinkByUserKey,
} from '../api/identityProviderLinkLookup';

export const IDENTITY_PROVIDER_LINK_SETTINGS_VALIDATOR = new Schema({
  remoteId: [
    {
      required: true,
      message: intl.formatMessage({
        id: 'identityProviderLink.validator.remoteId.required',
        defaultMessage: 'Please provide a remote ID',
      }),
    },
    {
      validator: (rule: any, value: any, callback: (error?: string) => void, source: Values) => {
        identityProviderLinkByRemoteId(source.identityProviderKey, source.remoteId)
          .then((unique) => {
            if (unique) {
              callback();
            } else {
              callback(
                intl.formatMessage({
                  id: 'identityProviderLink.validator.remoteId.unique',
                  defaultMessage:
                    'This remote ID is already in use for the selected identity provider',
                })
              );
            }
          })
          .catch(() =>
            callback(
              intl.formatMessage({
                id: 'identityProviderLink.validator.remoteId.checkUniqueError',
                defaultMessage: 'There was a problem verifying the remote ID',
              })
            )
          );
      },
    },
  ],
  identityProviderKey: [
    {
      required: true,
      message: intl.formatMessage({
        id: 'identityProviderLink.validator.identityProviderKey.required',
        defaultMessage: 'Please select an identity provider',
      }),
    },
    {
      validator: (rule: any, value: any, callback: (error?: string) => void, source: Values) => {
        identityProviderLinkByUserKey(source.identityProviderKey, source.userKey)
          .then((unique) => {
            if (unique) {
              callback();
            } else {
              callback(
                intl.formatMessage({
                  id: 'identityProviderLink.validator.identityProviderKey.unique',
                  defaultMessage: 'This user already has a link for this identity provider',
                })
              );
            }
          })
          .catch(() =>
            callback(
              intl.formatMessage({
                id: 'identityProviderLink.validator.identityProviderKey.checkUniqueError',
                defaultMessage: 'There was a problem verifying the identity provider',
              })
            )
          );
      },
    },
  ],
});

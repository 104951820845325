import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

import { Box, FormControlLabel, Grid, MenuItem, Switch, Typography } from '@mui/material';

import * as SubmissionApi from '../../../../../../api/submission';
import { extractErrorMessage } from '../../../../../../api/endpoints';
import { InputTooltip, PaddedPaper, ValidatedTextField } from '../../../../../../components';
import {
  SubmissionAutomationMode,
  SUBMISSION_AUTOMATION_MODES,
  SUBMISSION_AUTOMATION_MODE_METADATA,
} from '../../../../../../types';
import { intl } from '../../../../../../Internationalization';

import { OpenSubmissionContext } from '../OpenSubmissionContext';

const SubmissionAutomation: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { submissionUpdated, submission } = useContext(OpenSubmissionContext);
  const [automationMode, setAutomationMode] = useState<SubmissionAutomationMode>(
    submission.automationMode
  );
  const [sendNotifications, setSendNotifications] = useState<boolean>(submission.sendNotifications);
  const [processing, setProcessing] = useState<boolean>(false);

  const firstRender = useRef<boolean>(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    const patchSubmission = async () => {
      setProcessing(true);
      try {
        const submissionData = (
          await SubmissionApi.patchSubmission(submission.reference, {
            automationMode,
            sendNotifications,
          })
        ).data;
        submissionUpdated(submissionData);
      } catch (error: any) {
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'submission.automation.patchError',
              defaultMessage: 'Failed to update submission',
            })
          ),
          { variant: 'error' }
        );
      } finally {
        setProcessing(false);
      }
    };

    patchSubmission();
  }, [enqueueSnackbar, automationMode, sendNotifications, submission.reference, submissionUpdated]);

  return (
    <PaddedPaper fillVertical>
      <Typography variant="h5" gutterBottom>
        <FormattedMessage
          id="openSubmission.processSubmission.automation.title"
          defaultMessage="Automation"
        />
      </Typography>
      <Grid container spacing={3}>
        <Grid item xl={6} xs={12}>
          <ValidatedTextField
            label={intl.formatMessage({
              id: 'submission.automation.automationMode.label',
              defaultMessage: 'Automation Mode',
            })}
            tooltip={intl.formatMessage({
              id: 'submission.automation.automationMode.tooltip',
              defaultMessage:
                'The automation mode controls when the processing of the submission will continue without your attention.',
            })}
            name="automationMode"
            select
            fullWidth
            value={automationMode}
            variant="outlined"
            margin="normal"
            disabled={processing}
            onChange={(event) => setAutomationMode(event.target.value as SubmissionAutomationMode)}
          >
            {SUBMISSION_AUTOMATION_MODES.map((mode) => (
              <MenuItem key={mode} value={mode}>
                {SUBMISSION_AUTOMATION_MODE_METADATA[mode].label}
              </MenuItem>
            ))}
          </ValidatedTextField>
        </Grid>
        <Grid item xl={6} xs={12}>
          <Box display="flex" height="100%" alignContent="center">
            <InputTooltip
              data-tooltip-for="sendNotifications"
              title={intl.formatMessage({
                id: 'submission.automation.sendNotifications.tooltip',
                defaultMessage:
                  'When enabled, the system will send you notifications when the submission requires your attention.',
              })}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={sendNotifications}
                    onChange={(event) => setSendNotifications(event.target.checked)}
                    name="sendNotifications"
                    disabled={processing}
                  />
                }
                label={intl.formatMessage({
                  id: 'submission.automation.sendNotifications.label',
                  defaultMessage: 'Notify me when action required',
                })}
              />
            </InputTooltip>
          </Box>
        </Grid>
      </Grid>
    </PaddedPaper>
  );
};

export default SubmissionAutomation;

import { FC, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import * as DeletionJobApi from '../../../api/deletionJob';
import { extractErrorMessage } from '../../../api/endpoints';
import {
  ActiveAvatar,
  DefaultButton,
  SecureConfirmDialog,
  ListItemActionArea,
  PaddedPaper,
} from '../../../components';
import { DeletionJobEntity } from '../../../types';
import { intl } from '../../../Internationalization';
import { toDeletionJobUrl } from '../../system/activity/DeletionJobs';

import { SupplierContext } from './SupplierContext';

const SupplierManagement: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { supplier } = useContext(SupplierContext);
  const navigate = useNavigate();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const handleDelete = async () => {
    setProcessing(true);
    try {
      const { data: deletionJob } = await DeletionJobApi.createDeletionJob({
        entity: DeletionJobEntity.SUPPLIER,
        entityKey: supplier.key,
      });
      enqueueSnackbar(
        intl.formatMessage({
          id: 'supplier.management.deletionStarted',
          defaultMessage: 'Deletion task started',
        }),
        { variant: 'success' }
      );
      navigate(toDeletionJobUrl(deletionJob));
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'supplier.management.deleteError',
            defaultMessage: 'Failed to start supplier deletion task',
          })
        ),
        { variant: 'error' }
      );
      if (error.response.status === 400) {
        setDeleteDialogOpen(false);
      }
      setProcessing(false);
    }
  };

  return (
    <PaddedPaper>
      <Typography variant="h5" component="h3" gutterBottom>
        <FormattedMessage id="supplier.management.title" defaultMessage="Manage Supplier" />
      </Typography>
      <List>
        <ListItem>
          <ListItemAvatar>
            <ActiveAvatar activated="inactive">
              <DeleteForeverIcon />
            </ActiveAvatar>
          </ListItemAvatar>
          <ListItemText
            primary={intl.formatMessage({
              id: 'supplier.management.deleteSupplier',
              defaultMessage: 'Delete Supplier',
            })}
            secondary={intl.formatMessage({
              id: 'supplier.management.deleteSupplier.description',
              defaultMessage:
                'Permanently remove the supplier and all of its associated memberships, assignments and submissions.',
            })}
          />
          <ListItemActionArea>
            <DefaultButton
              name="deleteSupplierButton"
              color="grey"
              onClick={() => setDeleteDialogOpen(true)}
            >
              <FormattedMessage
                id="supplier.management.deleteSupplierButton"
                defaultMessage="Delete Supplier"
              />
            </DefaultButton>
          </ListItemActionArea>
        </ListItem>
      </List>
      <SecureConfirmDialog
        id="confirm-delete-supplier"
        disabled={processing}
        confirmCode={supplier.key}
        confirmCodeLabel={intl.formatMessage({
          id: 'supplier.management.confirmDeleteSupplier.confirmCodeLabel',
          defaultMessage: 'Supplier Key',
        })}
        isOpen={deleteDialogOpen}
        title={intl.formatMessage({
          id: 'supplier.management.confirmDeleteSupplier.title',
          defaultMessage: 'Delete Supplier?',
        })}
        confirmBtnText={intl.formatMessage({
          id: 'supplier.management.confirmDeleteSupplier.button',
          defaultMessage: 'Delete Supplier',
        })}
        confirmAction={handleDelete}
        closeAction={() => setDeleteDialogOpen(false)}
      >
        <Typography variant="body1" component="p" gutterBottom>
          <FormattedMessage
            id="supplier.management.confirmDeleteSupplier.warningMessage"
            defaultMessage="Are you absolutely sure you want to delete this supplier? This will delete the supplier and all of its associated memberships, assignments and submissions permanently."
          />
        </Typography>
        <Box my={2}>
          <Typography>
            <strong>
              <FormattedMessage
                id="supplier.management.confirmDeleteSupplier.supplierKeyLabel"
                defaultMessage="Supplier Key:"
              />
            </strong>
            {' ' + supplier.key}
          </Typography>
          <Typography>
            <strong>
              <FormattedMessage
                id="supplier.management.confirmDeleteSupplier.supplierNameLabel"
                defaultMessage="Supplier Name:"
              />
            </strong>
            {' ' + supplier.name}
          </Typography>
        </Box>
        <Typography variant="body1" component="p">
          <FormattedMessage
            id="supplier.management.confirmDeleteSupplier.confirmMessage"
            defaultMessage="Type the supplier key into the box below to confirm this action."
          />
        </Typography>
      </SecureConfirmDialog>
    </PaddedPaper>
  );
};

export default SupplierManagement;

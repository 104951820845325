import { AxiosPromise } from 'axios';

import {
  SupplierDetail,
  SupplierSettings,
  MembersRequest,
  UsersResponse,
  UpdateAvatarRequest,
  SupplierMembershipRequest,
  SupplierMembershipDetail,
  VirusCheckedMediaDetail,
  AvatarDetail,
} from '../types';
import { AXIOS, entityDoesNotExist, FileUploadConfig, RequestConfig } from './endpoints';
import { uploadMediaAndVirusCheck } from './media';

export function getSupplier(supplierKey: string): AxiosPromise<SupplierDetail> {
  return AXIOS.get(`/suppliers/${supplierKey}`);
}

export function supplierUniqueByKey(supplierKey: string): Promise<boolean> {
  return entityDoesNotExist(AXIOS.head(`/suppliers/${supplierKey}`));
}

export function updateSupplier(
  supplierKey: string,
  supplier: SupplierSettings
): AxiosPromise<SupplierDetail> {
  return AXIOS.post(`/suppliers/${supplierKey}`, {
    name: supplier.name,
    active: supplier.active,
  });
}

export function updateAvatar(
  supplierKey: string,
  request: UpdateAvatarRequest
): AxiosPromise<AvatarDetail> {
  return AXIOS.post(`/suppliers/${supplierKey}/avatar`, request);
}

export const uploadImageAndVirusCheck = (
  supplierKey: string,
  image: File,
  config?: FileUploadConfig
): AxiosPromise<VirusCheckedMediaDetail> =>
  uploadMediaAndVirusCheck(`/suppliers/${supplierKey}/avatar/image`, image, config);

export function deleteImage(supplierKey: string): AxiosPromise<void> {
  return AXIOS.delete(`/suppliers/${supplierKey}/avatar/image`);
}

export function getMembers(
  supplierKey: string,
  request: MembersRequest,
  config?: RequestConfig
): AxiosPromise<UsersResponse> {
  return AXIOS.get(`/suppliers/${supplierKey}/members`, {
    ...config,
    params: request,
  });
}

export function fetchSupplierMembership(
  supplierKey: string,
  userKey: string
): AxiosPromise<SupplierMembershipDetail> {
  return AXIOS.get(`/suppliers/${supplierKey}/membership/${userKey}`);
}

export function grantSupplierMembership(
  supplierKey: string,
  userKey: string,
  request: SupplierMembershipRequest
): AxiosPromise<SupplierMembershipDetail> {
  return AXIOS.post(`/suppliers/${supplierKey}/membership/${userKey}`, request);
}

export function revokeSupplierMembership(
  supplierKey: string,
  userKey: string
): AxiosPromise<UsersResponse> {
  return AXIOS.delete(`/suppliers/${supplierKey}/membership/${userKey}`);
}

export function extractSupplierSettings(supplierDetail: SupplierDetail): SupplierSettings {
  return (({ name, active }) => ({ name, active }))(supplierDetail);
}

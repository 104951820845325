import Schema, { InternalRuleItem, RuleItem } from 'async-validator';
import { endOfDay, isBefore, isEqual, isValid } from 'date-fns';

import {
  duplicateValidator,
  urlSafeValidator,
  NOT_BLANK_REGEX,
  notTrimmableValidator,
} from './shared';
import { intl } from '../Internationalization';

import { entityDoesNotExist } from '../api/endpoints';
import { getApiToken } from '../api/apiTokens';

export function lookupByName(userKey: string, name: string): Promise<boolean> {
  return entityDoesNotExist(getApiToken(userKey, name));
}

const descriptionValidator: RuleItem = {
  required: true,
  message: intl.formatMessage({
    id: 'apiToken.validator.description.required',
    defaultMessage: 'Please provide a description',
  }),
};
const expiresAtValidator: RuleItem[] = [
  {
    required: true,
    message: intl.formatMessage({
      id: 'apiToken.validator.expiresAt.required',
      defaultMessage: 'Please select an expiration date',
    }),
  },
  {
    validator: (rule: InternalRuleItem, value: Date, callback: (error?: string) => void) => {
      if (value) {
        const dayEnd = endOfDay(new Date());
        if (!isValid(value)) {
          callback(
            intl.formatMessage({
              id: 'apiToken.validator.expiresAt.isDate',
              defaultMessage: 'Must be a valid date',
            })
          );
        } else if (isBefore(value, dayEnd) || isEqual(value, dayEnd)) {
          callback(
            intl.formatMessage({
              id: 'apiToken.validator.expiresAt.futureDate',
              defaultMessage: 'Date must be in the future',
            })
          );
        }
      }
      callback();
    },
  },
];

export const API_TOKEN_SETTINGS_VALIDATOR = new Schema({
  description: descriptionValidator,
  expiresAt: expiresAtValidator,
});

export function newApiTokenSettingsValidator(getUserKey: () => string) {
  return new Schema({
    name: [
      {
        required: true,
        message: intl.formatMessage({
          id: 'apiToken.validator.name.required',
          defaultMessage: 'Please provide a unique name',
        }),
      },
      notTrimmableValidator,
      urlSafeValidator,
      duplicateValidator({
        regex: NOT_BLANK_REGEX,
        checkUnique: (name) => lookupByName(getUserKey(), name),
        alreadyExistsMessage: intl.formatMessage({
          id: 'apiToken.validator.name.unique',
          defaultMessage: 'This name is already in use by another API token',
        }),
        errorMessage: intl.formatMessage({
          id: 'apiToken.validator.name.checkUniqueError',
          defaultMessage: 'There was a problem verifying the token name',
        }),
      }),
    ],
    description: descriptionValidator,
    expiresAt: expiresAtValidator,
  });
}

import { FC, PropsWithChildren } from 'react';
import { Page, Document, Image, Font, Text, StyleSheet, View } from '@react-pdf/renderer';
import { FormattedMessage } from 'react-intl';

import dgLogo from '../../assets/images/1datagateway_1300.jpg';
import RobotoLight from '../../assets/fonts/roboto/Roboto-Light.ttf';
import RobotoLightItalic from '../../assets/fonts/roboto/Roboto-LightItalic.ttf';
import RobotoRegular from '../../assets/fonts/roboto/Roboto-Regular.ttf';
import RobotoMedium from '../../assets/fonts/roboto/Roboto-Medium.ttf';
import RobotoMediumItalic from '../../assets/fonts/roboto/Roboto-MediumItalic.ttf';
import Internationalization from '../../Internationalization';
import { dateTimeFormat } from '../../util';
import { downloadSiteResourceUri } from '../../api/siteMedia';
import { MediaDetail } from '../../types';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: RobotoLight, fontWeight: 300 },
    { src: RobotoLightItalic, fontWeight: 300, fontStyle: 'italic' },
    { src: RobotoRegular, fontWeight: 400 },
    { src: RobotoMedium, fontWeight: 500 },
    { src: RobotoMediumItalic, fontWeight: 500, fontStyle: 'italic' },
  ],
});

const pdfStyles = StyleSheet.create({
  page: {
    paddingTop: 50,
    paddingBottom: 65,
    paddingHorizontal: 20,
    fontFamily: 'Roboto',
    fontWeight: 300,
  },
  header: {
    fontSize: 10,
    position: 'absolute',
    display: 'flex',
    textAlign: 'center',
    color: 'grey',
    top: 10,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  footer: {
    fontSize: 10,
    position: 'absolute',
    bottom: 5,
    left: 35,
    height: 20,
    color: 'grey',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    marginBottom: 15,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  dgLogo: {
    position: 'absolute',
    top: 10,
    right: 20,
    height: 20,
    opacity: 0.5,
  },
  companyLogoContainer: {
    position: 'absolute',
    width: '35%',
    height: 30,
    left: 20,
    top: 10,
  },
  companyLogo: {
    objectFit: 'contain',
    objectPosition: 'left',
    opacity: 0.5,
  },
});

interface PdfDocumentLayoutProps extends PropsWithChildren {
  title?: string;
  documentTitle: string;
  logo?: MediaDetail;
}

const PdfDocumentLayout: FC<PdfDocumentLayoutProps> = ({
  title,
  documentTitle,
  logo,
  children,
}) => (
  <Internationalization>
    <Document title={documentTitle}>
      <Page style={pdfStyles.page}>
        {logo && (
          <View style={pdfStyles.companyLogoContainer} fixed>
            <Image
              fixed
              style={pdfStyles.companyLogo}
              src={downloadSiteResourceUri(logo.storedFilename)}
            />
          </View>
        )}
        <Image fixed style={pdfStyles.dgLogo} src={dgLogo} />
        <Text style={pdfStyles.header} fixed>
          <FormattedMessage
            id="components.pdfDocumentLayout.confidentialInformation"
            defaultMessage="Confidential Information"
          />
        </Text>
        {title && <Text style={pdfStyles.title}>{title}</Text>}
        {children}
        <Text style={pdfStyles.footer} fixed>
          {dateTimeFormat(new Date().toString())}
        </Text>
        <Text
          style={pdfStyles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  </Internationalization>
);

export default PdfDocumentLayout;

import { FC, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Container, TableCell, TableRow } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

import {
  AddFab,
  BrowseTable,
  ButtonRow,
  DefaultButton,
  FilterBar,
  FilterPagination,
  FilterSearch,
  MinWidthTableCell,
  StyledTableHead,
  browseTableBody,
} from '../../../../components';
import * as NamedLocationsApi from '../../../../api/namedLocations';
import { NamedLocationDetail } from '../../../../types/namedLocation';
import { useBrowseRequest } from '../../../../hooks';
import { intl } from '../../../../Internationalization';

import NamedLocationDialog from './NamedLocationDialog';
import DeleteNamedLocationDialog from './DeleteNamedLocationDialog';

const NamedLocationsTableBody = browseTableBody<NamedLocationDetail>();

const PAGE_SIZE = 15;

const NamedLocations: FC = () => {
  const { request, response, processing, updateRequest, setPage, refresh } = useBrowseRequest({
    initialRequest: { page: 0, size: PAGE_SIZE, filter: '' },
    onRequest: NamedLocationsApi.getNamedLocations,
  });

  const [createNamedLocation, setCreateNamedLocation] = useState<boolean>(false);
  const [editNamedLocation, setEditNamedLocation] = useState<NamedLocationDetail>();
  const [confirmDeleteNamedLocation, setConfirmDeleteNamedLocation] = useState<string>();

  const handleCloseNamedLocationDialog = () => {
    setCreateNamedLocation(false);
    setEditNamedLocation(undefined);
  };

  const handleNamedLocationUpdated = () => {
    refresh();
    handleCloseNamedLocationDialog();
  };

  const handleFilterUpdate = useCallback(
    (filter: string) => updateRequest({ filter }),
    [updateRequest]
  );

  const namedLocationRow = (namedLocation: NamedLocationDetail) => (
    <TableRow key={namedLocation.key}>
      <TableCell>{namedLocation.name}</TableCell>
      <TableCell>{namedLocation.rootDirectory}</TableCell>
      <MinWidthTableCell>
        <ButtonRow whiteSpace="nowrap">
          <DefaultButton
            name="editNamedLocation"
            color="grey"
            onClick={() => setEditNamedLocation(namedLocation)}
            aria-label={intl.formatMessage({
              id: 'systemActivity.namedLocations.edit.ariaLabel',
              defaultMessage: 'Edit named location',
            })}
          >
            <EditIcon />
          </DefaultButton>
          <DefaultButton
            name="deleteNamedLocation"
            color="grey"
            onClick={() => setConfirmDeleteNamedLocation(namedLocation.key)}
            aria-label={intl.formatMessage({
              id: 'systemActivity.namedLocations.delete.ariaLabel',
              defaultMessage: 'Delete named location',
            })}
          >
            <CloseIcon />
          </DefaultButton>
        </ButtonRow>
      </MinWidthTableCell>
    </TableRow>
  );

  return (
    <Container maxWidth="md" id="system-site-named-locations" disableGutters>
      <FilterBar
        startInput={
          <FilterSearch
            placeholder={intl.formatMessage({
              id: 'systemActivity.namedLocations.filterSearch.placeholder',
              defaultMessage: 'Filter named locations…',
            })}
            onSearch={handleFilterUpdate}
          />
        }
        actions={
          <FilterPagination
            page={request.page}
            size={request.size}
            total={response?.total}
            disabled={processing}
            setPage={setPage}
          />
        }
      />
      <BrowseTable>
        <StyledTableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage
                id="systemActivity.namedLocations.table.nameColumn"
                defaultMessage="Name"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="systemActivity.namedLocations.table.rootDirectory"
                defaultMessage="Root Directory"
              />
            </TableCell>
            <MinWidthTableCell>
              <FormattedMessage
                id="systemActivity.namedLocations.table.actionsColumn"
                defaultMessage="Actions"
              />
            </MinWidthTableCell>
          </TableRow>
        </StyledTableHead>
        <NamedLocationsTableBody
          data={response && response.results}
          mapToRow={namedLocationRow}
          noDataMessage={intl.formatMessage({
            id: 'systemActivity.namedLocations.table.noData',
            defaultMessage: 'No matching named locations.',
          })}
          numCols={3}
        />
      </BrowseTable>
      <AddFab
        name="addNamedLocation"
        onClick={() => setCreateNamedLocation(true)}
        aria-label={intl.formatMessage({
          id: 'systemActivity.namedLocations.createNamedLocation.ariaLabel',
          defaultMessage: 'Create Named Location',
        })}
      />
      <NamedLocationDialog
        location={editNamedLocation}
        open={!!editNamedLocation || createNamedLocation}
        onUpdated={handleNamedLocationUpdated}
        onClose={handleCloseNamedLocationDialog}
      />
      <DeleteNamedLocationDialog
        namedLocationKey={confirmDeleteNamedLocation}
        setNamedLocationKey={setConfirmDeleteNamedLocation}
        refresh={refresh}
      />
    </Container>
  );
};

export default NamedLocations;

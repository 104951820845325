import { FC, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import * as DeletionJobApi from '../../../../../api/deletionJob';
import { extractErrorMessage } from '../../../../../api/endpoints';
import {
  ActiveAvatar,
  SecureConfirmDialog,
  DefaultButton,
  ListItemActionArea,
  PaddedPaper,
} from '../../../../../components';
import { DeletionJobEntity } from '../../../../../types';
import { intl } from '../../../../../Internationalization';
import { toDeletionJobUrl } from '../../../../system/activity/DeletionJobs';

import { AssignmentContext } from './AssignmentContext';

const AssignmentManagement: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { assignment } = useContext(AssignmentContext);
  const navigate = useNavigate();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const handleDelete = async () => {
    setProcessing(true);
    try {
      const { data: deletionJob } = await DeletionJobApi.createDeletionJob({
        entity: DeletionJobEntity.ASSIGNMENT,
        entityKey: assignment.key,
      });
      enqueueSnackbar(
        intl.formatMessage({
          id: 'assignment.management.deletionStarted',
          defaultMessage: 'Deletion task started',
        }),
        { variant: 'success' }
      );
      navigate(toDeletionJobUrl(deletionJob));
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'assignment.management.deleteError',
            defaultMessage: 'Failed to start assignment deletion task',
          })
        ),
        { variant: 'error' }
      );
      if (error.response.status === 400) {
        setDeleteDialogOpen(false);
      }
      setProcessing(false);
    }
  };

  return (
    <PaddedPaper>
      <Typography variant="h5" component="h3" gutterBottom>
        <FormattedMessage id="assignment.management.title" defaultMessage="Manage Assignment" />
      </Typography>
      <List>
        <ListItem>
          <ListItemAvatar>
            <ActiveAvatar activated="inactive">
              <DeleteForeverIcon />
            </ActiveAvatar>
          </ListItemAvatar>
          <ListItemText
            primary={intl.formatMessage({
              id: 'assignment.management.deleteAssignment',
              defaultMessage: 'Delete Assignment',
            })}
            secondary={intl.formatMessage({
              id: 'assignment.management.deleteAssignment.description',
              defaultMessage:
                'Permanently remove the assignment and all of its associated submissions',
            })}
          />
          <ListItemActionArea>
            <DefaultButton
              name="deleteAssignment"
              color="grey"
              onClick={() => setDeleteDialogOpen(true)}
            >
              <FormattedMessage
                id="assignment.management.deleteAssignmentButton"
                defaultMessage="Delete Assignment"
              />
            </DefaultButton>
          </ListItemActionArea>
        </ListItem>
      </List>
      <SecureConfirmDialog
        id="confirm-delete-assignment"
        disabled={processing}
        confirmCode={assignment.key}
        confirmCodeLabel={intl.formatMessage({
          id: 'assignment.management.confirmDeleteAssignment.confirmCodeLabel',
          defaultMessage: 'Assignment Key',
        })}
        isOpen={deleteDialogOpen}
        title={intl.formatMessage({
          id: 'assignment.management.confirmDeleteAssignment.title',
          defaultMessage: 'Delete Assignment?',
        })}
        confirmBtnText={intl.formatMessage({
          id: 'assignment.management.confirmDeleteAssignment.button',
          defaultMessage: 'Delete Assignment',
        })}
        confirmAction={handleDelete}
        closeAction={() => setDeleteDialogOpen(false)}
      >
        <Typography variant="body1" component="p" gutterBottom>
          <FormattedMessage
            id="project.management.confirmDeleteAssignment.warningMessage"
            defaultMessage="Are you absolutely sure you want to delete this assignment? This will delete the assignment and the associated submissions permanently."
          />
        </Typography>
        <Box my={2}>
          <Typography>
            <strong>
              <FormattedMessage
                id="project.management.confirmDeleteAssignment.assignmentKeyLabel"
                defaultMessage="Assignment Key:"
              />
            </strong>
            {' ' + assignment.key}
          </Typography>
          <Typography>
            <strong>
              <FormattedMessage
                id="project.management.confirmDeleteAssignment.assignmentReferenceLabel"
                defaultMessage="Assignment Reference:"
              />
            </strong>
            {' ' + assignment.reference}
          </Typography>
        </Box>
        <Typography variant="body1" component="p">
          <FormattedMessage
            id="project.management.confirmDeleteAssignment.confirmMessage"
            defaultMessage="Type the assignment key into the box below to confirm this action."
          />
        </Typography>
      </SecureConfirmDialog>
    </PaddedPaper>
  );
};

export default AssignmentManagement;

import { AxiosPromise } from 'axios';

import { KeySuggestion } from '../types';
import { AXIOS, entityDoesNotExist } from './endpoints';

export function projectKeySuggestion(name: string): AxiosPromise<KeySuggestion> {
  return AXIOS.get('/projectKeySuggestion', { params: { name } });
}

export function projectByName(name: string): Promise<boolean> {
  return entityDoesNotExist(
    AXIOS.head('/projectByName', {
      params: { name },
    })
  );
}

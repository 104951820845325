import { AXIOS } from './endpoints';
import { AxiosPromise } from 'axios';

import { MailTemplateDetail, MailTemplateSettings } from '../types/mail';

export function getGlobalMailTemplate(name: string): AxiosPromise<MailTemplateDetail> {
  return AXIOS.get(`/mail/global/${name}`);
}

export function updateGlobalMailTemplate(
  name: string,
  mailTemplate: MailTemplateSettings
): AxiosPromise<MailTemplateDetail> {
  return AXIOS.post(`/mail/global/${name}`, mailTemplate);
}

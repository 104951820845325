import { FC, useContext } from 'react';
import { SketchPicker, ColorResult } from 'react-color';

import { Typography, Box, useTheme } from '@mui/material';
import { rgbToHex, PaletteColor } from '@mui/material/styles';

import { CustomThemeContext } from '../../../../contexts/custom-theme/context';
import { intl } from '../../../../Internationalization';

import TextContrastValidator from './TextContrastValidator';

export type PaletteColorType = 'primary' | 'secondary';

const paletteColorTypeLabel = (type: PaletteColorType) => {
  switch (type) {
    case 'primary':
      return intl.formatMessage({
        id: 'site.theme.primaryColor',
        defaultMessage: 'Primary Colour',
      });
    case 'secondary':
      return intl.formatMessage({
        id: 'site.theme.secondaryColor',
        defaultMessage: 'Secondary Colour',
      });
  }
};

const paletteColorModes = ['dark', 'main', 'light'] as const;

interface ColorBarProps {
  main: string;
}

const ColorBar: FC<ColorBarProps> = ({ main }) => {
  const theme = useTheme();
  const color: PaletteColor = theme.palette.augmentColor({ color: { main } });

  return (
    <Box my={2} display="flex" flexDirection="row">
      {paletteColorModes.map((key) => (
        <Box
          sx={{
            width: 64,
            height: 64,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          style={{ backgroundColor: color[key] }}
          key={key}
        >
          <Typography variant="caption" sx={{ color: theme.palette.getContrastText(color[key]) }}>
            {rgbToHex(color[key])}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

interface ColorPickerProps {
  id: string;
  themeColorType: PaletteColorType;
}

const ColorPicker: FC<ColorPickerProps> = ({ id, themeColorType }) => {
  const { palette, updatePalette } = useContext(CustomThemeContext);
  const color = palette[themeColorType];

  const handleColorChangeComplete = (updatedColor: ColorResult, type: PaletteColorType) => {
    const newPalette = { ...palette, [type]: { main: updatedColor.hex } };
    updatePalette(newPalette);
  };

  return (
    <Box id={id} display="flex" flexDirection="column" alignItems="center" mx={3} my={1}>
      <Typography gutterBottom variant="h5" align="center">
        {paletteColorTypeLabel(themeColorType)}
      </Typography>
      <SketchPicker
        disableAlpha
        color={color.main}
        width="300px"
        onChangeComplete={(selectedColor) =>
          handleColorChangeComplete(selectedColor, themeColorType)
        }
      />
      <ColorBar main={color.main} />
      <TextContrastValidator main={color.main} type={themeColorType} />
    </Box>
  );
};

export default ColorPicker;

import { FC, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import * as SpecificationsApi from '../../../api/specifications';
import * as AssignmentsApi from '../../../api/assignments';
import {
  AssignmentDetail,
  AssignmentsResponse,
  ProjectDetail,
  SpecificationsResponse,
} from '../../../types';
import { extractErrorMessage } from '../../../api/endpoints';
import { intl } from '../../../Internationalization';

import { TableInfoRow, TableLoadingRow, ProjectAvatar, SupplierAvatar } from '../../../components';
import { AssignmentRow, SpecificationRow } from './preview-table';
import { Box, CircularProgress, IconButton, TableCell, TableRow } from '@mui/material';

const seeMoreCount = (total: number, expanded: boolean) =>
  !expanded && total > 1 && `(+ ${total - 1})`;

const filterAssignments = (assignments?: AssignmentDetail[], specificationKey?: string) =>
  assignments && specificationKey
    ? assignments.filter((assignment) => assignment.specification.key === specificationKey)
    : undefined;

const PAGE_SIZE = 100;

interface ProjectExportRowDetailsProps {
  project: ProjectDetail;
  isExpanded: boolean;
  handleExpandProject: React.Dispatch<React.SetStateAction<ProjectDetail | undefined>>;
  handleRemoveProject: (projectKey: string) => void;
}

const ProjectExportRowDetails: FC<ProjectExportRowDetailsProps> = ({
  project,
  isExpanded,
  handleExpandProject,
  handleRemoveProject,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [assignments, setAssignments] = useState<AssignmentsResponse>();
  const [specifications, setSpecifications] = useState<SpecificationsResponse>();

  const firstSpecificationsAssignments = filterAssignments(
    assignments?.results,
    specifications?.results[0]?.key
  );

  useEffect(() => {
    const fetchProjectDependencies = async () => {
      setLoading(true);
      try {
        const [specificationsResult, assignmentResult] = await Promise.all([
          SpecificationsApi.getSpecifications(project.key, {
            page: 0,
            size: PAGE_SIZE,
          }),
          AssignmentsApi.getAssignments({
            projectKey: project.key,
            page: 0,
            size: PAGE_SIZE,
          }),
        ]);
        setSpecifications(specificationsResult.data);
        setAssignments(assignmentResult.data);
      } catch (error: any) {
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'projectImport.export.projectDetails.fetchError',
              defaultMessage: 'Failed to load project details',
            })
          )
        );
      } finally {
        setLoading(false);
      }
    };
    fetchProjectDependencies();
    return () => {
      setSpecifications(undefined);
      setAssignments(undefined);
    };
  }, [enqueueSnackbar, project.key]);

  const renderExpandableContent = () => {
    if (!isExpanded) {
      return null;
    }
    if (loading) {
      return <TableLoadingRow colSpan={5} />;
    }
    if (!assignments?.results.length && !specifications?.results.length) {
      return (
        <TableInfoRow
          size="medium"
          message={intl.formatMessage({
            id: 'projectImport.export.projectDetails.noContent',
            defaultMessage: 'There are no project dependencies to display',
          })}
          colSpan={4}
        />
      );
    }
    if (
      specifications?.total &&
      specifications?.total > PAGE_SIZE &&
      assignments?.total &&
      assignments?.total > PAGE_SIZE
    ) {
      return (
        <TableInfoRow
          colSpan={5}
          height={50}
          size="small"
          message={intl.formatMessage(
            {
              id: 'projectImport.export.projectDetails.totalExceeded',
              defaultMessage:
                'Maximum results exceeded, unable to render preview. {specifications} specifications and {assignments} assignments will be exported for this project.',
            },
            {
              specifications: specifications?.total,
              assignments: assignments?.total,
              pageSize: PAGE_SIZE,
            }
          )}
        />
      );
    }

    return (
      <>
        {firstSpecificationsAssignments?.map((assignment, assIndex) =>
          assIndex ? <AssignmentRow assignment={assignment} actionColumn /> : null
        )}
        {specifications?.results.map((specification, specIndex) => {
          if (!specIndex) {
            return null;
          }
          const filteredAssignments = filterAssignments(assignments?.results, specification.key);
          return (
            <>
              <SpecificationRow
                specification={specification}
                actionColumn
                assignment={filteredAssignments && filteredAssignments[0]}
                rowSpan={filteredAssignments?.length || 1}
              />
              {filteredAssignments &&
                filteredAssignments.map((assignment, assIndex) => {
                  if (!assIndex) {
                    return null;
                  }
                  return <AssignmentRow assignment={assignment} actionColumn />;
                })}
            </>
          );
        })}
      </>
    );
  };
  const renderProjectDetailCells = () => {
    if (loading) {
      return (
        <TableCell colSpan={3} align="center">
          <CircularProgress size={35} sx={{ color: 'text.secondary' }} />
        </TableCell>
      );
    }
    return (
      <>
        {specifications?.results.length ? (
          <TableCell rowSpan={isExpanded ? firstSpecificationsAssignments?.length : 1}>
            {specifications.results[0].key} - {specifications.results[0].name}&nbsp;
            {seeMoreCount(specifications.total, isExpanded)}
          </TableCell>
        ) : (
          <TableCell />
        )}
        {assignments?.results.length ? (
          <>
            <TableCell>
              {assignments.results[0].key} - {assignments.results[0].reference}&nbsp;
              {seeMoreCount(assignments.total, isExpanded)}
            </TableCell>
            <TableCell>
              <Box display="flex" alignItems="center">
                <SupplierAvatar supplier={assignments.results[0].supplier} sx={{ mr: 1 }} />
                {assignments.results[0].supplier.key} - {assignments.results[0].supplier.name}&nbsp;
                {seeMoreCount(assignments.total, isExpanded)}
              </Box>
            </TableCell>
          </>
        ) : (
          <TableCell colSpan={2} />
        )}
      </>
    );
  };
  return (
    <>
      <TableRow>
        <TableCell align="left" rowSpan={isExpanded ? assignments?.total : 1}>
          <Box display="flex" alignItems="center">
            <ProjectAvatar project={project} sx={{ mr: 1 }} />
            {project.key}&nbsp;-&nbsp;{project.name}
          </Box>
        </TableCell>
        {renderProjectDetailCells()}
        <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
          <IconButton
            name="removeProject"
            onClick={() => handleRemoveProject(project.key)}
            size="large"
            aria-label={intl.formatMessage({
              id: 'projectImport.export.removeProject.ariaLabel',
              defaultMessage: 'Remove project',
            })}
          >
            <CloseIcon />
          </IconButton>
          <IconButton
            name="expandProjectDetails"
            onClick={() =>
              handleExpandProject((prev) => (prev?.key === project.key ? undefined : project))
            }
            disabled={
              !(specifications?.total && specifications?.total > 1) ||
              !(assignments?.total && assignments?.total > 1)
            }
            size="large"
            aria-label={
              isExpanded
                ? intl.formatMessage({
                    id: 'projectImport.export.collapseOptions.ariaLabel',
                    defaultMessage: 'Collapse project details',
                  })
                : intl.formatMessage({
                    id: 'projectImport.export.expandOptions.ariaLabel',
                    defaultMessage: 'Expand project details',
                  })
            }
          >
            <ExpandMoreIcon
              sx={(theme) => ({
                transition: theme.transitions.create('transform', {
                  duration: theme.transitions.duration.shortest,
                }),
                transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
              })}
            />
          </IconButton>
        </TableCell>
      </TableRow>
      {renderExpandableContent()}
    </>
  );
};

export default ProjectExportRowDetails;

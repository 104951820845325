import { FC, useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';
import { isUndefined } from 'lodash';

import { Dialog, DialogTitle, Divider, Tab, Tabs } from '@mui/material';

import { extractErrorMessage } from '../../../../api/endpoints';
import * as AssignmentSavedMappingsApi from '../../../../api/assignmentSavedMappings';
import { AddFab, MessageBox } from '../../../../components';
import { intl } from '../../../../Internationalization';
import { MAX_MAPPINGS } from '../../../../types';

import { MyAssignmentContext } from '../MyAssignmentContext';

import CreateSavedMappingForm from './CreateSavedMappingForm';
import { CopySavedMapping } from './copy-saved-mapping';

type AddSavedMappingDialogTabs = 'NEW' | 'COPY';

const AddSavedMappingDialog: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { assignmentKey } = useContext(MyAssignmentContext);
  const [tab, setTab] = useState<AddSavedMappingDialogTabs>('NEW');
  const [savedMappingCount, setSavedMappingCount] = useState<number>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const showMessage = !isUndefined(savedMappingCount) && savedMappingCount >= MAX_MAPPINGS;
  const disableNext = isUndefined(savedMappingCount) || savedMappingCount >= MAX_MAPPINGS;

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSavedMappingCount(undefined);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
    fetchSavedMappingCount();
    setTab('NEW');
  };

  const fetchSavedMappingCount = async () => {
    try {
      const {
        data: { total },
      } = await AssignmentSavedMappingsApi.getSavedMappings(assignmentKey, { size: 1, page: 0 });
      setSavedMappingCount(total);
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'myAssignment.savedMapping.add.countError',
            defaultMessage: 'Failed to fetch saved schema mappings count',
          })
        ),
        { variant: 'error' }
      );
    }
  };

  return (
    <>
      <Dialog
        id="add-saved-mapping-dialog"
        onClose={handleCloseDialog}
        aria-labelledby="add-saved-mapping-dialog-title"
        open={dialogOpen}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="add-saved-mapping-dialog-title">
          <FormattedMessage
            id="myAssignment.savedMapping.add.title"
            defaultMessage="Add Saved Mapping"
          />
        </DialogTitle>
        <Divider />
        {showMessage && (
          <>
            <MessageBox
              level="info"
              message={intl.formatMessage({
                id: 'myAssignment.savedMapping.add.savedMappingCountLimit',
                defaultMessage: 'Limit of 10 saved mappings reached',
              })}
            />
            <Divider />
          </>
        )}
        <Tabs value={tab} onChange={(_, newTab) => setTab(newTab)} variant="fullWidth">
          <Tab
            label={intl.formatMessage({
              id: 'myAssignment.savedMapping.add.tabs.newMapping',
              defaultMessage: 'New Mapping',
            })}
            value="NEW"
          />
          <Tab
            label={intl.formatMessage({
              id: 'myAssignment.savedMapping.add.tabs.copyMapping',
              defaultMessage: 'Copy Mapping',
            })}
            value="COPY"
          />
        </Tabs>
        {tab === 'NEW' ? (
          <CreateSavedMappingForm onClose={handleCloseDialog} disableNext={disableNext} />
        ) : (
          <CopySavedMapping onClose={handleCloseDialog} disableNext={disableNext} />
        )}
      </Dialog>
      <AddFab
        name="addSavedMapping"
        onClick={handleOpenDialog}
        aria-label={intl.formatMessage({
          id: 'myAssignment.savedMapping.add.addFab.ariaLabel',
          defaultMessage: 'Add saved mapping',
        })}
      />
    </>
  );
};

export default AddSavedMappingDialog;

import { FC } from 'react';

import * as NamedLocationsApi from '../../api/namedLocations';
import { NamedLocationSummary } from '../../types';
import FilteredAutocomplete, { BasicFilteredAutocompleteProps } from './FilteredAutocomplete';

type NamedLocationsAutocompleteProps = BasicFilteredAutocompleteProps<NamedLocationSummary, false>;

const fetchOptions = async (filter: string) => {
  const response = await NamedLocationsApi.getNamedLocations({ page: 0, size: 100, filter });
  return response.data.results;
};

const renderOption = (namedLocation: NamedLocationSummary) => namedLocation.name;
const getOptionSelected = (option: NamedLocationSummary, value: NamedLocationSummary) =>
  option.key === value.key;
const getOptionLabel = (namedLocation: NamedLocationSummary) => namedLocation.name;

const NamedLocationsAutocomplete: FC<NamedLocationsAutocompleteProps> = (props) => (
  <FilteredAutocomplete
    {...props}
    fetchOptions={fetchOptions}
    renderOption={renderOption}
    getOptionSelected={getOptionSelected}
    getOptionLabel={getOptionLabel}
  />
);

export default NamedLocationsAutocomplete;

import { FC, useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { ValidateFieldsError } from 'async-validator';
import { useSnackbar } from 'notistack';

import { DialogContent } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import * as AssignmentSavedMappingsApi from '../../../../api/assignmentSavedMappings';
import { extractErrorMessage } from '../../../../api/endpoints';
import { DefaultButton, PaddedDialogActions, ValidatedTextField } from '../../../../components';
import { intl } from '../../../../Internationalization';
import { createSavedMappingValidator, validate } from '../../../../validation';

import { MyAssignmentContext } from '../MyAssignmentContext';
import { toSavedMapping } from './SavedMappings';

interface CreateSavedMappingProps {
  onClose: () => void;
  disableNext: boolean;
}

const CreateSavedMapping: FC<CreateSavedMappingProps> = ({ onClose, disableNext }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { assignmentKey } = useContext(MyAssignmentContext);
  const [name, setName] = useState<string>('');
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();
  const [processing, setProcessing] = useState<boolean>(false);

  const handleCloseDialog = () => {
    onClose();
  };

  const createMapping = async (validationResult: { name: string }) => {
    try {
      const response = await AssignmentSavedMappingsApi.createSavedMapping(assignmentKey, {
        name: validationResult.name,
        mappings: [],
      });
      enqueueSnackbar(
        intl.formatMessage({
          id: 'myAssignment.savedMapping.create.createSuccess',
          defaultMessage: 'Saved mapping created',
        }),
        { variant: 'success' }
      );
      navigate(toSavedMapping(assignmentKey, response.data.key));
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'myAssignment.savedMapping.create.createError',
            defaultMessage: 'Failed to create saved mapping',
          })
        ),
        { variant: 'error' }
      );
      setProcessing(false);
    }
  };

  const validateAndSubmit = async () => {
    setProcessing(true);
    try {
      setFieldErrors({});
      await createMapping(
        await validate(
          createSavedMappingValidator(() => assignmentKey),
          { name }
        )
      );
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  return (
    <>
      <DialogContent dividers={true}>
        <ValidatedTextField
          name="name"
          value={name}
          label={intl.formatMessage({
            id: 'myAssignment.savedMapping.create.name.label',
            defaultMessage: 'Name',
          })}
          tooltip={intl.formatMessage({
            id: 'myAssignment.savedMapping.create.name.tooltip',
            defaultMessage: 'A descriptive name, unique to this assignment.',
          })}
          onChange={(e) => setName(e.target.value)}
          fieldErrors={fieldErrors}
          disabled={processing || disableNext}
          margin="normal"
          variant="outlined"
        />
      </DialogContent>
      <PaddedDialogActions>
        <DefaultButton
          name="cancel"
          color="secondary"
          onClick={handleCloseDialog}
          disabled={processing}
        >
          <FormattedMessage
            id="myAssignment.savedMapping.create.cancelButton"
            defaultMessage="Cancel"
          />
        </DefaultButton>
        <DefaultButton
          name="create"
          onClick={validateAndSubmit}
          disabled={processing || disableNext}
          startIcon={<AddIcon />}
        >
          <FormattedMessage
            id="myAssignment.savedMapping.create.createButton"
            defaultMessage="Create"
          />
        </DefaultButton>
      </PaddedDialogActions>
    </>
  );
};

export default CreateSavedMapping;

import { FC, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';
import { Container, Typography, FormControlLabel, Switch, TextField, Grid } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import { extractErrorMessage } from '../../../../api/endpoints';
import * as Saml2IdentityProviderApi from '../../../../api/saml2IdentityProvider';
import { PaddedPaper, DefaultButton, FormButtons, ConfirmDialog } from '../../../../components';
import { intl } from '../../../../Internationalization';

import { Saml2IdentityProviderContext } from './Saml2IdentityProviderContext';

const Saml2IdentityProviderServiceSigning: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { identityProvider, identityProviderUpdated } = useContext(Saml2IdentityProviderContext);

  const [confirmRegenerate, setConfirmRegenerate] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const updateSignRequests = (checked: boolean) => {
    setProcessing(true);
    Saml2IdentityProviderApi.updateIdentityProvider(identityProvider.key, {
      ...identityProvider,
      signRequests: checked,
    })
      .then((response) => {
        identityProviderUpdated(response.data);
        enqueueSnackbar(
          response.data.signRequests
            ? intl.formatMessage({
                id: 'saml2.identityProviderServiceSigning.saveSuccess.signingOn',
                defaultMessage: 'Requests will be signed',
              })
            : intl.formatMessage({
                id: 'saml2.identityProviderServiceSigning.saveSuccess.signingOff',
                defaultMessage: 'Requests will not be signed',
              }),
          { variant: 'success' }
        );
      })
      .catch((error) => {
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'saml2.identityProviderServiceSigning.saveError',
              defaultMessage: 'Failed to update request signing mode',
            })
          ),
          { variant: 'error' }
        );
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const regenerateKeys = () => {
    setProcessing(true);
    Saml2IdentityProviderApi.regenerateKeys(identityProvider.key)
      .then((response) => {
        identityProviderUpdated(response.data);
        enqueueSnackbar(
          intl.formatMessage({
            id: 'saml2.identityProviderServiceSigning.regenerateSuccess',
            defaultMessage: 'Keys have successfully been re-generated',
          }),
          { variant: 'success' }
        );
      })
      .catch((error) => {
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'saml2.identityProviderServiceSigning.regenerateError',
              defaultMessage: 'Failed to re-generate keys',
            })
          ),
          { variant: 'error' }
        );
      })
      .finally(() => {
        setConfirmRegenerate(false);
        setProcessing(false);
      });
  };

  return (
    <Container maxWidth="md" id="system-identity-provider-saml2-service-signing" disableGutters>
      <PaddedPaper>
        <Typography variant="h5" gutterBottom>
          <FormattedMessage
            id="saml2.identityProviderServiceSigning.title"
            defaultMessage="Service Provider Signing"
          />
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  disabled={processing}
                  checked={identityProvider.signRequests}
                  onChange={(event) => updateSignRequests(event.target.checked)}
                  name="signRequests"
                />
              }
              label={intl.formatMessage({
                id: 'saml2.identityProviderServiceSigning.signRequests.label',
                defaultMessage: 'Sign Auth Requests?',
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              rows={10}
              variant="outlined"
              fullWidth
              value={identityProvider.spCertificate}
              multiline
              disabled
              label={intl.formatMessage({
                id: 'saml2.identityProviderServiceSigning.serviceProviderCertificate.label',
                defaultMessage: 'Service Provider Certificate',
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              rows={10}
              variant="outlined"
              fullWidth
              value={identityProvider.spPrivateKey}
              multiline
              disabled
              label={intl.formatMessage({
                id: 'saml2.identityProviderServiceSigning.serviceProviderPrivateKey.label',
                defaultMessage: 'Service Provider Private Key',
              })}
            />
          </Grid>
        </Grid>
        <FormButtons>
          <DefaultButton
            id="regenerate-keys"
            startIcon={<AutorenewIcon />}
            disabled={processing}
            onClick={() => setConfirmRegenerate(true)}
          >
            <FormattedMessage
              id="saml2.identityProviderServiceSigning.regenerateButton"
              defaultMessage="Re-generate Keys"
            />
          </DefaultButton>
        </FormButtons>
        <ConfirmDialog
          id="confirm-regenerate-keys"
          isOpen={confirmRegenerate}
          confirmAction={regenerateKeys}
          closeAction={() => setConfirmRegenerate(false)}
          title={intl.formatMessage({
            id: 'saml2.identityProviderServiceSigning.confirmRegenerateKeys.title',
            defaultMessage: 'Re-Generate Keys',
          })}
          text={intl.formatMessage({
            id: 'saml2.identityProviderServiceSigning.confirmRegenerateKeys.text',
            defaultMessage: 'Are you sure you want to re-generate the service provider key pair?',
          })}
          confirmBtnText={intl.formatMessage({
            id: 'saml2.identityProviderServiceSigning.confirmRegenerateKeys.confirmButton',
            defaultMessage: 'Re-generate',
          })}
        />
      </PaddedPaper>
    </Container>
  );
};

export default Saml2IdentityProviderServiceSigning;

import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  Theme,
  PaletteColor,
  alpha,
} from '@mui/material/styles';

import { grey } from '@mui/material/colors';

import { Palette } from '../../types';
import { CustomThemeContext } from './context';

const defaultPalette = {
  primary: {
    main: '#555',
  },
  secondary: {
    main: '#e1e758',
  },
};

const initialTheme = createTheme({
  palette: {
    ...defaultPalette,
    background: {
      default: '#fafafa',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.77)',
    },
    grey: {
      main: grey[300],
      dark: grey[400],
    },
  },
});

const rawTheme = createTheme(initialTheme, {
  palette: {
    default: initialTheme.palette.augmentColor({
      color: { main: initialTheme.palette.grey[600] },
    }),
    highlight: {
      success: '#ddffdd',
      failure: '#ffdddd',
      selected: '#eeeeee',
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'grey' },
          style: {
            color: initialTheme.palette.getContrastText(initialTheme.palette.grey[300]),
          },
        },
        {
          props: { variant: 'outlined', color: 'grey' },
          style: {
            'color': initialTheme.palette.text.primary,
            'borderColor':
              initialTheme.palette.mode === 'light'
                ? 'rgba(0, 0, 0, 0.23)'
                : 'rgba(255, 255, 255, 0.23)',
            '&.Mui-disabled': {
              border: `1px solid ${initialTheme.palette.action.disabledBackground}`,
            },
            '&:hover': {
              borderColor:
                initialTheme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.23)'
                  : 'rgba(255, 255, 255, 0.23)',
              backgroundColor: alpha(
                initialTheme.palette.text.primary,
                initialTheme.palette.action.hoverOpacity
              ),
            },
          },
        },
        {
          props: { color: 'grey', variant: 'text' },
          style: {
            'color': initialTheme.palette.text.primary,
            '&:hover': {
              backgroundColor: alpha(
                initialTheme.palette.text.primary,
                initialTheme.palette.action.hoverOpacity
              ),
            },
          },
        },
      ],
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
  },
});

const CustomTheme: FC<PropsWithChildren> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(createTheme(rawTheme, defaultPalette));
  const [palette, setPalette] = useState<Palette>(defaultPalette);

  useEffect(() => {
    setTheme((prevTheme) =>
      createTheme(prevTheme, {
        palette: {
          primary: prevTheme.palette.augmentColor({ color: palette.primary }),
          secondary: prevTheme.palette.augmentColor({ color: palette.secondary }),
        },
      })
    );
  }, [palette]);

  const updatePalette = useCallback((newPalette: Palette) => setPalette(newPalette), []);

  return (
    <CustomThemeContext.Provider value={{ palette, updatePalette }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </CustomThemeContext.Provider>
  );
};

export default CustomTheme;

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey: true;
  }
}

declare module '@mui/material/ButtonGroup' {
  interface ButtonGroupPropsColorOverrides {
    grey: true;
  }
}

declare module '@mui/material' {
  interface Palette {
    default: PaletteColor;
    highlight: {
      success: string;
      failure: string;
      selected: string;
    };
  }
  interface Color {
    main: string;
    dark: string;
  }
}

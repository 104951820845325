import { FC, useContext, useState, useEffect } from 'react';
import copy from 'clipboard-copy';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  InputBase,
  Paper,
  Typography,
} from '@mui/material';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import LinkIcon from '@mui/icons-material/Link';

import * as UserApi from '../../../api/user';
import { intl } from '../../../Internationalization';
import { extractErrorMessage } from '../../../api/endpoints';
import { PaddedDialogActions, DefaultButton } from '../../../components';

import { UserContext } from './UserContext';

interface ActivationLinkDialogProps {
  open: boolean;
  onClose: () => void;
}

const ActivationLinkDialog: FC<ActivationLinkDialogProps> = ({ open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const [processing, setProcessing] = useState<boolean>(false);
  const [activationLink, setActivationLink] = useState<string>();

  useEffect(() => {
    if (open) {
      setActivationLink(undefined);
    }
  }, [open]);

  const handleGenerateActivationLink = async () => {
    setProcessing(true);
    try {
      const response = await UserApi.generateActivationLink(user.key);
      setActivationLink(response.data.link);
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage(
            {
              id: 'user.status.activationLink.generateError',
              defaultMessage: 'Failed to generate the activation link for {username}',
            },
            { username: user.name }
          )
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessing(false);
    }
  };

  const copyActivationLink = () => {
    if (activationLink) {
      copy(activationLink);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'user.status.activationLink.dialog.copySuccess',
          defaultMessage: 'Copied activation link to clipboard',
        }),
        { variant: 'info' }
      );
    }
  };

  const renderDialogAction = () => {
    if (activationLink) {
      return (
        <Paper sx={{ py: 0.25, px: 0.5, display: 'flex', alignItems: 'center', width: '100%' }}>
          <Box p={1.5} display="flex">
            <LinkIcon />
          </Box>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <InputBase sx={{ mx: 1, flex: 1 }} value={activationLink} />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <DefaultButton
            sx={{ mx: 1 }}
            onClick={copyActivationLink}
            startIcon={<FilterNoneIcon />}
            color="grey"
          >
            <FormattedMessage
              id="user.status.activationLink.dialog.copy.copyButton"
              defaultMessage="Copy"
            />
          </DefaultButton>
        </Paper>
      );
    }

    return (
      <DefaultButton
        fullWidth
        startIcon={<FilterNoneIcon />}
        disabled={processing}
        onClick={handleGenerateActivationLink}
      >
        <FormattedMessage
          id="user.status.activationLink.dialog.generate.generateLinkButton"
          defaultMessage="Generate Link"
        />
      </DefaultButton>
    );
  };

  return (
    <>
      <Dialog
        id="activation-link-dialog"
        onClose={onClose}
        aria-labelledby="activation-link-dialog-title"
        open={open}
        fullWidth
      >
        <DialogTitle id="activation-link-dialog-title">
          <FormattedMessage
            id="user.status.activationLink.dialog.title"
            defaultMessage="Activation Link"
          />
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText>
            <FormattedMessage
              id="user.status.activationLink.dialog.description"
              defaultMessage="Use this feature to generate an activation link. This link may be sent to the user so they can set their password and activate their account."
            />
          </DialogContentText>
          <Box my={2}>
            <Typography>
              <strong>
                <FormattedMessage
                  id="user.status.activationLink.dialog.email"
                  defaultMessage="Email:"
                />
              </strong>
              {' ' + user.email}
            </Typography>
            <Typography>
              <strong>
                <FormattedMessage
                  id="user.status.activationLink.dialog.name"
                  defaultMessage="Name:"
                />
              </strong>
              {' ' + user.name}
            </Typography>
          </Box>
          {renderDialogAction()}
        </DialogContent>
        <PaddedDialogActions>
          <DefaultButton
            name="closeActivationLinkDialog"
            color="secondary"
            onClick={onClose}
            disabled={processing}
          >
            <FormattedMessage
              id="user.status.activationLink.dialog.close.label"
              defaultMessage="Close"
            />
          </DefaultButton>
        </PaddedDialogActions>
      </Dialog>
    </>
  );
};

export default ActivationLinkDialog;

import { AXIOS, entityDoesNotExist } from './endpoints';

export function assignmentByReference(
  specificationKey: string,
  reference: string
): Promise<boolean> {
  return entityDoesNotExist(
    AXIOS.head(`/assignmentByReference`, {
      params: { specificationKey, reference },
    })
  );
}

import { FC, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';

import { Divider, IconButton, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { onEnterCallback } from '../../util';

const DEBOUNCE_DELAY = 300;

interface FilterSearchProps {
  onSearch: (filter: string) => void;
  placeholder: string;
  disabled?: boolean;
}

const FilterSearch: FC<FilterSearchProps> = ({ placeholder, disabled, onSearch }) => {
  const didMount = useRef(false);
  const [filter, setFilter] = useState('');

  const debouncedSearch = useRef(debounce(onSearch, DEBOUNCE_DELAY));

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    debouncedSearch.current(filter);
  }, [filter]);

  const searchNow = () => {
    onSearch(filter);
    debouncedSearch.current.cancel();
  };
  const submitOnEnter = onEnterCallback(searchNow);

  return (
    <>
      <InputBase
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        sx={{ minWidth: 100, maxWidth: 250, flex: 1, m: (theme) => theme.spacing(1, 0, 1, 1) }}
        placeholder={placeholder}
        inputProps={{ 'aria-label': placeholder }}
        onKeyDown={submitOnEnter}
        disabled={disabled}
        endAdornment={
          <IconButton
            onClick={searchNow}
            sx={{ p: 1, m: -0.5 }}
            aria-label={placeholder}
            disabled={disabled}
            size="large"
          >
            <SearchIcon />
          </IconButton>
        }
      />
      <Divider orientation="vertical" sx={{ width: '1px', height: '32px', m: 1 }} />
    </>
  );
};

export default FilterSearch;

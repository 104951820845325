import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import CustomTheme from './contexts/custom-theme/CustomTheme';

import Internationalization from './Internationalization';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <CustomTheme>
    <Internationalization>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Internationalization>
  </CustomTheme>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

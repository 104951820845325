import { AxiosPromise } from 'axios';
import { OneIntegrateServerSettings, OneIntegrateTestServerResponse } from '../types';
import { AXIOS } from './endpoints';

export function testServerConnection(
  settings: OneIntegrateServerSettings
): AxiosPromise<OneIntegrateTestServerResponse> {
  return AXIOS.post('/1integrate/server/test', settings);
}

export function evictCapabilities(): AxiosPromise<void> {
  return AXIOS.post('/1integrate/capabilities/evict');
}

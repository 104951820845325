import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { EditorState } from 'draft-js';

import SaveIcon from '@mui/icons-material/Save';
import { Typography, useTheme } from '@mui/material';

import { AvatarPaper, AvatarPaperHeader, DefaultButton, MailEditor } from '../../../../components';
import {
  ValidationErrors,
  MailTemplateMetadata,
  MailTemplateDetail,
  MailTemplateSettings,
} from '../../../../types';
import { textFromEditorState, editorStateFromText } from '../../../../util';

import MailEditorValidationError from './MailEditorValidationError';

interface TemplateEditorProps {
  templateMetadata: MailTemplateMetadata;
  template: MailTemplateDetail;
  validationErrors?: ValidationErrors;
  onSave: (name: string, template: MailTemplateSettings) => void;
}

const TemplateEditor: FC<TemplateEditorProps> = ({
  templateMetadata: { title, description, icon: Icon, textFields, linkFields },
  template,
  validationErrors,
  onSave,
}) => {
  const theme = useTheme();

  const [subjectEditorState, setSubjectEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [body, setBody] = useState<string>('');

  useEffect(() => {
    setSubjectEditorState(editorStateFromText(template.subject));
    setBody(template.text);
  }, [template]);

  const save = () => {
    onSave(template.name, {
      subject: textFromEditorState(subjectEditorState),
      text: body,
    });
  };

  return (
    <AvatarPaper id="mail-template-editor" icon={Icon} iconColor={theme.palette.info.main}>
      <AvatarPaperHeader>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="subtitle1">{description}</Typography>
      </AvatarPaperHeader>
      <MailEditor
        subjectEditorState={subjectEditorState}
        onSubjectEditorStateChange={setSubjectEditorState}
        body={body}
        setBody={setBody}
        textFields={textFields}
        linkFields={linkFields}
      />
      {validationErrors && <MailEditorValidationError validationErrors={validationErrors} />}
      <DefaultButton name="saveButton" onClick={save} startIcon={<SaveIcon />}>
        <FormattedMessage id="mail.template.saveButton" defaultMessage="Save" />
      </DefaultButton>
    </AvatarPaper>
  );
};

export default TemplateEditor;

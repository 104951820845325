import { FC, useCallback } from 'react';

import * as SpecificationsApi from '../../api/specifications';
import { SpecificationDetail } from '../../types';

import FilteredAutocomplete, { BasicFilteredAutocompleteProps } from './FilteredAutocomplete';

interface SpecificationAutocompleteProps
  extends BasicFilteredAutocompleteProps<SpecificationDetail, false> {
  projectKey?: string;
  active?: boolean;
}

const fetchSpecifications = async (projectKey: string, filter: string, active?: boolean) => {
  const response = await SpecificationsApi.getSpecifications(projectKey, {
    page: 0,
    size: 100,
    filter,
    active,
  });
  return response.data.results;
};

const renderOption = (specification: SpecificationDetail) => specification.name;
const getOptionSelected = (option: SpecificationDetail, value: SpecificationDetail) =>
  option.key === value.key;
const getOptionLabel = (specification: SpecificationDetail) => specification.name;

const SpecificationAutocomplete: FC<SpecificationAutocompleteProps> = ({
  projectKey,
  disabled,
  active,
  ...rest
}) => {
  const fetchOptions = useCallback(
    (filter: string) =>
      projectKey ? fetchSpecifications(projectKey, filter, active) : Promise.resolve([]),
    [projectKey, active]
  );
  return (
    <FilteredAutocomplete
      {...rest}
      fetchOptions={fetchOptions}
      renderOption={renderOption}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      disabled={disabled || !projectKey}
    />
  );
};

export default SpecificationAutocomplete;

import { AxiosPromise } from 'axios';

import {
  SupplierMappingsRequest,
  SupplierMappingsResponse,
  SupplierMappingSettings,
  SupplierMappingDetail,
} from '../types';
import { AXIOS, RequestConfig } from './endpoints';

export function getSupplierMappings(
  request: SupplierMappingsRequest,
  config?: RequestConfig
): AxiosPromise<SupplierMappingsResponse> {
  return AXIOS.get('/supplier_mappings', {
    ...config,
    params: request,
  });
}

export function createSupplierMapping(
  request: SupplierMappingSettings
): AxiosPromise<SupplierMappingDetail> {
  return AXIOS.post('/supplier_mappings', request);
}

import { AxiosPromise } from 'axios';

import {
  ApiTokenDetail,
  ApiTokenFullDetail,
  ApiTokensResponse,
  ApiTokensRequest,
  CreateApiTokenRequest,
  ApiTokenSettings,
} from '../types';

import { AXIOS, RequestConfig } from './endpoints';

export function getApiTokens(
  request: ApiTokensRequest,
  config?: RequestConfig
): AxiosPromise<ApiTokensResponse> {
  return AXIOS.get(`/api_tokens`, {
    ...config,
    params: request,
  });
}

export function createApiToken(request: CreateApiTokenRequest): AxiosPromise<ApiTokenFullDetail> {
  return AXIOS.post(`/api_tokens`, request);
}

export function deleteApiToken(userKey: string, name: string): AxiosPromise<void> {
  return AXIOS.delete(`/api_tokens/${userKey}/${name}`);
}

export function getApiToken(userKey: string, name: string): AxiosPromise<ApiTokenDetail> {
  return AXIOS.get(`/api_tokens/${userKey}/${name}`);
}

export function updateApiToken(
  request: ApiTokenSettings,
  userKey: string,
  name: string
): AxiosPromise<ApiTokenDetail> {
  return AXIOS.put(`/api_tokens/${userKey}/${name}`, request);
}

import { AxiosPromise } from 'axios';

import {
  SavedMappingDetail,
  SavedMappingInputDetail,
  SavedMappingSettings,
  SubmissionSchema,
} from '../types';

import { AXIOS, RequestConfig } from './endpoints';
import { createFormDataFromFile } from './media';

export function deleteSavedMapping(
  assignmentKey: string,
  savedMappingKey: string
): AxiosPromise<void> {
  return AXIOS.delete(`/assignments/${assignmentKey}/saved_mappings/${savedMappingKey}`);
}

export function getSavedMapping(
  assignmentKey: string,
  savedMappingKey: string
): AxiosPromise<SavedMappingDetail> {
  return AXIOS.get(`/assignments/${assignmentKey}/saved_mappings/${savedMappingKey}`);
}

export function getSchema(
  assignmentKey: string,
  savedMappingKey: string
): AxiosPromise<SubmissionSchema> {
  return AXIOS.get(`/assignments/${assignmentKey}/saved_mappings/${savedMappingKey}/schema`);
}

export function updateSavedMapping(
  assignmentKey: string,
  savedMappingKey: string,
  savedMappingSettings: SavedMappingSettings
): AxiosPromise<SavedMappingDetail> {
  return AXIOS.put(
    `/assignments/${assignmentKey}/saved_mappings/${savedMappingKey}`,
    savedMappingSettings
  );
}

export function uploadInput(
  assignmentKey: string,
  savedMappingKey: string,
  dataStorePath: string,
  file: File,
  config?: RequestConfig
): AxiosPromise<SavedMappingInputDetail> {
  return AXIOS.post(
    `/assignments/${assignmentKey}/saved_mappings/${savedMappingKey}/inputs`,
    createFormDataFromFile(file),
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: { dataStorePath },
      ...(config || {}),
    }
  );
}

export function deleteInput(
  assignmentKey: string,
  savedMappingKey: string,
  dataStorePath: string
): AxiosPromise<void> {
  return AXIOS.delete(`/assignments/${assignmentKey}/saved_mappings/${savedMappingKey}/inputs`, {
    params: { dataStorePath },
  });
}

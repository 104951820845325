import React, { FC, useContext, useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Typography, IconButton, Divider, Card, Box, Popover } from '@mui/material';

import { AuthenticatedContext } from '../../../../contexts/authentication';
import { DefaultButton } from '../../../';
import { AccountAvatar } from '../../../avatars';
import { intl } from '../../../../Internationalization';

const AuthMenu: FC = () => {
  const authenticatedContext = useContext(AuthenticatedContext);
  const [authAnchorEl, setAuthAnchorEl] = useState<HTMLButtonElement | null>(null);
  const authMenuOpen = Boolean(authAnchorEl);

  const anchorRef = useRef<HTMLButtonElement>(null);

  const onNavigate = () => {
    setAuthAnchorEl(null);
  };

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAuthAnchorEl(event.currentTarget);
  };

  const handleSignOut = () => {
    authenticatedContext.signOut(true);
    onNavigate();
  };

  const handleClose = (event: React.MouseEvent<Document>) => {
    if (anchorRef.current && anchorRef.current.contains(event.currentTarget)) {
      return;
    }

    setAuthAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="open-auth-menu"
        sx={{ py: 0.5, pl: 1, pr: 0 }}
        aria-label={intl.formatMessage({
          id: 'components.appBar.authMenu.openButton.ariaLabel',
          defaultMessage: 'Open account menu',
        })}
        aria-owns={authMenuOpen ? 'app-menu-authentication' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        size="large"
      >
        <AccountAvatar user={authenticatedContext.me} />
      </IconButton>
      <Popover
        PaperProps={{ id: 'app-menu-authentication' }}
        open={authMenuOpen}
        anchorEl={authAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableScrollLock
      >
        <Card>
          <Box display="flex" flexDirection="row" alignItems="center" p={2}>
            <AccountAvatar size="large" user={authenticatedContext.me} />
            <Box pl={2}>
              <Typography variant="h6">{authenticatedContext.me.name}</Typography>
              <Typography
                variant="body1"
                sx={{
                  maxWidth: '250px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {authenticatedContext.me.email}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box pt={1.5} px={1.5}>
            <DefaultButton
              sx={{ mb: 1.5 }}
              component={Link}
              to="/account/"
              id="my-account"
              fullWidth
              color="secondary"
              onClick={onNavigate}
            >
              <FormattedMessage
                id="components.appBar.authMenu.myAccountButton"
                defaultMessage="My Account"
              />
            </DefaultButton>
            <DefaultButton sx={{ mb: 1.5 }} id="sign-out" fullWidth onClick={handleSignOut}>
              <FormattedMessage
                id="components.appBar.authMenu.signOutButton"
                defaultMessage="Sign Out"
              />
            </DefaultButton>
          </Box>
        </Card>
      </Popover>
    </>
  );
};

export default AuthMenu;

import { AxiosPromise } from 'axios';
import {
  CreateSpecificationActionConfig,
  SpecificationActions,
  SpecificationActionConfig,
} from '../types';
import { AXIOS } from './endpoints';

export function createSpecificationAction(
  specificationKey: string,
  request: CreateSpecificationActionConfig
): AxiosPromise<SpecificationActions> {
  return AXIOS.post(`/specifications/${specificationKey}/actions/create`, request);
}

export function updateSpecificationActions(
  specificationKey: string,
  request: SpecificationActionConfig[]
): AxiosPromise<SpecificationActions[]> {
  return AXIOS.post(`/specifications/${specificationKey}/actions`, request);
}

export function listSpecificationActions(
  specificationKey: string
): AxiosPromise<SpecificationActions[]> {
  return AXIOS.get(`/specifications/${specificationKey}/actions`);
}

import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import {
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
} from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ForwardIcon from '@mui/icons-material/Forward';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { IdentityProviderConfigurationDetail, IdentityProviderType } from '../../../types';
import { DefaultButton, FormButtons, PaddedPaper } from '../../../components';
import { intl } from '../../../Internationalization';

interface IdentityProviderInformationProps {
  identityProviderType: IdentityProviderType;
  identityProviderKey: string;
  identityProviderConfiguration: IdentityProviderConfigurationDetail;
}

const IdentityProviderInformation: FC<IdentityProviderInformationProps> = ({
  identityProviderType,
  identityProviderKey,
  identityProviderConfiguration,
}) => (
  <PaddedPaper id="system-identity-provider-settings-configuration-details">
    <Typography variant="h5" gutterBottom>
      <FormattedMessage
        id="identityProvider.information.title"
        defaultMessage="Configuration Details"
      />
    </Typography>
    <>
      <List>
        {identityProviderConfiguration.spEntityId && (
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar>#</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={intl.formatMessage({
                id: 'identityProvider.information.entityId.label',
                defaultMessage: 'Entity ID',
              })}
              secondary={identityProviderConfiguration.spEntityId}
              secondaryTypographyProps={{ id: 'saml-configuration-entity-id' }}
            />
          </ListItem>
        )}
        {identityProviderConfiguration.ssoUrl && (
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar>
                <VpnKeyIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={intl.formatMessage({
                id: 'identityProvider.information.ssoUrl.label',
                defaultMessage: 'SSO URL',
              })}
              secondary={identityProviderConfiguration.ssoUrl}
              secondaryTypographyProps={{ id: 'saml-configuration-sso-url' }}
            />
          </ListItem>
        )}
        {identityProviderConfiguration.loginUrl && (
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar>
                <ForwardIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={intl.formatMessage({
                id: 'identityProvider.information.loginUrl.label',
                defaultMessage: 'Login URL',
              })}
              secondary={identityProviderConfiguration.loginUrl}
              secondaryTypographyProps={{ id: 'saml-configuration-login-url' }}
            />
          </ListItem>
        )}
        <Divider variant="fullWidth" />
        <ListItem alignItems="flex-start">
          <ListItemText
            inset={true}
            secondary={
              <FormattedMessage
                id="identityProvider.information.rootUrlHint"
                defaultMessage="URLs generated by this identity provider are based on the <a>Root URL</a> configured in the site settings."
                values={{
                  a: (text) => <RouterLink to="/system/site/settings">{text}</RouterLink>,
                }}
              />
            }
          />
        </ListItem>
      </List>
      {identityProviderType === IdentityProviderType.SAML2 && (
        <FormButtons sx={{ display: 'flex', justifyContent: 'end', mt: 0 }}>
          <DefaultButton
            name="samlDownloadButton"
            href={`/saml2/service-provider-metadata/${identityProviderKey}`}
            download
            color="secondary"
            startIcon={<CloudDownloadIcon />}
          >
            <FormattedMessage
              id="identityProvider.information.samlMetadata.label"
              defaultMessage="SAML2.0 Metadata"
            />
          </DefaultButton>
        </FormButtons>
      )}
    </>
  </PaddedPaper>
);

export default IdentityProviderInformation;

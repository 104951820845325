import { MediaDetail, SubmissionFileType } from '../types';

import { generateApiUri } from './endpoints';

export const downloadSubmissionFileUri = (
  submissionReference: string,
  file: MediaDetail,
  type: SubmissionFileType
): string => {
  return generateApiUri(`/submissions/${submissionReference}/${type}/${file.storedFilename}`, {
    params: { download: file.filename },
  });
};

export const downloadSubmissionLogFileUri = (
  submissionReference: string,
  file: MediaDetail
): string => {
  return generateApiUri(`/submissions/${submissionReference}/log/${file.storedFilename}`, {
    params: { download: file.filename },
  });
};

export const downloadAppliedSchemaFileUri = (
  submissionReference: string,
  file: MediaDetail
): string => {
  return generateApiUri(
    `/submissions/${submissionReference}/schemamapping/${file.storedFilename}`,
    {
      params: { download: file.storedFilename },
    }
  );
};

import { FC, useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { toAssignmentUrl } from '../../projects/project/specification/Assignments';
import { updateActiveOnlyValue } from '../../../util';
import { useBrowseRequest, useTitle } from '../../../hooks';
import * as AssignmentsApi from '../../../api/assignments';
import {
  BrowseTable,
  DefaultButton,
  FilterBar,
  FilterPagination,
  FilterSearch,
  FilterToggle,
  MinWidthTableCell,
  StyledTableHead,
  browseTableBody,
} from '../../../components';
import { CrossIcon, TickIcon } from '../../../components/icons';
import { AssignmentDetail } from '../../../types';
import { intl } from '../../../Internationalization';
import { SupplierContext } from './SupplierContext';

const AssignmentsTableBody = browseTableBody<AssignmentDetail>();

const PAGE_SIZE = 10;

const SupplierAssignments: FC = () => {
  useTitle(
    intl.formatMessage({ id: 'title.supplierAssignments', defaultMessage: 'Supplier Assignments' })
  );
  const { supplierKey } = useContext(SupplierContext);

  const { request, response, processing, updateRequest, setPage } = useBrowseRequest({
    initialRequest: {
      page: 0,
      size: PAGE_SIZE,
      active: true,
      filterRelated: true,
      supplierKey: supplierKey,
    },
    onRequest: AssignmentsApi.getAssignments,
  });

  const handleFilterUpdate = useCallback(
    (filter: string) => updateRequest({ filter }),
    [updateRequest]
  );
  const handleActiveOnlyUpdate = updateActiveOnlyValue(updateRequest);

  const assignmentRow = (assignment: AssignmentDetail) => {
    return (
      <TableRow key={assignment.key}>
        <TableCell align="left">{assignment.specification.project.name}</TableCell>
        <TableCell align="left">{assignment.specification.name}</TableCell>
        <TableCell align="left">{assignment.reference}</TableCell>
        <TableCell align="center">{assignment.restricted ? <TickIcon /> : <CrossIcon />}</TableCell>
        <MinWidthTableCell>
          <DefaultButton
            color="grey"
            component={Link}
            to={toAssignmentUrl(assignment)}
            aria-label={intl.formatMessage({
              id: 'supplier.assignments.table.navigateToAssignment.ariaLabel',
              defaultMessage: 'Navigate to assignment',
            })}
          >
            <EditIcon />
          </DefaultButton>
        </MinWidthTableCell>
      </TableRow>
    );
  };

  return (
    <div id="supplier-assignments">
      <FilterBar
        startInput={
          <FilterSearch
            placeholder={intl.formatMessage({
              id: 'supplier.assignments.filterSearch.placeholder',
              defaultMessage: 'Filter assignments…',
            })}
            onSearch={handleFilterUpdate}
          />
        }
        actions={
          <>
            <FilterPagination
              page={request.page}
              size={request.size}
              total={response?.total}
              disabled={processing}
              setPage={setPage}
            />
            <FilterToggle
              name="active"
              label={intl.formatMessage({
                id: 'supplier.assignments.filterActiveToggle.label',
                defaultMessage: 'Active Only',
              })}
              checked={!!request.active}
              onChange={handleActiveOnlyUpdate}
              disabled={processing}
            />
          </>
        }
      />
      <BrowseTable>
        <StyledTableHead>
          <TableRow>
            <TableCell align="left">
              <FormattedMessage
                id="supplier.assignments.table.projectColumn"
                defaultMessage="Project"
              />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage
                id="supplier.assignments.table.specificationColumn"
                defaultMessage="Specification"
              />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage
                id="supplier.assignments.table.referenceColumn"
                defaultMessage="Reference"
              />
            </TableCell>
            <TableCell align="center">
              <FormattedMessage
                id="supplier.assignments.table.restrictedColumn"
                defaultMessage="Restricted"
              />
            </TableCell>
            <MinWidthTableCell>
              <FormattedMessage
                id="supplier.assignments.table.actionsColumn"
                defaultMessage="Actions"
              />
            </MinWidthTableCell>
          </TableRow>
        </StyledTableHead>
        <AssignmentsTableBody
          data={response && response.results}
          mapToRow={assignmentRow}
          noDataMessage={intl.formatMessage({
            id: 'supplier.assignments.noAssignments',
            defaultMessage: 'No matching assignments',
          })}
          numCols={4}
        />
      </BrowseTable>
    </div>
  );
};

export default SupplierAssignments;

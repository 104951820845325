import { AxiosPromise } from 'axios';

import { NotificationPatch, MyNotificationsRequest, MyNotificationsResponse } from '../types';
import { AXIOS, RequestConfig } from './endpoints';

export function getMyNotifications(
  request: MyNotificationsRequest,
  config?: RequestConfig
): AxiosPromise<MyNotificationsResponse> {
  return AXIOS.get(`/my_notifications`, {
    ...config,
    params: request,
  });
}

export function patchNotifications(request: NotificationPatch): AxiosPromise<void> {
  return AXIOS.patch(`/my_notifications`, request);
}

import { useState, useContext, FC } from 'react';
import { Container, Grid } from '@mui/material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useSnackbar } from 'notistack';

import * as SubmissionApi from '../../../../../../api/submission';
import { AxiosApiError, extractErrorMessage } from '../../../../../../api/endpoints';
import { FullWidthButton } from '../../../../../../components';
import SubmissionResultDetails from '../../../../../components/submission/SubmissionResultDetails';
import { AppBarStatsContext } from '../../../../../../contexts/app-bar-stats';
import { SubmissionState } from '../../../../../../types';
import { intl } from '../../../../../../Internationalization';
import { useTitle } from '../../../../../../hooks';

import { OpenSubmissionContext } from '../OpenSubmissionContext';

const SubmissionResults: FC = () => {
  useTitle(
    intl.formatMessage({
      id: 'title.submissionResults',
      defaultMessage: 'Submission Results',
    })
  );
  const [processing, setProcessing] = useState(false);
  const openSubmissionContext = useContext(OpenSubmissionContext);
  const appBarStatsContext = useContext(AppBarStatsContext);
  const { enqueueSnackbar } = useSnackbar();

  const completeCurrentSubmission = () => {
    setProcessing(true);
    SubmissionApi.close(openSubmissionContext.submission.reference)
      .then((response) => {
        openSubmissionContext.submissionUpdated(response.data);
        appBarStatsContext.refresh();
      })
      .catch((error: AxiosApiError) => {
        setProcessing(false);
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'openSubmission.results.completeError',
              defaultMessage: 'Failed to close submission',
            })
          ),
          { variant: 'error' }
        );
      });
  };
  const { submissionUpdated, submission, assignment, specification, project } =
    openSubmissionContext;

  return (
    <Container maxWidth="xl" id="my-assignment-open-submission-results" disableGutters>
      <Grid container justifyContent="center" spacing={3}>
        <SubmissionResultDetails
          submissionUpdated={submissionUpdated}
          submission={submission}
          assignment={assignment}
          project={project}
          specification={specification}
        />
        <Grid item xs={12}>
          <FullWidthButton
            name="closeSubmission"
            label={intl.formatMessage({
              id: 'openSubmission.results.closeButton',
              defaultMessage: 'Close Submission',
            })}
            processing={processing}
            disabled={
              submission.state !== SubmissionState.FINISHED || !submission.canUpdateSubmission
            }
            color="primary"
            endIcon={<DoneAllIcon />}
            onClick={completeCurrentSubmission}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default SubmissionResults;

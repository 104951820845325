import { FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Loading } from '../../../../../components';
import {
  AssignmentDetail,
  DataStoreConfigDetail,
  ProjectDetail,
  SessionSchema,
  SpecificationDetail,
  SubmissionDetail,
} from '../../../../../types';
import * as SpecificationApi from '../../../../../api/specification';
import { extractErrorMessage } from '../../../../../api/endpoints';
import { ErrorBlockContext } from '../../../../../contexts/error-block';
import { intl } from '../../../../../Internationalization';

import { OpenSubmissionContext } from './OpenSubmissionContext';
import OpenSubmissionStepper from './OpenSubmissionStepper';
import MapViewer from './map/MapViewer';

interface MapUnavailableRedirectProps extends PropsWithChildren {
  productionMode: boolean;
  enableMap: boolean;
}

const MapUnavailableRedirect: FC<MapUnavailableRedirectProps> = ({
  productionMode,
  enableMap,
  children,
}) => {
  if (productionMode || !enableMap) {
    return <Navigate to="../" replace />;
  }

  return <>{children}</>;
};

interface OpenSubmissionProps {
  submissionUpdated: React.Dispatch<React.SetStateAction<SubmissionDetail | undefined>>;
  submission: SubmissionDetail;
  assignment: AssignmentDetail;
  project: ProjectDetail;
  specification: SpecificationDetail;
}

interface OpenSubmissionContextData {
  specificationValid: boolean;
  dataStores: DataStoreConfigDetail[];
  sessionSchema: SessionSchema;
}

const OpenSubmission: FC<OpenSubmissionProps> = ({
  submissionUpdated,
  submission,
  assignment,
  project,
  specification,
}) => {
  const { raiseError } = useContext(ErrorBlockContext);
  const [openSubmissionContextData, setOpenSubmissionContextData] =
    useState<OpenSubmissionContextData>();

  useEffect(() => {
    const loadAndValidate = async () => {
      try {
        const [{ data: dataStores }, specificationValid, { data: sessionSchema }] =
          await Promise.all([
            SpecificationApi.getDataStoreConfigs(specification.key),
            SpecificationApi.isSpecificationValid(specification.key),
            SpecificationApi.getSessionSchema(specification.key),
          ]);
        setOpenSubmissionContextData({ dataStores, specificationValid, sessionSchema });
      } catch (error: any) {
        raiseError(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'openSubmission.loadError',
              defaultMessage: 'Failed to fetch submission data',
            })
          )
        );
      }
    };

    loadAndValidate();
  }, [raiseError, specification.key]);

  if (openSubmissionContextData) {
    return (
      <OpenSubmissionContext.Provider
        value={{
          submissionUpdated,
          submission,
          assignment,
          project,
          specification,
          ...openSubmissionContextData,
        }}
      >
        <Routes>
          <Route index element={<OpenSubmissionStepper />} />
          <Route
            path="map"
            element={
              <MapUnavailableRedirect
                productionMode={openSubmissionContextData.sessionSchema.productionMode}
                enableMap={specification.enableMap}
              >
                <MapViewer />
              </MapUnavailableRedirect>
            }
          />
          <Route path="*" element={<Navigate to="." replace />} />
        </Routes>
      </OpenSubmissionContext.Provider>
    );
  }

  return <Loading />;
};

export default OpenSubmission;

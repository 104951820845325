import { AxiosPromise } from 'axios';

import { AXIOS } from './endpoints';
import {
  IdentityProviderLinkSettings,
  IdentityProviderLinkDetail,
} from '../types/identityProviderLink';

export function fetchIdentityProviderLink(
  identityProviderKey: string,
  userKey: string
): AxiosPromise<IdentityProviderLinkDetail> {
  return AXIOS.get(`/identity_provider_links/${identityProviderKey}/${userKey}`);
}

export function createOrUpdateIdentityProviderLink(
  identityProviderKey: string,
  userKey: string,
  request: IdentityProviderLinkSettings
): AxiosPromise<IdentityProviderLinkDetail> {
  return AXIOS.put(`/identity_provider_links/${identityProviderKey}/${userKey}`, request);
}

export function deleteIdentityProviderLink(
  identityProviderKey: string,
  userKey: string
): AxiosPromise<void> {
  return AXIOS.delete(`/identity_provider_links/${identityProviderKey}/${userKey}`);
}

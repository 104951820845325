import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  Typography,
  Box,
  Grid,
} from '@mui/material';

import { PaddedPaper, Upload, MessageBox } from '../../../components';
import { TickIcon, CrossIcon } from '../../../components/icons';
import * as UserImportsApi from '../../../api/userImports';
import { UserImportJobDetail } from '../../../types';
import { useMediaUpload } from '../../../hooks';
import { intl } from '../../../Internationalization';
import { FileUploadConfig } from '../../../api/endpoints';

interface UploadCsvProps {
  onUploaded: (jobDetail: UserImportJobDetail) => void;
}

const UploadCsv: FC<UploadCsvProps> = ({ onUploaded }) => {
  const [upload, uploading, uploadProgress] = useMediaUpload<UserImportJobDetail>({
    onUpload: (files: File[], config: FileUploadConfig) =>
      UserImportsApi.createJob(files[0], config),
    onUploadComplete: (jobDetail: UserImportJobDetail) => onUploaded(jobDetail),
  });

  return (
    <Container maxWidth="xl" id="system-user-import-upload" disableGutters>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PaddedPaper>
            <Typography variant="h5" gutterBottom>
              <FormattedMessage id="userImport.uploadCsv.title" defaultMessage="User Import" />
            </Typography>
            <MessageBox
              level="info"
              message={intl.formatMessage({
                id: 'userImport.uploadCsv.uploadInfo',
                defaultMessage:
                  'Upload a CSV below containing the users to import. Invalid or duplicate users will be skipped.',
              })}
            />
            <Box py={2}>
              <Upload
                accept=".csv"
                onDrop={upload}
                uploading={uploading}
                progress={uploadProgress}
              />
            </Box>
          </PaddedPaper>
        </Grid>
        <Grid item xs={12} xl={6}>
          <PaddedPaper fillVertical>
            <Typography variant="h5" gutterBottom>
              <FormattedMessage
                id="userImport.uploadCsv.reqTable.title"
                defaultMessage="Format Requirements"
              />
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage
                      id="userImport.uploadCsv.reqTable.columnColumn"
                      defaultMessage="Column"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="userImport.uploadCsv.reqTable.requiredColumn"
                      defaultMessage="Required"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="userImport.uploadCsv.reqTable.descriptionColumn"
                      defaultMessage="Description"
                    />
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>name</TableCell>
                  <TableCell>
                    <TickIcon
                      titleAccess={intl.formatMessage({
                        id: 'userImport.uploadCsv.reqTable.nameRequired.titleAccess',
                        defaultMessage: 'Name is required',
                      })}
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="userImport.uploadCsv.reqTable.nameColumnDesc"
                      defaultMessage="The user's full name. The user will be skipped if no name is provided."
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>email</TableCell>
                  <TableCell>
                    <TickIcon
                      titleAccess={intl.formatMessage({
                        id: 'userImport.uploadCsv.reqTable.emailRequired.titleAccess',
                        defaultMessage: 'Email is required',
                      })}
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="userImport.uploadCsv.reqTable.emailColumnDesc"
                      defaultMessage="The user's email address. The user will be skipped if this value is missing, invalid, or already in use."
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>supplier</TableCell>
                  <TableCell>
                    <CrossIcon
                      titleAccess={intl.formatMessage({
                        id: 'userImport.uploadCsv.reqTable.supplierNotRequired.titleAccess',
                        defaultMessage: 'Supplier is not required',
                      })}
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="userImport.uploadCsv.reqTable.supplierColumnDesc"
                      defaultMessage="The name of the supplier to assign the user to. The user will be skipped if a supplier with the provided name does not exist."
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>receiver</TableCell>
                  <TableCell>
                    <CrossIcon
                      titleAccess={intl.formatMessage({
                        id: 'userImport.uploadCsv.reqTable.receiverNotRequired.titleAccess',
                        defaultMessage: 'Receiver is not required',
                      })}
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="userImport.uploadCsv.reqTable.receiverColumnDesc"
                      defaultMessage='Controls whether the user is granted receiver permissions. Valid values are "true" or "false", defaulting to "false" if missing. The user will be skipped if this value is invalid.'
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>activate</TableCell>
                  <TableCell>
                    <CrossIcon
                      titleAccess={intl.formatMessage({
                        id: 'userImport.uploadCsv.reqTable.activateNotRequired.titleAccess',
                        defaultMessage: 'Activate is not required',
                      })}
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="userImport.uploadCsv.reqTable.activateColumnDesc"
                      defaultMessage='If "true", the account will be activated and the user will receive the welcome email. If "false", the user will receive the activation email. Valid values are "true" or "false", defaulting to "false" if missing. The user will be skipped if this value is invalid.'
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </PaddedPaper>
        </Grid>
        <Grid item xs={12} xl={6}>
          <PaddedPaper fillVertical>
            <Typography variant="h5" gutterBottom>
              <FormattedMessage
                id="userImport.uploadCsv.formatTable.title"
                defaultMessage="Format Example"
              />
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>name</TableCell>
                  <TableCell>email</TableCell>
                  <TableCell>supplier</TableCell>
                  <TableCell>receiver</TableCell>
                  <TableCell>activate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Joe Bloggs</TableCell>
                  <TableCell>joe.bloggs@example.com</TableCell>
                  <TableCell>A Supplier</TableCell>
                  <TableCell>true</TableCell>
                  <TableCell>true</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Jane Doe</TableCell>
                  <TableCell>jane.doe@example.com</TableCell>
                  <TableCell>Another Supplier</TableCell>
                  <TableCell>true</TableCell>
                  <TableCell>true</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Jeremy Smith</TableCell>
                  <TableCell>jeremy.smith@example.com</TableCell>
                  <TableCell>A Supplier</TableCell>
                  <TableCell>false</TableCell>
                  <TableCell>true</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Jerome Morgan</TableCell>
                  <TableCell>jerome.morgan@example.com</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>James Nicol</TableCell>
                  <TableCell>james.nicol@example.com</TableCell>
                  <TableCell>Another Supplier</TableCell>
                  <TableCell></TableCell>
                  <TableCell>false</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Jack Wilson</TableCell>
                  <TableCell>jack.wilson@example.com</TableCell>
                  <TableCell></TableCell>
                  <TableCell>true</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </PaddedPaper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UploadCsv;

import { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { UserProfile } from '../../../types';
import ApiTokens from './ApiTokens';
import ApiToken from './ApiToken';
import { Container } from '@mui/material';

interface ApiTokenRoutingProps {
  user: UserProfile;
}

const ApiTokenRouting: FC<ApiTokenRoutingProps> = ({ user }) => (
  <Container maxWidth="md" disableGutters>
    <Routes>
      <Route index element={<ApiTokens user={user} />} />
      <Route path=":apiTokenName" element={<ApiToken user={user} />} />
      <Route path="*" element={<Navigate to="." replace />} />
    </Routes>
  </Container>
);

export default ApiTokenRouting;

import Schema from 'async-validator';
import {
  NOT_BLANK_REGEX,
  duplicateValidator,
  notTrimmableValidator,
  nameLengthValidator,
  descriptionLengthValidator,
  periodValidator,
  ConditionalRule,
  nominalUriLengthValidator,
  validateArrayWithConditionalRules,
} from './shared';
import { intl } from '../Internationalization';
import { specificationByName } from '../api/specificationLookup';
import { ActionKind, SpecificationActionConfig } from '../types';

export function specificationSettingsValidator(
  getProjectKey: () => string,
  getExistingName?: () => string
): Schema {
  return new Schema({
    name: [
      {
        required: true,
        pattern: NOT_BLANK_REGEX,
        message: intl.formatMessage({
          id: 'specification.validator.name.required',
          defaultMessage: 'Please provide a name',
        }),
      },
      nameLengthValidator,
      notTrimmableValidator,
      duplicateValidator({
        regex: NOT_BLANK_REGEX,
        existingValue: getExistingName,
        checkUnique: (name: string) => specificationByName(getProjectKey(), name),
        alreadyExistsMessage: intl.formatMessage({
          id: 'specification.validator.name.unique',
          defaultMessage: 'A specification with this name already exists',
        }),
        errorMessage: intl.formatMessage({
          id: 'specification.validator.name.checkUniqueError',
          defaultMessage: 'There was a problem verifying the specification name',
        }),
      }),
    ],
    description: [
      {
        required: true,
        message: intl.formatMessage({
          id: 'specification.validator.description.required',
          defaultMessage: 'Please provide a description',
        }),
      },
      descriptionLengthValidator,
    ],
    timeoutPeriod: [
      {
        required: true,
        message: intl.formatMessage({
          id: 'specification.validator.timeoutPeriod.required',
          defaultMessage: 'Please provide a timeout period',
        }),
      },
      {
        max: 10,
        message: intl.formatMessage({
          id: 'specification.validator.timeoutPeriod.maxCharacters',
          defaultMessage: 'Maximum of 9 non-space characters.',
        }),
      },
      periodValidator({ days: 1 }),
    ],
    autoClosePeriod: [
      {
        required: true,
        message: intl.formatMessage({
          id: 'specification.validator.autoClosePeriod.required',
          defaultMessage: 'Please provide an auto close period.',
        }),
      },
      {
        max: 10,
        message: intl.formatMessage({
          id: 'specification.validator.autoClosePeriod.maxCharacters',
          defaultMessage: 'Maximum of 9 non-space characters.',
        }),
      },
      periodValidator({ days: 1 }),
    ],
    mediaRetentionPolicy: {
      required: true,
      message: intl.formatMessage({
        id: 'specification.validator.mediaRetentionPolicy.required',
        defaultMessage: 'Please select a media retention policy',
      }),
    },
    mediaRetentionPeriod: [
      {
        required: true,
        message: intl.formatMessage({
          id: 'specification.validator.mediaRetentionPeriod.required',
          defaultMessage: 'Please provide a media retention period',
        }),
      },
      {
        max: 10,
        message: intl.formatMessage({
          id: 'specification.validator.mediaRetentionPeriod.maxCharacters',
          defaultMessage: 'Maximum of 9 non-space characters.',
        }),
      },
      periodValidator({ days: 1 }),
    ],
    conformanceMode: {
      required: true,
      message: intl.formatMessage({
        id: 'specification.validator.conformanceMode.required',
        defaultMessage: 'Please select a conformance mode',
      }),
    },
  });
}
const INPUT_CONFIG_NAME_VALIDATOR = {
  required: true,
  pattern: NOT_BLANK_REGEX,
  message: intl.formatMessage({
    id: 'specificationInputConfig.validator.name.required',
    defaultMessage: 'Please provide a name',
  }),
};

export function newSpecificationInputConfigValidator(): Schema {
  return new Schema({
    name: [INPUT_CONFIG_NAME_VALIDATOR, nameLengthValidator],
  });
}

export const SPECIFICATION_INPUT_CONFIG_VALIDATOR = new Schema({
  inputs: {
    type: 'array',
    defaultField: {
      type: 'object',
      fields: { name: [INPUT_CONFIG_NAME_VALIDATOR, nameLengthValidator] },
    },
  },
});

const actionNameValidator = [
  {
    required: true,
    pattern: NOT_BLANK_REGEX,
    message: intl.formatMessage({
      id: 'specification.actions.validator.name.required',
      defaultMessage: 'Please provide a name',
    }),
  },
  nameLengthValidator,
  notTrimmableValidator,
];

const actionKindValidator = {
  required: true,
  pattern: NOT_BLANK_REGEX,
  message: intl.formatMessage({
    id: 'specification.actions.validator.kind.required',
    defaultMessage: "Please select the action's kind",
  }),
};
const actionTargetPathValidator = [
  {
    required: true,
    pattern: NOT_BLANK_REGEX,
    message: intl.formatMessage({
      id: 'specification.actions.validator.targetPath.required',
      defaultMessage: 'Please provide a target path',
    }),
  },
  nominalUriLengthValidator,
  notTrimmableValidator,
];
const actionNamedLocationValidator = {
  required: true,
  pattern: NOT_BLANK_REGEX,
  message: intl.formatMessage({
    id: 'specification.actions.validator.namedLocation.required',
    defaultMessage: 'Please select a named location',
  }),
};

export const CREATE_SPECIFICATION_ACTION_CONFIG_VALIDATOR = new Schema({
  name: actionNameValidator,
  kind: actionKindValidator,
});

export const SPECIFICATION_INPUT_ACTION_CONFIG_VALIDATOR = {
  name: actionNameValidator,
  kind: actionKindValidator,
  targetPath: actionTargetPathValidator,
  namedLocation: actionNamedLocationValidator,
};

export const SPECIFICATION_DEBUG_ACTION_CONFIG_VALIDATOR = {
  name: actionNameValidator,
  kind: actionKindValidator,
};

export const specificationActionConfigValidator = (actions: SpecificationActionConfig[]) => {
  return new Schema({
    actions: validateArrayWithConditionalRules(
      actions,
      SPECIFICATION_ACTION_CONFIG_VALIDATOR_RULES
    ),
  });
};

const SPECIFICATION_ACTION_CONFIG_VALIDATOR_RULES: ConditionalRule<SpecificationActionConfig>[] = [
  {
    getRulesIfApplicable(item: SpecificationActionConfig) {
      if (item.kind === ActionKind.COPY_INPUTS) {
        return SPECIFICATION_INPUT_ACTION_CONFIG_VALIDATOR;
      }
    },
  },
  {
    getRulesIfApplicable(item: SpecificationActionConfig) {
      if (item.kind === ActionKind.DEBUG) {
        return SPECIFICATION_DEBUG_ACTION_CONFIG_VALIDATOR;
      }
    },
  },
];

import { FC, useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';
import { ValidateFieldsError } from 'async-validator';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import * as IdentityProviderLinkApi from '../../../../api/identityProviderLink';
import {
  ValidatedTextField,
  PaddedDialogActions,
  DefaultButton,
  IdentityProvidersAutocomplete,
} from '../../../../components';
import { IdentityProviderMetadata } from '../../../../types';
import { extractErrorMessage } from '../../../../api/endpoints';
import { intl } from '../../../../Internationalization';

import { UserContext } from '../UserContext';
import { IDENTITY_PROVIDER_LINK_SETTINGS_VALIDATOR, validate } from '../../../../validation';

interface CreateIdentityProviderLinkRequest {
  identityProviderKey: string;
  userKey: string;
  remoteId: string;
}

interface NewIdentityProviderProps {
  onCancel: () => void;
  onCreated: () => void;
}

const NewIdentityProvider: FC<NewIdentityProviderProps> = ({ onCancel, onCreated }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { userKey } = useContext(UserContext);

  const [processing, setProcessing] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();
  const [identityProvider, setIdentityProvider] = useState<IdentityProviderMetadata | null>(null);
  const [remoteId, setRemoteId] = useState('');

  const collectSettings = (): Partial<CreateIdentityProviderLinkRequest> => ({
    identityProviderKey: identityProvider?.key,
    userKey,
    remoteId,
  });

  const validateAndSubmit = async () => {
    setProcessing(true);
    try {
      createIdentityProviderLink(
        await validate(IDENTITY_PROVIDER_LINK_SETTINGS_VALIDATOR, collectSettings())
      );
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const createIdentityProviderLink = async (createRequest: CreateIdentityProviderLinkRequest) => {
    setFieldErrors(undefined);

    try {
      await IdentityProviderLinkApi.createOrUpdateIdentityProviderLink(
        createRequest.identityProviderKey,
        createRequest.userKey,
        { remoteId: createRequest.remoteId }
      );
      enqueueSnackbar(
        intl.formatMessage({
          id: 'user.identityProviderLink.create.saveSuccess',
          defaultMessage: 'New identity provider link created',
        }),
        { variant: 'success' }
      );
      onCreated();
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'user.identityProviderLink.create.saveError',
            defaultMessage: 'Failed to create new identity provider link',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Dialog
      onClose={onCancel}
      open={true}
      fullWidth
      id="new-identity-provider-link-dialog"
      aria-labelledby="new-identity-provider-link-dialog-title"
    >
      <DialogTitle id="new-identity-provider-link-dialog-title">
        <FormattedMessage
          id="user.identityProviderLink.create.title"
          defaultMessage="Add Identity Provider Link"
        />
      </DialogTitle>
      <DialogContent dividers={true}>
        <IdentityProvidersAutocomplete
          id="identity-provider-select"
          name="identityProviderKey"
          label={intl.formatMessage({
            id: 'user.identityProviderLink.create.identityProviderId.label',
            defaultMessage: 'Identity Provider',
          })}
          margin="normal"
          variant="outlined"
          value={identityProvider}
          onChange={setIdentityProvider}
          fieldErrors={fieldErrors}
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="remoteId"
          label={intl.formatMessage({
            id: 'user.identityProviderLink.create.remoteId.label',
            defaultMessage: 'Remote ID',
          })}
          value={remoteId}
          onChange={(event) => setRemoteId(event.target.value)}
          margin="normal"
          variant="outlined"
        />
      </DialogContent>
      <PaddedDialogActions>
        <DefaultButton
          id="cancel-add-identity-provider-link"
          color="secondary"
          onClick={onCancel}
          disabled={processing}
        >
          <FormattedMessage
            id="user.identityProviderLink.create.cancelButton"
            defaultMessage="Cancel"
          />
        </DefaultButton>
        <DefaultButton
          name="addNewIdentityProviderLink"
          onClick={validateAndSubmit}
          disabled={processing}
          startIcon={<AddIcon />}
        >
          <FormattedMessage
            id="user.identityProviderLink.create.createButton"
            defaultMessage="Add Identity Provider Link"
          />
        </DefaultButton>
      </PaddedDialogActions>
    </Dialog>
  );
};

export default NewIdentityProvider;

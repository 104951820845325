import * as ProjectsApi from '../../api/projects';
import { ProjectDetail } from '../../types';

import FilteredAutocomplete, { BasicFilteredAutocompleteProps } from './FilteredAutocomplete';

interface ProjectAutocompleteProps<Multiple extends boolean | undefined>
  extends BasicFilteredAutocompleteProps<ProjectDetail, Multiple> {
  active?: boolean;
}

const fetchOptions = async (filter: string, active?: boolean) => {
  const response = await ProjectsApi.getProjects({ page: 0, size: 100, filter, active });
  return response.data.results;
};

const renderOption = (project: ProjectDetail) => project.name;
const getOptionSelected = (option: ProjectDetail, value: ProjectDetail) => option.key === value.key;
const getOptionLabel = (project: ProjectDetail) => project.name;

const ProjectAutocomplete = <Multiple extends boolean | undefined>({
  active,
  ...props
}: ProjectAutocompleteProps<Multiple>) => (
  <FilteredAutocomplete
    {...props}
    fetchOptions={(filter: string) => fetchOptions(filter, active)}
    renderOption={renderOption}
    getOptionSelected={getOptionSelected}
    getOptionLabel={getOptionLabel}
  />
);

export default ProjectAutocomplete;

import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormHelperText } from '@mui/material';

import { ValidatedPasswordField } from '../../../../../../components';

interface InputParameterPasswordProps {
  name: string;
  storedPassword: boolean;
  handleParamChange: (name: string, value: string) => void;
}

const InputParameterPassword: FC<InputParameterPasswordProps> = ({
  name,
  storedPassword,
  handleParamChange,
}) => {
  const [parameter, setParameter] = useState<string>('');

  const onChange = (newValue: string) => {
    setParameter(newValue);
    handleParamChange(name, newValue);
  };

  return (
    <>
      <ValidatedPasswordField
        key={name}
        name={name}
        label={name}
        value={parameter}
        onChange={(event) => onChange(event.target.value)}
        margin="normal"
        variant="outlined"
        autoComplete="new-password"
      />
      {storedPassword && (
        <FormHelperText variant="outlined">
          <FormattedMessage
            id="openSubmission.inputUpload.parameters.storedPassword.message"
            defaultMessage='Submitting a new "{input}" value will overwrite the current stored value.'
            values={{
              input: name,
            }}
          />
        </FormHelperText>
      )}
    </>
  );
};

export default InputParameterPassword;
